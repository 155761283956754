import helioscopeConfig from 'projectDesign/components/projectConfiguration/utils/defaultProps/helioscope';
import { createReducer } from '__common/utils/helpers';
import { products, isSMTilt, isGroundProduct, isRM10orRM10Evo, isEcoFoot2Plus, isAdditionalUserInputEnabledProduct, isRMFamily, isRmGridflex10 } from '__common/constants/products';
import { ProjectConfigurationActionTypes } from 'actionsConstants';
import { Action } from '__common/store/action';
import { SET_ENVIRONMENTAL_FACTORS_ACTION, SET_FIELD_INVALID_ACTION, SET_FIELD_WARNING_ACTION } from './projectConfigurationActions';
import { calculateKe, get_numerical_coefficient } from './utils/coefficients';
import { isASCE705, isASCE716or722 } from '__common/constants/buildingCodes';
import { applyEcoFoot2PlusRM10andEvoSetbackChanges } from '__common/utils/versionCompare/versionCompare';
import { toggleInputUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import { DEFAULT_VERIFIED_FLYOUTS_DATA } from './utils/constants';
import { MODULE_SELECTION_PAGE } from './utils/projectConfigurationDrawerPages';
import _ from 'lodash';

export const projectConfigurationState: projectConfigurationState = {
  projectEnvConfig: {},
  railsProductId: null,
  productId: products.solarmount_2,
  projectId: '',
  area: '',
  productTitle: {
    type: '',
    name: '',
  },
  projectName: '',
  openedDrawerPage: null,
  projectConfigurated: false,
  envFactorsLoading: false,
  clampCheckFailed: false,
  railArrangementTypeValidList: [],
  seismicSetbackDistance: null,
  formState: {
    invalidFields: [],
    warningFields: [],
  },
  seismicDesignCategory: '',
  usedSetbackDistance: 0,
  projectVersion: '',
  verifiedFlyouts: DEFAULT_VERIFIED_FLYOUTS_DATA,
  gone_through_panel_editor: true,
};

export default {
  projectConfiguration: createReducer(projectConfigurationState, {
    [ProjectConfigurationActionTypes.LOAD_PROJECT_CONFIGURATION](state: projectConfigurationState, action) {
      const { projectEnvConfig, productId, verified_flyouts } = action.payload;
      let verifiedFlyouts = verified_flyouts;
      if(!(verified_flyouts && Object.keys(verified_flyouts).length > 0)){
        verifiedFlyouts = _.cloneDeep(DEFAULT_VERIFIED_FLYOUTS_DATA);
        verifiedFlyouts[MODULE_SELECTION_PAGE].checked = false;
      }
      verifiedFlyouts = { ...DEFAULT_VERIFIED_FLYOUTS_DATA, ...verifiedFlyouts };
      if (!isAdditionalUserInputEnabledProduct(productId)){
        delete verifiedFlyouts['additional-user-inputs']
      }
      return { ...state, inputUnit: state.inputUnit ?? projectEnvConfig.input_unit, projectEnvConfig, verifiedFlyouts };
    },
    [ProjectConfigurationActionTypes.LOAD_HELIOSCOPE_PROJECT_CONFIGURATION](state: projectConfigurationState, action) {
      const { projectEnvConfig } = action.payload;
      const defaultCfg = helioscopeConfig(projectEnvConfig.product);
      return { ...state, projectEnvConfig: { ...projectEnvConfig, ...defaultCfg } };
    },
    [ProjectConfigurationActionTypes.SET_PROJECT_OPTION](state: projectConfigurationState, action): projectConfigurationState {
      const { field, value } = action.payload;
      const projectEnvConfig = state.projectEnvConfig;
      projectEnvConfig[field] = value;
      return { 
        ...state, 
        projectEnvConfig,
        formState: clearInvalidFieldOnChange(state.formState, field),
      };
    },
    [ProjectConfigurationActionTypes.SET_FLYOUT_VERIFIED](state: projectConfigurationState, action): projectConfigurationState {
      const { flyout, value, error } = action.payload;
      const verifiedFlyouts = state.verifiedFlyouts;
      if(flyout) {
        verifiedFlyouts[flyout] = verifiedFlyouts[flyout] ?? {};
        verifiedFlyouts[flyout].checked = value;
        verifiedFlyouts[flyout].error = error;
      }
      return { 
        ...state,
        verifiedFlyouts 
      };
    },
    [ProjectConfigurationActionTypes.CLEAR_PROJECT_CONFIGURATION](state: projectConfigurationState) {
      return { 
        ...state,   
        projectEnvConfig: {},
        projectId: '',
        railsProductId: null,
        productId: products.solarmount_2,
        area: '',
        productTitle: {
          type: '',
          name: '',
        },
        projectName: '',
        clampCheckFailed: false,
        railArrangementTypeValidList: [],
        formState: {
          invalidFields: [],
          warningFields: [],
        },
        projectVersion: '',
        verifiedFlyouts: DEFAULT_VERIFIED_FLYOUTS_DATA,
      };
    },
    [ProjectConfigurationActionTypes.SET_PRODUCT_ID](state: projectConfigurationState, action) {
      return { ...state, ...action.payload };
    }
    ,
    [ProjectConfigurationActionTypes.SET_PROJECT_VERSION](state: projectConfigurationState, action) {
      return { ...state, ...action.payload };
    },
    [ProjectConfigurationActionTypes.SET_PROJECT_ID](state: projectConfigurationState, action) {
      const { projectId } = action.payload;
      return { ...state, projectId };
    },
    [ProjectConfigurationActionTypes.SET_PROJECT_ID_ON_AUTOSAVE](state: projectConfigurationState, action) {
      const { projectId } = action.payload;
      return { ...state, projectId };
    },
    [ProjectConfigurationActionTypes.SET_PRODUCT_TITLE](state: projectConfigurationState, action) {
      const { productTitle } = action.payload;
      return { ...state, productTitle };
    },
    [ProjectConfigurationActionTypes.CLEAR_PRODUCT_TITLE](state: projectConfigurationState) {
      return { ...state, productTitle: '' };
    },
    [ProjectConfigurationActionTypes.SET_ZIP_AND_ADDRESS_CODE](state: projectConfigurationState, action) {
      const projectEnvConfig = state.projectEnvConfig;
      projectEnvConfig.zipcode = action.payload.zipCode;
      return { ...state, projectEnvConfig, area: action.payload.address };
    },
    [ProjectConfigurationActionTypes.CHANGE_STRUCTURE_TYPE](state: projectConfigurationState , action) {
      state.projectEnvConfig.structure_type = action.payload.structure_type;
      return {...state,};
    },
    [ProjectConfigurationActionTypes.GET_ENVIRONMENTAL_FACTORS](state: projectConfigurationState) {
      return { ...state, envFactorsLoading: true };
    },
    [ProjectConfigurationActionTypes.SET_ENVIRONMENTAL_FACTORS](state: projectConfigurationState, action: Action<SET_ENVIRONMENTAL_FACTORS_ACTION>) {
      const productsWithoutSeismic = [products.sunframe];
      const productsWithSeismic_S1 = [products.gft, products.ula, products.rm_gridflex, products.rm_gridflex_10, products.rm_10_ultra, products.ascender, products.rm, products.rm_10_evolution, products.rm_5, products.ecofoot2_plus];
      const { factors: { wind_speed, snow_load, elevation, seismic_ss, seismic_s1, building_code, wind_special }, zipcode, productId } = action.payload;
      if (state.projectEnvConfig.env_factors_for !== zipcode) { 
        const projectEnvConfig = {
          ...state.projectEnvConfig,
          env_factors_for: zipcode,
          building_code,
          wind_speed, 
          snow_load, 
          elevation, 
          seismic_ss,
          seismic_s1,
          wind_special,
          numberical_coefficient: get_numerical_coefficient(building_code, elevation, state.inputUnit),
          ground_elevation_factor_ke: calculateKe(elevation, isASCE716or722(building_code), state.inputUnit),
        };

        if(isRMFamily(productId)){
          projectEnvConfig.dead_load_factor_modification = state.projectEnvConfig.dead_load_factor_modification;
        }
        
        if (isSMTilt(productId)) {
          projectEnvConfig.building_code = state.projectEnvConfig.building_code;
        }
        

        if (productsWithoutSeismic.includes(productId)) {

          delete projectEnvConfig.seismic_ss;
        }

        if(!(productsWithSeismic_S1.includes(productId))){
          delete projectEnvConfig.seismic_s1;
        }
        else if ((isRM10orRM10Evo(productId) || isEcoFoot2Plus(productId)) && !applyEcoFoot2PlusRM10andEvoSetbackChanges(productId, state.projectVersion)){
          delete projectEnvConfig.seismic_s1;
        }
        
        return { ...state, projectEnvConfig, envFactorsLoading: false };
      }

      return { ...state, envFactorsLoading: false };
    },
    [ProjectConfigurationActionTypes.BLANK_ENVIRONMENTAL_FACTORS](state: projectConfigurationState, action) {
      const { zipcode, productId } = action.payload;
      if (state.projectEnvConfig.env_factors_for !== zipcode) {
        const projectEnvConfig = {
          ...state.projectEnvConfig,
          env_factors_for: zipcode,
          building_code: null,
          wind_speed: null, 
          snow_load: null, 
          elevation: 0, 
          seismic_ss: 0,
        };

        if (isSMTilt(productId)) {
          projectEnvConfig.building_code = state.projectEnvConfig.building_code;
        }

        if(isGroundProduct(productId)) {
          projectEnvConfig.seismic_s1 = 0;
        }
    
        return { ...state, projectEnvConfig, envFactorsLoading: false };
      }

      return { ...state, envFactorsLoading: false };
    },
    [ProjectConfigurationActionTypes.ENV_LOADING_FINISHED](state: projectConfigurationState) {
      return { ...state, envFactorsLoading: false };
    },
    [ProjectConfigurationActionTypes.CLEAR_ENV_FACTORS_FOR](state: projectConfigurationState) {
      state.projectEnvConfig.env_factors_for = '';
      return state;
    },
    [ProjectConfigurationActionTypes.SET_PROJECT_NAME](state: projectConfigurationState, action) {
      const { projectName } = action.payload;
      return { ...state, projectName };
    },
    [ProjectConfigurationActionTypes.SET_CITY_NAME](state: projectConfigurationState, action) {
      const { area } = action.payload;
      return { ...state, area };
    },

    [ProjectConfigurationActionTypes.SET_RAILS_PRODUCT_ID](state: projectConfigurationState, action) {
      const { railsProductId } = action.payload; 
      return { ...state, railsProductId };
    },
    [ProjectConfigurationActionTypes.SET_SPAN](state: projectConfigurationState, action) {
      const { preferred_span } = action.payload;
      const { projectEnvConfig } = state;
      projectEnvConfig.preferred_span = preferred_span;
      return { ...state, projectEnvConfig };
    },
    [ProjectConfigurationActionTypes.OPEN_DRAWER_PAGE](state: projectConfigurationState, action) {
      const { openedDrawerPage } = action.payload;
      return { ...state, openedDrawerPage };
    },
    [ProjectConfigurationActionTypes.SET_PROJECT_CONFIGURED](state: projectConfigurationState) {
      return { ...state, projectConfigurated: true };
    },
    [ProjectConfigurationActionTypes.RESET_PROJECT_CONFIGURED](state: projectConfigurationState) {
      return { ...state, projectConfigurated: false };
    },
    [ProjectConfigurationActionTypes.REMOVE_OPTION_FROM_CONFIG](state: projectConfigurationState, action) {
      const { field } = action.payload;
      delete state.projectEnvConfig[field];
      return { ...state };
    },
    [ProjectConfigurationActionTypes.REMOVE_OPTIONS_FROM_CONFIG](state: projectConfigurationState, action) {
      const { fields } = action.payload;
      fields.forEach(field => delete state.projectEnvConfig[field])
      return { ...state };
    },
    [ProjectConfigurationActionTypes.SET_FIELD_INVALID](state: projectConfigurationState, action: Action<SET_FIELD_INVALID_ACTION>): projectConfigurationState {
      const { fieldName, message } = action.payload;
      return {
        ...state,
        formState: {
          invalidFields: [
            ...state.formState.invalidFields.filter(f => f.fieldName !== fieldName),
            {
              errorMessage: message,
              fieldName,
            },
          ],
          warningFields: [...state.formState.warningFields],
        },
      };
    },
    [ProjectConfigurationActionTypes.SET_FIELD_WARNING](state: projectConfigurationState, action: Action<SET_FIELD_WARNING_ACTION>): projectConfigurationState {
      const { fieldName, message } = action.payload;
      return {
        ...state,
        formState: {
          warningFields: [
            ...state.formState.warningFields.filter(f => f.fieldName !== fieldName),
            {
              warningMessage: message,
              fieldName,
            },
          ],
          invalidFields: [...state.formState.invalidFields],
        },
      };
    },
    [ProjectConfigurationActionTypes.SET_RAIL_ARRANGEMENT_VALID_LIST](state: projectConfigurationState, action) {
      const { payload: { railArrangementTypeValidList } } = action;
      return { ...state, railArrangementTypeValidList };
    },
    [ProjectConfigurationActionTypes.SET_SEISMIC_SETBACK_DISTANCE](state: projectConfigurationState, action) {
      const { payload: { seismicSetbackDistance } } = action;
      return { ...state, seismicSetbackDistance };
    },
    [ProjectConfigurationActionTypes.RESET_CLAMP_CHECK](state: projectConfigurationState, action) {
      return { ...state, clampCheckFailed: false, railArrangementTypeValidList: [] };
    },
    [ProjectConfigurationActionTypes.SET_CLAMP_CHECK_FAILED](state: projectConfigurationState) {
      return { ...state, clampCheckFailed: true };
    },
    [ProjectConfigurationActionTypes.SET_DEAD_LOAD_FACTOR_MODIFICATION](state: projectConfigurationState, action) {
      const { payload: { dead_load_factor_modification } } = action;
      return { ...state, dead_load_factor_modification };
    },
    [ProjectConfigurationActionTypes.SET_ADDITIONAL_LOAD_COMBINATION](state: projectConfigurationState, action) {
      const { payload: { additional_load_combination } } = action;
      return { ...state, additional_load_combination };
    },
    [ProjectConfigurationActionTypes.SET_ADJUSTABLE_TILT_SYSTEM](state: projectConfigurationState, action) {
      const { payload: { adjustable_tilt_system } } = action;
      return { ...state, adjustable_tilt_system };
     
    },
    [ProjectConfigurationActionTypes.SET_SEISMIC_DESIGN_CATEGORY](state: projectConfigurationState, action) {
      const { payload: { seismicDesignCategory } } = action;
      return { ...state, seismicDesignCategory };
    },
    [ProjectConfigurationActionTypes.CLEAR_WARNING](state: projectConfigurationState, action): projectConfigurationState {
      const { field } = action.payload;
      return { 
        ...state, 
        formState: clearWarningFieldOnChange(state.formState, field),
      };
    },
    [ProjectConfigurationActionTypes.CHANGE_STRUCTURE_TYPE_TILT](state: projectConfigurationState, action) {
      const { structureType, tilt } = action.payload;
      const { projectEnvConfig } = state;
      projectEnvConfig.structure_type_info[structureType-1].tilt = Number(tilt);
      return { ...state, projectEnvConfig };
    },
    [ProjectConfigurationActionTypes.CHANGE_STRUCTURE_TYPE_PREFERRED_SPAN](state: projectConfigurationState, action) {
      const { structureType, preferred_span } = action.payload;
      const { projectEnvConfig } = state;
      projectEnvConfig.structure_type_info[structureType-1].preferred_span = preferred_span? Number(preferred_span) : null;
      return { ...state, projectEnvConfig };
    },
    [ProjectConfigurationActionTypes.CLEAR_INVALID_FIELD](state: projectConfigurationState, action): projectConfigurationState {
      const { field } = action.payload;
      return { 
        ...state, 
        formState: clearInvalidFieldOnChange(state.formState, field),
      };
    },
    [ProjectConfigurationActionTypes.SET_INPUT_UNIT](state: projectConfigurationState, action) {
      return { ...state,    inputUnit: action.payload.inputUnit, };
    },
    [ProjectConfigurationActionTypes.TOGGLE_INPUT_UNIT](state: projectConfigurationState, action) {
      return { ...state,    inputUnit: toggleInputUnit(state.inputUnit), };
    },
    [ProjectConfigurationActionTypes.SET_GONE_THROUGH_THE_PANEL_EDITOR](state: projectConfigurationState, action) {
      return { ...state,    gone_through_panel_editor: action.payload.gone_through_panel_editor, };
    },
  }),
};

function clearInvalidFieldOnChange(state: projectConfigurationFormState, fieldName: string): projectConfigurationFormState {
  return {
    ...state,
    invalidFields: state.invalidFields.filter(f => f.fieldName !== fieldName),
  };
}

function clearWarningFieldOnChange(state: projectConfigurationFormState, fieldName: string): projectConfigurationFormState {
  return {
    ...state,
    warningFields: state.warningFields.filter(f => f.fieldName !== fieldName),
  };
}
import { BomActionTypes } from 'actionsConstants';
import { hitApi, ObservableAjax } from '__common/utils/api';
import {
  FETCH_BOM_PART_LIST_SUCCESS, FETCH_BOM_PART_LIST_ERROR, ADD_FRONT_TRIM_END_CAP, SET_BOM_PK, SELECT_RAILS_FROM_API, ADD_RM10_MODULE_AND_HEX_BOLT, EMPTY_APP_ACTION, OPEN_BOM_REPORT, CLAMPS_CHANGED, SET_ENGINEERING_LAYOUT_FLAGS,
  ADD_RM10_EVO_MODULE_CLAMP_KIT,
} from 'actions';
import { ActionsObservable } from 'redux-observable';
import { AnyAction, Store } from 'redux';
import { isULA, products } from '__common/constants/products';
import { showCustomNotifyAlert,} from '__common/modules/alerts';
import { PART_RM10_EVO_MODULE_CLAMP_KIT, PART_RM10_EVO_MODULE_CLAMP_SIDE_BOLT_BULK, PART_RM10_EVO_MODULE_CLAMP_BULK, BND_T_BOLT_NUT_3_8_1_SS } from '__common/constants/parts';
import { ADD_RM10_EVO_MODULE_CLAMP_BULK, ADD_RM10_EVO_MODULE_CLAMP_SIDE_BOLT_BULK, ADD_T_BOLTS_009020S_FOR_SOLARHOOKS, FETCH_SCREENSHOT_REQUEST, FETCH_SCREENSHOT_SUCCESS, REMOVE_EXTRA_MID_AND_END_CLAMP_QUANTITY } from './bomActions';


export function fetchBomPartsListRequest(action$: ActionsObservable<AnyAction>) {
  return action$.ofType(BomActionTypes.FETCH_BOM_PART_LIST_REQUEST)
    .switchMap((action) => {
      return ObservableAjax({
        takeUntil: action$.ofType(BomActionTypes.FETCH_BOM_PART_LIST_SUCCESS),
        onSuccess: isULA(products[action.payload.productId]) ? [FETCH_BOM_PART_LIST_SUCCESS.bind(null, action.payload.productId), CLAMPS_CHANGED.bind(null, action.payload.productId), SELECT_RAILS_FROM_API.bind(null, action.payload.productId), FETCH_SCREENSHOT_REQUEST.bind( null, action.payload.projectId)]
        :[FETCH_BOM_PART_LIST_SUCCESS.bind(null, action.payload.productId), SELECT_RAILS_FROM_API.bind(null, action.payload.productId), SET_ENGINEERING_LAYOUT_FLAGS, FETCH_SCREENSHOT_REQUEST.bind( null, action.payload.projectId)],
        onError: FETCH_BOM_PART_LIST_ERROR,
        socketName: 'project',
        onSocketPending: 'CALCULATING BOM WAITING IN A QUEUE ...',
        onSocketStart: 'CALCULATING BOM...',
        onSocketFailure: 'CALCULATING BOM FAILURE',
        onErrorMessage: 'Cannot load bom',
        link: hitApi('get', `api/v1/bom/${action.payload.projectId}/`),
      });
    });
}

export function fetchScreenshotRequest(action$: ActionsObservable<AnyAction>) {
  return action$.ofType(BomActionTypes.FETCH_SCREENSHOT_REQUEST)
    .switchMap((action) => {
      return ObservableAjax({
        takeUntil: action$.ofType(BomActionTypes.FETCH_SCREENSHOT_REQUEST),
        onSuccess: FETCH_SCREENSHOT_SUCCESS,
        socketName: 'project',
        onSocketPending: 'FETCHING SCREENSHOTS IN A QUEUE ...',
        onSocketStart: 'FETCHING SCREENSHOTS...',
        onSocketFailure: 'FETCHING SCREENSHOTS FAILURE',
        link: hitApi('get', `api/v1/reports/generate/screenshot/${action.payload.projectId}/`),
      });
    });
}

export function showMetalXAlertOnMLPEClipSelection(action$: ActionsObservable<AnyAction>) {
  return action$.ofType(BomActionTypes.SELECT_ACCESSORY)
    .map((action) => {
      if(action.payload.partNumber === "ES11212") {
        showCustomNotifyAlert(" Please provide MLPE Tiger Clip Quantity", 5);
      }
    });
}

export function showEcoFoot2PlusAlert(action$: ActionsObservable<AnyAction>) {
  return action$.ofType(BomActionTypes.SELECT_ACCESSORY)
    .map((action) => {
      if(action.payload.partNumber === "ES10970") {
        showCustomNotifyAlert("Please provide EcoFoot MLPE Bracket Quantity", 5);
      }
    });
}


export function fetchRM10ModuleAndHexBolt(action$: ActionsObservable<AnyAction>) {
  return action$.ofType(BomActionTypes.FETCH_RM10_MODULE_AND_HEX_BOLT)
    .switchMap(action => 
      ObservableAjax({
        takeUntil: action$.ofType(BomActionTypes.FETCH_RM10_MODULE_AND_HEX_BOLT),
        onSuccess: ADD_RM10_MODULE_AND_HEX_BOLT.bind(null, action.payload.qty),
        onErrorMessage: 'Cannot remove fetch rm10 module and hex bolts',
        link: hitApi('get', `api/v1/parts/${action.payload.projectId}/?number=310750&number=310751`),
      }));
}

export function fetchRM10EvoModuleClampKit(action$: ActionsObservable<AnyAction>) {
  return action$.ofType(BomActionTypes.FETCH_RM10_EVO_MODULE_CLAMP_KIT)
    .switchMap(action => 
      ObservableAjax({
        takeUntil: action$.ofType(BomActionTypes.FETCH_RM10_EVO_MODULE_CLAMP_KIT),
        onSuccess: ADD_RM10_EVO_MODULE_CLAMP_KIT.bind(null, action.payload.qty),
        onErrorMessage: 'Cannot fetch rm10 evo module clamp kit',
        link: hitApi('get', `api/v1/parts/${action.payload.projectId}/?number=${PART_RM10_EVO_MODULE_CLAMP_KIT}`),
      }));
}

export function fetchFrontTrimEndCap(action$: ActionsObservable<AnyAction>) {
  return action$.ofType(BomActionTypes.FETCH_FRONT_TRIM_END_CAP)
    .switchMap(action =>
      ObservableAjax({
        takeUntil: action$.ofType(BomActionTypes.FETCH_FRONT_TRIM_END_CAP),
        onSuccess: ADD_FRONT_TRIM_END_CAP,
        onErrorMessage: 'Cannot fetch front trim end caps',
        link: hitApi('get', `api/v1/get_sm_trim_clips/?product_id=${action.payload.productId}&sm_pro=1&continuous_trims_sections=${action.payload.continuous_trims_sections}&project_id=${action.payload.projectId}&front_trim_quantity=${action.payload.front_trim_quantity}&fetch_front_trim_cap_or_not=${action.payload.fetch_front_trim_cap_or_not}`),
      }),
    );
}

export function removeFrontTrimEndCap(action$: ActionsObservable<AnyAction>) {
  return action$.ofType(BomActionTypes.REMOVE_FRONT_TRIM_END_CAP)
    .switchMap(action =>
      ObservableAjax({
        takeUntil: action$.ofType(BomActionTypes.REMOVE_FRONT_TRIM_END_CAP),
        onSuccess: REMOVE_EXTRA_MID_AND_END_CLAMP_QUANTITY,
        onErrorMessage: 'Cannot remove front trim end caps',
        link: hitApi('get', `api/v1/remove_sm_trim_clips/?product_id=${action.payload.productId}&continuous_trims_sections=${action.payload.continuous_trims_sections}&project_id=${action.payload.projectId}&front_trim_quantity=${action.payload.front_trim_quantity}`),
      }),
    );
}

export function saveBom(action$: ActionsObservable<AnyAction>) {
  return action$.ofType(BomActionTypes.SAVE_BOM)
    .switchMap(action =>
      ObservableAjax({
        takeUntil: action$.ofType(BomActionTypes.SAVE_BOM),
        onSuccess: action.payload.dontRedirect ? EMPTY_APP_ACTION : SET_BOM_PK,
        onErrorMessage: 'Cannot save bom',
        link: hitApi('post', `api/v1/bom/${action.payload.projectId}/`, action.payload.bomSaveRequestBody),
      }),
  );
}

export function autoSaveBom(action$: ActionsObservable<AnyAction>) {
  return action$.ofType(BomActionTypes.AUTO_SAVE_BOM)
    .debounceTime(300)
    .switchMap(action =>
      ObservableAjax({
        takeUntil: action$.ofType(BomActionTypes.SAVE_BOM),
        onSuccess: action.payload.dontRedirect ? EMPTY_APP_ACTION : SET_BOM_PK,
        onErrorMessage: 'Cannot auto save bom',
        link: hitApi('post', `api/v1/bom/${action.payload.projectId}/`, action.payload.bomSaveRequestBody),
      }),
  );
}


export function saveAndDownloadBomPdf(action$: ActionsObservable<AnyAction>, store: Store<appState>) {
  return action$.ofType(BomActionTypes.SAVE_BOM_AND_DOWNLOAD_BOM_PDF)
  .switchMap(action =>
    ObservableAjax({
      takeUntil: action$.ofType(BomActionTypes.SAVE_BOM_AND_DOWNLOAD_BOM_PDF),
      onSuccess: OPEN_BOM_REPORT.bind(null, action.payload.projectId, action.payload.productId, store.getState().engineeringProjectDocuments.units),
      onErrorMessage: 'Cannot save bom',
      link: hitApi('post', `api/v1/bom/${action.payload.projectId}/`, action.payload.bomSaveRequestBody),
    }),
);
}

export function fetchRM10EvoModuleClampSideBoltBulk(action$: ActionsObservable<AnyAction>) {
  return action$.ofType(BomActionTypes.FETCH_RM10_EVO_MODULE_CLAMP_SIDE_BOLT_BULK)
    .switchMap(action => 
      ObservableAjax({
        takeUntil: action$.ofType(BomActionTypes.FETCH_RM10_EVO_MODULE_CLAMP_SIDE_BOLT_BULK),
        onSuccess: ADD_RM10_EVO_MODULE_CLAMP_SIDE_BOLT_BULK.bind(null, action.payload.qty),
        onErrorMessage: 'Cannot fetch rm10 evo clamp side bolt bulk',
        link: hitApi('get', `api/v1/parts/${action.payload.projectId}/?number=${PART_RM10_EVO_MODULE_CLAMP_SIDE_BOLT_BULK}`),
      }));
}

export function fetchRM10EvoModuleClampBulk(action$: ActionsObservable<AnyAction>) {
  return action$.ofType(BomActionTypes.FETCH_RM10_EVO_MODULE_CLAMP_BULK)
    .switchMap(action => 
      ObservableAjax({
        takeUntil: action$.ofType(BomActionTypes.FETCH_RM10_EVO_MODULE_CLAMP_BULK),
        onSuccess: ADD_RM10_EVO_MODULE_CLAMP_BULK.bind(null, action.payload.qty),
        onErrorMessage: 'Cannot fetch rm10 evo clamp bulk',
        link: hitApi('get', `api/v1/parts/${action.payload.projectId}/?number=${PART_RM10_EVO_MODULE_CLAMP_BULK}`),
      }));
}

export function fetchTbolts009020SForSolarhooks(action$: ActionsObservable<AnyAction>) {
  return action$.ofType(BomActionTypes.FETCH_T_BOLTS_009020S_FOR_SOLARHOOKS)
    .switchMap(action => 
      ObservableAjax({
        takeUntil: action$.ofType(BomActionTypes.FETCH_T_BOLTS_009020S_FOR_SOLARHOOKS),
        onSuccess: ADD_T_BOLTS_009020S_FOR_SOLARHOOKS.bind(null, action.payload.qty),
        onErrorMessage: 'Cannot fetch Tbolts 009020S for solarhooks',
        link: hitApi('get', `api/v1/parts/${action.payload.projectId}/?number=${BND_T_BOLT_NUT_3_8_1_SS}`),
      }));
}
import { ActionTypes } from 'actionsConstants';
import { hitApi, ObservableAjax } from '__common/utils/api';
import { PROJECT_NOT_FOUND, REDIRECT_TO_PROJECT } from 'actions';
import { GET_APP_STATUS_SUCCESS, GET_APP_STATUS_FAILURE, SET_APP_REVISION_HISTORY, APP_REVISION_HISTORY_PAYLOAD } from './appActions';
import { ActionsObservable } from 'redux-observable';
import { AnyAction, Store } from 'redux';
import { Observable } from 'rxjs';
import { getCurrentTime } from '__common/calculations/currentTime';

export function searchProject(action$) {
  return action$.ofType(ActionTypes.SEARCH_PROJECT)
    .switchMap(action => 
      ObservableAjax({
        takeUntil: action$.ofType(ActionTypes.SEARCH_PROJECT),
        onSuccess: REDIRECT_TO_PROJECT,
        onError: PROJECT_NOT_FOUND,
        onErrorMessage: 'Project not exist',
        link: hitApi('get', `api/v1/search_project/${action.payload.projectId}/`),
      }));
}

export function copyProject(action$) {
  return action$.ofType(ActionTypes.COPY_PROJECT)
    .switchMap((action) => {
      const { projectId } = action.payload;
      return ObservableAjax({
        takeUntil: action$.ofType(ActionTypes.COPY_PROJECT),
        onSuccess: REDIRECT_TO_PROJECT,
        onErrorMessage: 'Cannot copy project',
        link: hitApi('get', `api/v1/duplicate_project/${projectId}/${getCurrentTime()}`),
      });
    });
}

export function getAppStatus(action$) {
  return action$.ofType(ActionTypes.GET_APP_STATUS)
    .switchMap(action => 
      ObservableAjax({
        takeUntil: action$.ofType(ActionTypes.GET_APP_STATUS),
        onSuccess: GET_APP_STATUS_SUCCESS,
        onError: GET_APP_STATUS_FAILURE,
        onErrorMessage: 'Application seems to be offline',
        link: hitApi('get', `api/v1/status/`),
      }));
}

export function getAppVersion(action$: ActionsObservable<AnyAction>, store: Store<appState>): Observable<any> {
  return action$.ofType(ActionTypes.GET_APP_REVISION_HISTORY)
    .switchMap(action => 
      ObservableAjax({
        onSuccess: ({ revision_history }: APP_REVISION_HISTORY_PAYLOAD) => {
          return SET_APP_REVISION_HISTORY(revision_history);
        },
        onErrorMessage: 'Could not fetch app revision',
        link: hitApi('get', `api/v1/revision/`),
      }));
}

import * as React from 'react';
import AccessoryPart from 'bom/components/accessoriesSelector/accessoryPart/accessoryPart';
import { areStandardClampsSelected } from 'bom/bomHelpers';
import { mlpePartNumber, NEW_NXT_MLPE_LUG_CLAMP_FOR_LUG_V1 } from '__common/constants/parts';

declare type NxtAccessorySelectorTypeComponentState = {
    accessoriesList : any
    dispatch : any
    productId : any
    projectId : any
    proSeriesClampsSelected: any
    partsList:any
    continuous_trims_sections:any
    rm_roof_pad:any
    row_to_row_bonding_qty : any
  };
const STANDARD_CLAMPS_PART_NUMBER = 'STDCLAMPS';
export class NxtAccessorySelector extends React.Component<NxtAccessorySelectorTypeComponentState,{}> {
  isSelected(accessoryNumber: string) { 
    
    const { partsList, accessoriesList } = this.props;   
    if (accessoryNumber === STANDARD_CLAMPS_PART_NUMBER) {
      return areStandardClampsSelected(partsList);
    }

    if(accessoryNumber === 'LUGMLPE1M' || accessoryNumber === NEW_NXT_MLPE_LUG_CLAMP_FOR_LUG_V1 || accessoryNumber === 'LUGMLPE1R') {
      const is_accessory_selected = accessoriesList.find(acc => acc.number === accessoryNumber).selected;
      return is_accessory_selected;
    }

    return partsList.filter(part => part.number === accessoryNumber).length > 0;
  }

  render() {
    const { accessoriesList, partsList, continuous_trims_sections, rm_roof_pad, dispatch, productId, projectId, proSeriesClampsSelected, row_to_row_bonding_qty } = this.props;
    const mlpeList = accessoriesList.filter(e=>["LUGMLPE1M","008114M"].includes(e.number))
    const lugList = accessoriesList.filter(e=>["LUGMLPE1","008009P","008002S"].includes(e.number))
    const rowToRowBondingList = row_to_row_bonding_qty > 0 ? accessoriesList.filter(e=>["LUGMLPE1R","008000U","008015S"].includes(e.number)) : []
    const remainingNXTAccessoryList = accessoriesList.filter((e) => !["LUGMLPE1R","008000U","008015S","LUGMLPE1","008009P","008002S","LUGMLPE1M","008114M",'WRMCLPD1','WRMCNSD1','00822CM','CMBTLM1'].includes(e.number))
    const wireManagementAccesories = accessoriesList.filter(e=>['WRMCLPD1','WRMCNSD1','00822CM', 'CMBTLM1', '008015S'].includes(e.number))

    return (
      <div>
        <div className="control-panel-label bom-sidebar-sub-main-header">
            Wire Management
        </div>
        {
          row_to_row_bonding_qty > 0 ? (
          <>
            <div className="control-panel-label bom-sidebar-sub-header">
             For Row To Row Bonding
            </div>
             {
            rowToRowBondingList.map((accessory, index) => {
                const isSelected = this.isSelected(accessory.number);
                return (<AccessoryPart
                key={`accessories-${index}`}
                accessory={accessory}
                accessoriesList={accessoriesList}
                dispatch={dispatch}
                isSelected={isSelected}
                productId={productId}
                projectId={projectId}
                proSeriesClampsSelected={proSeriesClampsSelected}
                partsList={partsList}
                continuous_trims_sections={continuous_trims_sections}
                rm_roof_pad={rm_roof_pad}
                />)})
            }
        </>
        ) :
          null
        }
        <>
          <div className="control-panel-label bom-sidebar-sub-header">
          For Grounding
          </div>
          {
          lugList.map((accessory, index) => {
              const isSelected = this.isSelected(accessory.number);
              return (<AccessoryPart
              key={`accessories-${index}`}
              accessory={accessory}
              accessoriesList={accessoriesList}
              dispatch={dispatch}
              isSelected={isSelected}
              productId={productId}
              projectId={projectId}
              proSeriesClampsSelected={proSeriesClampsSelected}
              partsList={partsList}
              continuous_trims_sections={continuous_trims_sections}
              rm_roof_pad={rm_roof_pad}
              />)})
          }
        </>
        <>
            <div className="control-panel-label bom-sidebar-sub-header">
            For MLPE
            </div>
            {
            mlpeList.map((accessory, index) => {
                const isSelected = this.isSelected(accessory.number);
                return (<AccessoryPart
                key={`accessories-${index}`}
                accessory={accessory}
                accessoriesList={accessoriesList}
                dispatch={dispatch}
                isSelected={isSelected}
                productId={productId}
                projectId={projectId}
                proSeriesClampsSelected={proSeriesClampsSelected}
                partsList={partsList}
                continuous_trims_sections={continuous_trims_sections}
                rm_roof_pad={rm_roof_pad}
            />)})
        }
        </>
        <div className="control-panel-label bom-sidebar-sub-main-header">
            Other Accessories
        </div>
        <>
        {
            wireManagementAccesories.map((accessory, index) => {
                const isSelected = this.isSelected(accessory.number);
                return (<AccessoryPart
                key={`accessories-${index}`}
                accessory={accessory}
                accessoriesList={accessoriesList}
                dispatch={dispatch}
                isSelected={isSelected}
                productId={productId}
                projectId={projectId}
                proSeriesClampsSelected={proSeriesClampsSelected}
                partsList={partsList}
                continuous_trims_sections={continuous_trims_sections}
                rm_roof_pad={rm_roof_pad}
            />)})
        }
        </>
        <>
        {
        remainingNXTAccessoryList.map((accessory, index) => {
            const isSelected = this.isSelected(accessory.number);
            return (<AccessoryPart
            key={`accessories-${index}`}
            accessory={accessory}
            accessoriesList={accessoriesList}
            dispatch={dispatch}
            isSelected={isSelected}
            productId={productId}
            projectId={projectId}
            proSeriesClampsSelected={proSeriesClampsSelected}
            partsList={partsList}
            continuous_trims_sections={continuous_trims_sections}
            rm_roof_pad={rm_roof_pad}
            />)})
        }
        </>
      </div>
    );
  }
}


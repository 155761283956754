import SnowLoadTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/snowLoadTooltip';
import { SnowLoadField, apiField } from 'projectDesign/components/projectConfiguration/fields/types/snowLoad';
import { InputUnit, isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';

function makeConfig(max: number = 100, label: string = 'snowpsf'): SnowLoadField {
  return {
    type: 'Number',
    label,
    apiField,
    tooltip: SnowLoadTooltip,
    min: 0,
    max,
    missing:undefined
  };
}

export const getSnowLoadField = (biggerRange = false, inputUnit: number = InputUnit.CUSTOMARY) => {
  if (isMetricUnit(inputUnit)) return snowLoadMetric;
  let upperLimit = 60;
  if (biggerRange) upperLimit = 100;
  return makeConfig(upperLimit);
};

export const snowLoadMetric = makeConfig(100, 'snowkpa');

const config = getSnowLoadField();

export default config;

import * as React from 'react';
import appConfig from 'local_config';
import ColorsVariantsSelector from 'bom/components/colorsVariantsSelector/colorsVariantsSelector';
import TotalListPrice from 'bom/components/TotalListPrice';
import { calculatePrices } from 'bom/bomHelpers';
import { SET_NEW_PART_QUANTITY } from 'actions';
import DataTable from 'react-md/lib/DataTables/DataTable';
import TableBody from 'react-md/lib/DataTables/TableBody';
import TableColumn from 'react-md/lib/DataTables/TableColumn';
import TableHeader from 'react-md/lib/DataTables/TableHeader';
import TableRow from 'react-md/lib/DataTables/TableRow';
import TextField from 'react-md/lib/TextFields/TextField';
import { saveBomAndDownloadPdf } from '../../utils/saveBom';
import { products } from '__common/constants/products';
import { sortBomTableByUniracRequest } from './utils/bomTableSorting';

type Props = {
  onChangeQuantity?: Function;
  preview?: boolean;
  productId?: string;
  partsList: bomPartsList;
  dispatch: Function,
  projectId: string,
};

export default class BomTable extends React.Component<Props, {}> {
  imageX: number;
  imageY: number;
  partImageName: string;
  showImage = false;

  printTable = () => {
    saveBomAndDownloadPdf();
  }

  tableTitle() {
    return (
      <div className="table-title">
        <div className="table-title-info control-panel-label">
          Complete part list
        </div>
        <div className="table-title-legend">
          <div>LEGEND</div>
          <div className="base-part-squere">
            <i role="button" className="material-icons">
              stop
            </i>
          </div>
          <div>Base System Part</div>
          <div className="accessory-part-squere">
            <i role="button" className="material-icons">
              stop
            </i>
          </div>
          <div>Accessory</div>
        </div>
        <div onClick={this.printTable} className="table-title-print">
          <div className="print-table-icon">
            <i tabIndex={0} role="button" className="material-icons">
              print
            </i>
          </div>
          <div>PRINT THIS LIST</div>
        </div>
      </div>
    );
  }

  tableHeader() {
    return (
      <TableHeader>
        <TableRow>
          <TableColumn>
            <div>part</div>
            <div>number</div>
          </TableColumn>
          <TableColumn>
            <div>part</div>
            <div>type</div>
          </TableColumn>
          <TableColumn>
            <div className="empty-div">.</div>
            <div>description</div>
          </TableColumn>
          <TableColumn>
            <div className="empty-div">.</div>
            quantity
          </TableColumn>
          <TableColumn>
            <div>suggested</div>
            <div>quantity</div>
          </TableColumn>
          <TableColumn>
            <div>unit</div>
            <div>price (USD)</div>
          </TableColumn>
          <TableColumn>
            <div>total list</div>
            <div>price (USD)</div>
          </TableColumn>
        </TableRow>
      </TableHeader>
    );
  }

  tableBody() {
    const { preview } = this.props;
    
    if (preview) {
      return (
        <TableBody>{this.tableRows()}</TableBody>
      );
    }

    return (
      <TableBody>{this.tableRows().concat(this.tableTotalRow())}</TableBody>
    );
  }

  showPartImage = (partImage, e) => {
    this.imageX = e.clientX + 10
    this.imageY = e.clientY
    this.partImageName = partImage;
    this.showImage = true;
    this.forceUpdate();
  }

  hidePartImage = () => {
    this.showImage = false;
    this.imageX = null;
    this.imageY = null;
    this.partImageName = null;
    this.forceUpdate();
  }

  validatePartQuantity = (partNumber, qty) => {
    const { dispatch } = this.props;
    
    if (qty > 1000000) {
      dispatch(SET_NEW_PART_QUANTITY(partNumber, qty));
    }
    
  }

  partQuantityColumn(part: bomPart) {
    const { preview } = this.props;

    if (preview) {
      return part.quantity || 0;
    }

    return (
      <TextField
        id="part-quantity"
        value={part.quantity}
        onBlur={this.validatePartQuantity.bind(this, part.number, part.quantity)}
        onChange={this.props.onChangeQuantity.bind(this, part.number)}
        type="number"
        onKeyDown = {(e)=>{
          if (e.key === '-' || e.key === '+' || e.key === '.' || e.key === 'e' || e.key === 'E') {
            e.preventDefault()
          }
        }}
        min={0}
      />
    );
  }

  dynamicSort = (property) => {
    let newProperty = property;
    let sortOrder = -1;
    if (property[0] === '-') {
      sortOrder = -1;
      newProperty = newProperty.substr(1);
    }
    return function (a, b) {
      const result =
        a[newProperty] < b[newProperty]
          ? -1
          : a[newProperty] > b[newProperty]
            ? 0
            : 1;
      return result * sortOrder;
    };
  }

  makePartTypeClassFriendly(className) {
    return className
      .split(' ')
      .join('-')
      .split('.')
      .join('-')
      .toLocaleLowerCase();
  }



  tableRows() {
    const { partsList, productId, preview } = this.props;
    let newPartList;
    const soloboxAndConduitMountParts = ["SBOXRAIL-D", "CMBTLM1"];
    const Sm168Parts = ["320168M", "310168D", "310168C"];
    const Sm208Parts = ["320208M", "310208C"];
    if(partsList && partsList.length>0) {
      newPartList = partsList.filter((elem, index, self) => self.findIndex(
        ele => ele.number === elem.number) === index);
        };
    return sortBomTableByUniracRequest(products[productId], newPartList)
      .map(
      (part: any, index) =>
        !part.disabled && (
          <TableRow key={this.makePartTypeClassFriendly(part.number)} className={`part-row part-row-${this.makePartTypeClassFriendly(part.number)}`}>
            <TableColumn className="part-number-and-image" style={{ position: 'relative'}}>
              <div style={{whiteSpace: 'pre-line'}} className={`part-number ${this.makePartTypeClassFriendly(part.number)}`}>{part.number.replace('6 (U','6 \n  (U')}</div>
              <img
                alt="part_image"
                className="part-image"
                src={`${location.protocol}//${appConfig.apiUrl.slice(0, -1)}${part.image}`}
              />
              <i
                tabIndex={0}
                role="button"
                className="material-icons"
                onMouseOver = {this.showPartImage.bind(null, part.image)}
                onMouseLeave={this.hidePartImage}
              >
                add_box
              </i>
            </TableColumn>
            <TableColumn
              className={
                part.category === 'Base'
                  ? `base-part ${this.makePartTypeClassFriendly(part.type)} ${this.makePartTypeClassFriendly(part.type)}-${this.makePartTypeClassFriendly(part.number)}`
                  : `accessory-part ${this.makePartTypeClassFriendly(part.type)} ${this.makePartTypeClassFriendly(part.type)}-${this.makePartTypeClassFriendly(part.number)}`
              }
            >
              {part.type}
            </TableColumn>
            <TableColumn
              grow={true}
              className="md-cell--middle desc-and-color"
              tooltipLabel={
                part.description.length > 100 ? part.description : null
              }
            >
              <span style={{ display: 'inline-block', paddingTop: '7px' }}>
                {part.description.length > 100
                  ? `${part.description.substring(0, 100).toUpperCase()}...`
                  : part.description.toUpperCase()}
                {soloboxAndConduitMountParts.includes(part.number) ? (<strong> - COMING SOON</strong>) : null}
                {Sm168Parts.includes(part.number) ? (<span className='part-number-warning'> - CONFIRM AVAILABILITY<br/> WITH YOUR DISTRIBUTOR, 168" LENGTH IS <br/>IS BEING DISCONTINUED - TRANSITIONING<br/> TO 185" LENGTH</span>) : null}
                {Sm208Parts.includes(part.number) ? (<span className='part-number-warning'> - CONFIRM AVAILABILITY<br/> WITH YOUR DISTRIBUTOR, 208" LENGTH IS <br/>IS BEING DISCONTINUED - TRANSITIONING<br/> TO 185" LENGTH</span>) : null}
                {part.domestic ? <p style={{ margin:'10px 0 5px 0', color: "#f2682A", fontSize: "10px", lineHeight: 1 }}>US-manufactured part available. For guaranteed domestic content, add -US to the end of the part number.</p> : null}
              </span>
              {part.variants &&
                !preview &&
                part.variants.length > 0 &&
                products[productId] !== 12 && (
                  <ColorsVariantsSelector
                    partToReplace={part}
                    variants={part.variants}
                  />
                )}
            </TableColumn>
            <TableColumn>{this.partQuantityColumn(part)}</TableColumn>
            <TableColumn>{part.suggested_quantity}</TableColumn>

            <TableColumn>{Number(part.price).toFixed(2)}</TableColumn>
            <TableColumn>{Number(part.price * part.quantity).toFixed(2)}</TableColumn>
          </TableRow>
        ),
    );
  }

  tableTotalRow() {
    const { partsList } = this.props;
    let newPartList;
    if(partsList && partsList.length>0) {
      newPartList = partsList.filter((elem, index, self) => self.findIndex(
        ele => ele.number === elem.number) === index);
        };
    const { baseSystemPrice, accessoriesPrice } = calculatePrices(newPartList);
    return (
      <TableRow key="total_row" className="part-row">
        <TableColumn style={{ position: 'relative' }} />
        <TableColumn style={{ position: 'relative' }} />
        <TableColumn style={{ position: 'relative' }} />
        <TableColumn style={{ position: 'relative' }} />
        <TableColumn style={{ position: 'relative' }} />
        <TableColumn style={{ position: 'relative' }} />
        <TableColumn style={{ position: 'relative' }}>
          <TotalListPrice totalPrice={baseSystemPrice + accessoriesPrice} />
        </TableColumn>
      </TableRow>
    );
  }

  partImage() {
    let imageDiv;
    if (this.showImage) {
      imageDiv = (
        <div
          className="preview-image-box"
          style={{ position: 'fixed', top: this.imageY, left: this.imageX }}
        >
          <img
            alt="part_image"
            src={`${location.protocol}//${appConfig.apiUrl.slice(0, -1)}${this.partImageName}`}
          />
        </div>
      );
    } else {
      imageDiv = null;
    }

    return imageDiv;
  }

  render() {
    return (
      <div className="bom_table">
        {this.tableTitle()}
        <DataTable className="bom_table_data" plain={true}>
          {this.tableHeader()}
          {this.props.partsList?.length ? this.tableBody(): null}
        </DataTable>
        {this.partImage()}
      </div>
    );
  }
}

import { autoSave, fetchProject, saveProject, setClampCheckFailed, saveProjectStartedBeforeAutosaveFinished } from '__editor/googleMapsRoofsSelector/googleMapsRoofsSelectorEpics';
import { changePassword } from 'changePassword/changePasswordEpics';
import { combineEpics } from 'redux-observable';
import { disableAccount } from 'disableAccount/disableAccountEpics';
import { fetchCurrentZipCode, getAddressByZip, activateRoofAreaTextPopUp, activateRoofAreaBanners, deactivateRoofAreaBanners, updateOnSetMapCenter } from '__editor/components/roofsSelector/components/roofsSelectorMap/roofsSelectorMapEpic';
import { fetchHelioscopeDesignsRequest } from 'helioscopeImport/components/helioscopeDesignsList/helioscopeDesignsListEpics';
import { fetchHelioscopeProjectsListRequest } from 'helioscopeImport/components/helioscopeProjectsList/HelioscopeProjectsListEpics';
import { fetchMfgAndModelRequest, fetchMfgModelsRequest, populateModelsByModule } from '__common/components/moduleSelector/moduleSelectorEpics';
import { fetchRails, selectRailTypeFromBom } from 'bom/components/railsSelector/railsSelectorEpic';
import { fetchStatics } from '__common/components/FetchStatics/fetchStaticsEpic';
import { getEnvironmentalFactors, saveProjectTitle, updateOnEnvironmentalFactorsUpdate, fetchRailArrangementListWhenClampCheckFailed, validateSetbackDistance, updateFlyoutOptionsOnInputUnitChange } from 'projectDesign/components/projectConfiguration/projectConfigurationEpics';
import { helioscopeDesignConfigInitDataRequest, helioscopeDesignFullDesignConfigInitDataRequest, importHelioscopeFullDesignProject, importHelioscopeProject } from 'helioscopeImport/components/helioscopeDesignsConfig/helioscopeDesignConfigEpics';
import { registrationRequest, userLogin, userLogout } from 'user/userEpics';
import { reportIssue } from 'app/components/ReportProblem/ReportProblemEpics';
import { resetPassword } from 'resetPassword/resetPasswordEpics';
import { searchProject, getAppStatus, getAppVersion, copyProject } from 'app/appEpics';
import { sendNewUserPreferences } from 'userPreferences/userPreferencesEpics';
import {
  downloadFullReport,
  generateBomPDF,
  generateEngineeringPDF,
  generateLayoutPDF,
  openFullReport,
  downloadBomReport,
  resetReportsOnUnitTypeChange,
  generateInternalEngineeringPDF,
  generateDetailedEngineeringPDF,
  generateEngineeringSummaryPDF,
  generateDxf,
  getDxf,
  generateConsolidatedDxf,
  getConsolidatedDxf,
  generateDxfZipAll,
  getDxfZipAll,
  getConstructionDxf,
  generateConstructionDxf,
  getPlansetDxf,
  generatePlansetDxf,
  generateLayoutTypeDxf,
  getLayoutTypeDxf,
  generatePeStampPDF
  } from 'engineering/components/engineeringProjectDocuments/engineeringProjectDocumentsEpics';
import {
  fetchEngineeringRaport,
  getCompilantLetter,
  setDiscount,
  updateEngineeringClientDetails,
  } from 'engineering/engineeringEpics';
import {
  combineProjects,
  deleteProject,
  getUserProjects,
  checkUB1Projects,
  initUserProjects,
  getCombinedproject,
  } from 'yourProjects/yourProjectsEpics';
import {
  fetchBomPartsListRequest,
  fetchFrontTrimEndCap,
  removeFrontTrimEndCap,
  saveBom,
  fetchRM10ModuleAndHexBolt,
  saveAndDownloadBomPdf,
  autoSaveBom,
  showMetalXAlertOnMLPEClipSelection,
  showEcoFoot2PlusAlert,
  fetchRM10EvoModuleClampKit,
  fetchRM10EvoModuleClampSideBoltBulk,
  fetchRM10EvoModuleClampBulk,
  fetchTbolts009020SForSolarhooks,
  fetchScreenshotRequest,
} from 'bom/bomEpics';
import {
  resetPasswordOnDemand,
  changePasswordOnDemand,
} from 'forgotPassword/forgotPasswordEpics';
import { generateBallastMap } from 'engineering/components/engineeringBallastMaps/engineeringBallastMapsEpics';
import * as panelsEpics from '__editor/panelsEditor/components/panels/panelsEpics';
import * as obstructionsEpics from '__editor/panelsEditor/components/obstructions/obstructionsEpics';
import { faqList } from 'app/components/FaqComponent/FaqEpics';

export default combineEpics(
  getDxfZipAll,
  generateDxfZipAll,
  getConsolidatedDxf,
  generateConsolidatedDxf,
  getConstructionDxf,
  generateConstructionDxf,
  getPlansetDxf,
  generatePlansetDxf,
  getDxf,
  generateDxf,
  getLayoutTypeDxf,
  generateLayoutTypeDxf,
  showMetalXAlertOnMLPEClipSelection,
  showEcoFoot2PlusAlert,
  sendNewUserPreferences,
  userLogin,
  userLogout,
  registrationRequest,
  fetchHelioscopeProjectsListRequest,
  fetchHelioscopeDesignsRequest,
  helioscopeDesignConfigInitDataRequest,
  helioscopeDesignFullDesignConfigInitDataRequest,
  fetchMfgAndModelRequest,
  fetchMfgModelsRequest,
  populateModelsByModule,
  importHelioscopeProject,
  importHelioscopeFullDesignProject,
  fetchBomPartsListRequest,
  fetchScreenshotRequest,
  fetchFrontTrimEndCap,
  removeFrontTrimEndCap,
  saveProject,
  autoSave,
  saveProjectStartedBeforeAutosaveFinished,
  fetchProject,
  setClampCheckFailed,
  fetchRailArrangementListWhenClampCheckFailed,
  validateSetbackDistance,
  fetchCurrentZipCode,
  getAddressByZip,
  activateRoofAreaTextPopUp,
  activateRoofAreaBanners,
  deactivateRoofAreaBanners,
  getEnvironmentalFactors,
  updateOnEnvironmentalFactorsUpdate,
  saveProjectTitle,
  saveBom,
  updateOnSetMapCenter,
  autoSaveBom,
  saveAndDownloadBomPdf,
  fetchEngineeringRaport,
  fetchRails,
  selectRailTypeFromBom,
  fetchRM10ModuleAndHexBolt,
  fetchRM10EvoModuleClampKit,
  fetchRM10EvoModuleClampSideBoltBulk,
  fetchRM10EvoModuleClampBulk,
  fetchTbolts009020SForSolarhooks,
  updateEngineeringClientDetails,
  getCompilantLetter,
  setDiscount,
  getUserProjects,
  checkUB1Projects,
  initUserProjects,
  deleteProject,
  copyProject,
  combineProjects,
  getCombinedproject,
  fetchStatics,
  searchProject,
  getAppStatus,
  getAppVersion,
  disableAccount,
  changePassword,
  resetPassword,
  resetPasswordOnDemand,
  changePasswordOnDemand,
  reportIssue,
  generateBomPDF,
  resetReportsOnUnitTypeChange,
  generateEngineeringPDF,
  generateEngineeringSummaryPDF,
  generateInternalEngineeringPDF,
  generateDetailedEngineeringPDF,
  generatePeStampPDF,
  generateLayoutPDF,
  downloadBomReport,
  downloadFullReport,
  openFullReport,
  generateBallastMap,
  panelsEpics.calculateWhichPanelsCanBeAdded,
  panelsEpics.updateRestrictedAreaWhenPanelsAdded,
  panelsEpics.resetKdTreeStoreOnRoofCleared,
  panelsEpics.updateRoofEdgesForRMBlankDesign,
  panelsEpics.resetVirtualRoofEdgesOnResetPanelState,
  panelsEpics.checkTableWidthBounds,
  panelsEpics.UpdateTableRowSpacingBasedOnTableHeight,
  panelsEpics.updatePanelGroupIdsToReflectActualGroups,
  panelsEpics.updatePanelSpacings,
  panelsEpics.detectInvalidPanels,
  panelsEpics.updateRMDTPanelZone,
  panelsEpics.updatePanelZone,
  panelsEpics.updateRmPanelZone,
  panelsEpics.setRoofEdgesForRMIFIBlankDesign,
  panelsEpics.setPanelsGroupArrangement,
  panelsEpics.restorePanelsAfterNoMovement,
  obstructionsEpics.filterOutPanelsCollidingWithObstruction,
  updateFlyoutOptionsOnInputUnitChange,
  faqList
);

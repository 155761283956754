import Leaflet, { LatLngLiteral } from 'leaflet';
import RoofShape from '__editor/bingMapsRoofsSelector/models/selectedArea';
import { clickedRoofEdgeData, SOUTH_EDGE_CHANGE_WARNING } from '__editor/components/roofsSelector/components/roofsSelectorLeadEdge/utils/roofsSelectorSelectEdge';
import { createBingMapPopup, createBingMapAzimuth } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorDrawingManager/bingMapsRoofsSelectorDrawingManagerHelper';
import { dispatch, state } from '__common/store';
import { getBingMap } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorMap/bingMapsRoofsSelectorMap';
import { isClickOnRoofEdge } from '__editor/googleMapsRoofsSelector/components/leadEdgeRoofSelector/utils/selectEdge';
import { panelsEditorEnabled } from 'projectDesign/projectDesign';
import { removePlaceMarker } from '../../../components/roofsSelector/components/roofsSelectorMap/roofsSelectorMap';
import { savePanelsEditor } from '__editor/panelsEditor/components/panels/panels';
import { SELECT_ROOF_LEAD_EDGE } from '__editor/googleMapsRoofsSelector/googleMapsRoofsSelectorActions';
import { selectBingRoofLeadEdge } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorLeadEdge/utils/selectBingLeadEdge';
import CenterMarker, {
  emptyRoofMarkerIcon,
} from '__editor/bingMapsRoofsSelector/models/centerMarker';
import {
  removeBingLeadEdgeObject,
  makeBingLeadEdgeEmpty,
} from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorLeadEdge/bingMapsRoofsSelectorLeadEdge';
import {
  ACTIVE_PANELS_EDITOR,
  ADD_ROOF,
  CLEAR_ROOF_LEAD_EDGE,
  CLEAR_SELECTION,
  DELETE_ROOF,
  SET_SELECTED_ROOF_AND_MARKER,
  UNSET_SELECTED_ROOF_AND_MARKER,
  UNSELECT_ALL_SELECTED_ROOF_AND_MARKER,
  OPEN_CONFIRM_CLEAR_ARRAYS_MODAL,
  DELETE_PANELS_INSIDE_OF_ROOF,
} from 'actions';
import $ from 'jquery';
import {
  roofHasPanels,
  emptyRoofColor,
} from '../../../components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManagerHelper';
import { goToPanelsEditor } from '../../../components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManager';
import { roofHasObstructions } from '__editor/panelsEditor/components/obstructions/obstructions';
import { launchRecheckPanelExposure } from '__editor/panelsEditor/components/panels/utils/exposureRecheck';
import { isModuleSelectorValid } from '__common/components/moduleSelector/moduleSelectorHelper';
import { get } from 'lodash';
import { convertToPixelPoints, getLeadEdge } from '__editor/panelsEditor/components/leadEdge/leadEdge';
import { getRoofAreaAzimuth } from '__editor/panelsEditor/components/leadEdge/utils/roofAreaAzimuth';
import roofDimension from '__editor/bingMapsRoofsSelector/models/roofDimension';
import { isBlankMap } from '__common/constants/map';

let roofsOnMap: { [roofId: number]: bingRoofsObject } | null = null;

let roofOver;

export function getBingRoofOver() {
  return roofOver;
}

export function setBingMapAzimuthInfo(roofId: number, coords: LatLngLiteral[], leadEdgeCoords: LatLngLiteral[], previewMode: boolean = false) {
  
  if (!roofsOnMap || !roofsOnMap[roofId] || previewMode) return; // skip update
  
  const { roofsSelectorMap: { zoom } } = state();
  const map = getBingMap();

  if (roofsOnMap[roofId].azimuthWindow) {
    roofsOnMap[roofId].azimuthWindow.removeFrom(map);
  }

  const roof = roofsOnMap[roofId].roof;
  const roofEdges = coords;
  const { lat,lng } = roof.overlay.getBounds().getCenter();
  const roofCenter = { lat, lng };
  const middlePoint = Leaflet.latLngBounds(leadEdgeCoords).getCenter();
  const leadEdgeCords = convertToPixelPoints(leadEdgeCoords, roofCenter , zoom);
  const azimuth = getRoofAreaAzimuth(leadEdgeCords, roofEdges, roofCenter, zoom);

  const azimuthWindow = createBingMapAzimuth(azimuth, middlePoint);
  if(azimuthWindow) {
    roofsOnMap[roofId].azimuthWindow = azimuthWindow;
    azimuthWindow.addTo(map);
  }
}

export function setBingMapInfoWindow(roofId: number, previewMode: boolean = false) {
  const map = getBingMap();
  if (!roofsOnMap || !roofsOnMap[roofId]) return; // skip update
  if (roofsOnMap[roofId].infoWindow) {
    roofsOnMap[roofId].infoWindow.removeFrom(map);
  }
  const {
    drawingManager: { roofs },
    background : { selectedRoofId },
  } = state();
  const shouldDisplay =
    roofs &&
    roofs[roofId] &&
    roofs[roofId].panels &&
    !!roofs[roofId].panels.length &&
    !previewMode;
  const infoWindow = createBingMapPopup(
    roofId,
    get(roofs, [roofId, 'roofName'], ''),
    get(roofs, [roofId, 'panels', 'length'], 0),
    roofsOnMap[roofId].roof.overlay.getBounds().getCenter(),
    shouldDisplay,
    map,
    previewMode,
  );

  if (infoWindow) {
    roofsOnMap[roofId].infoWindow = infoWindow;
    infoWindow.addTo(map);
  }
  if (infoWindow && roofId === selectedRoofId){
    roofsOnMap[selectedRoofId].infoWindow.removeFrom(map);
  }
  if (infoWindow && roofId !== selectedRoofId && selectedRoofId){
    if (roofsOnMap && Object.keys(roofsOnMap).length>0){
      const roofsArray = Object.keys(roofsOnMap)
      if ((roofsArray.indexOf(selectedRoofId.toString()) != -1)){
        roofsOnMap[selectedRoofId].infoWindow.removeFrom(map);
      }
    }
  }
}

export function getBingRoofOnMap(roofId: number) {
  if (roofsOnMap && Object.keys(roofsOnMap).length) {
    return roofsOnMap[roofId];
  }
}

export function bingRoofAreaMarkerLatLngs(coords: { lat: number; lng: number}[],
  roofId?: number){
  const roof = RoofShape(coords, roofId);
  const { lat, lng } = roof.overlay.getBounds().getCenter();
  return {checkingMarker : { lat, lng }}
  }

export function createBingMapRoofArea(
  coords: { lat: number; lng: number }[],
  roofId?: number,
  previewMode?: boolean,
) {
  const {
    roofsSelector: { mapType },
  } = state();
  const map = getBingMap();

  map.on('contextmenu', (e: Leaflet.LeafletEvent) => {
    e?.originalEvent?.preventDefault && e.originalEvent.preventDefault();
    e?.originalEvent?.stopPropogation && e.originalEvent.stopPropogation();
  });

  const roof = RoofShape(coords, roofId);
  
  const marker = CenterMarker(roof.overlay.getBounds().getCenter(), roofId);
  roof.clickListener = roof.overlay.on('click', (event: Leaflet.LeafletMouseEvent) =>
    roofBingClickEvent(event, roof, previewMode),
  );
  roof.clickListener = roof.overlay.on('mouseover', event => {
    roofOver = roof.id;
  });
  marker.clickListener = marker.on('click', () => {
    // when the module selector is invalid we keep drawer open and don't want to allow to edit panels
    if (isModuleSelectorValid()) {
      return goToPanelsEditor(roof.overlay.getBounds().getCenter(), roof.id);
    }
  });
  roof.overlay.addTo(map);
  marker.addTo(map);

  if (roofsOnMap === null) roofsOnMap = {};
  roofsOnMap[roof.id] = {
    roof,
    marker
  };

  if(!previewMode && mapType !== 'white') {
    const dimension = roofDimension(coords, roofHasPanels(roof.id));
    dimension.addTo(map);
    roofsOnMap[roof.id].dimension = dimension;
  }

  setBingMapInfoWindow(roof.id, previewMode);

  if (mapType !== 'white') {
    const leadEdgePath = getLeadEdge(roof.id, coords);
    setBingMapAzimuthInfo(roof.id, coords, leadEdgePath, previewMode);
  }

  return {
    coords,
    marker: roof.overlay.getBounds().getCenter(),
    id: roof.id,
    roof,
  };
}

export function addNewBingMapRoofArea(
  roofCoords: { lat: number; lng: number }[],
  roofEdgesPixiCords?: { x: number; y: number }[],
  roofId?: number,
) {
  const { marker, coords, id } = createBingMapRoofArea(roofCoords, roofId);
  dispatch(ADD_ROOF(coords, roofEdgesPixiCords?? [], marker, id));
  return id;
}

export function clearBingRoofsOnMaps() {
  if (roofsOnMap && Object.keys(roofsOnMap).length) {
    roofsOnMap = null;
  }
}

export function goToBingPanelsEditor(
  roofCenter: Leaflet.LatLng,
  roofId: number,
) {
  const { drawingManager: { roofs }, panels: { panels } } = state();
  const { coords: roofCoords, marker: center, blank_map_building_length: length, blank_map_building_width: width, roofEdgesPixiCords, panelsRotationDegrees, lowEdgeToRoof } = roofs[roofId];
  removePlaceMarker();
  if (panelsEditorEnabled()) {
    const panelsIdsToRecheck = panels.map(panel => panel.id);
    return launchRecheckPanelExposure(panelsIdsToRecheck, roofId, () => { 
      savePanelsEditor();
      const { drawingManager: { roofs } } = state();
      const { coords: roofCoords, marker: center, blank_map_building_length: length, blank_map_building_width: width, roofEdgesPixiCords, panelsRotationDegrees, lowEdgeToRoof } = roofs[roofId];
      dispatch(ACTIVE_PANELS_EDITOR(center || roofCenter, roofCoords, roofId, roofEdgesPixiCords, length, width, panelsRotationDegrees, lowEdgeToRoof));
    });
  }
  dispatch(ACTIVE_PANELS_EDITOR(center || roofCenter, roofCoords, roofId, roofEdgesPixiCords, length, width, panelsRotationDegrees, lowEdgeToRoof));
}

export function roofBingClickEvent(event: Leaflet.LeafletMouseEvent, roof: roofOnMap, preview: boolean) {
  const {
    drawingManager: { editEnabled },
  } = state();
  const clickRoofEdgePath = clickedRoofEdgeData(
    event.latlng,
    isClickOnRoofEdge,
  );
  if (editEnabled) {
    toogleBingRoofSelection(roof.id);
  } else if (clickRoofEdgePath && !preview) {
    roofHasPanels(roof.id) || roofHasObstructions(roof.id)
      ? confirmChangeSouthEdge(clickRoofEdgePath, roof.id)
      : selectBingRoofLeadEdge(clickRoofEdgePath);
  }
}

export function clearBingSelection() {
  dispatch(UNSELECT_ALL_SELECTED_ROOF_AND_MARKER());
  if (roofsOnMap) {
    Object.keys(roofsOnMap).map((roofId: string) => {
      roofsOnMap[roofId].roof.overlay.editMode = false;
      roofsOnMap[roofId].roof.overlay.disableEdit();
    });
  }
}

export function deleteAllBingShapes() {
  if (roofsOnMap) {
    Object.keys(roofsOnMap).map(roofId => {
      removeBingShape(parseInt(roofId, 10));
    });
  }
}

export function getAllBingRoofObject(): { [roofId: number]: bingRoofsObject } {
  if (roofsOnMap) {
    return roofsOnMap;
  }
}

export function zoomOnBingRoof(roofId: number) {
  const roofBounds = roofsOnMap[roofId].roof.overlay.getBounds();
  getBingMap().fitBounds(roofBounds);
}

export function zoomOnBingAllRoofs() {
  const roofsGroups = Object.keys(roofsOnMap).map(roofId => {
    return roofsOnMap[roofId].roof.overlay;
  });

  const group = Leaflet.featureGroup(roofsGroups);
  getBingMap().fitBounds(group.getBounds());
}

export function updateCenterMarkerPosition(roofId: number, center: Leaflet.LatLng) {
  const roofMarker = roofsOnMap[roofId].marker;
  roofMarker.setLatLng(center);
}

export function updateRoofDimensions(roofId: number, enableEdit: boolean) {
  const map = getBingMap();
  const { drawingManager: { roofs }, roofsSelector : { mapType } } = state();
  if (!isBlankMap(mapType)){
    roofsOnMap[roofId].dimension.removeFrom(map);
    if(!enableEdit) {
      const dimension = roofDimension(roofs[roofId].coords, roofHasPanels(roofId));
      roofsOnMap[roofId].dimension = dimension;
      dimension.addTo(map);
  }}
}

export function updateInfoWindowPosition(roofId: number, center: Leaflet.LatLng) {
  const infoWindow = roofsOnMap[roofId].infoWindow;
  infoWindow.setLatLng(center);
}

export function removeBingShape(roofId: number) {
  const map = getBingMap();
  roofsOnMap[roofId].roof.overlay.disableEdit();
  roofsOnMap[roofId].roof.overlay.removeFrom(map);
  roofsOnMap[roofId].marker.removeFrom(map);
  if (roofsOnMap[roofId].dimension) {
    roofsOnMap[roofId].dimension.removeFrom(map);
  }
  if (roofsOnMap[roofId].infoWindow) {
    roofsOnMap[roofId].infoWindow.removeFrom(map);
  }
  if (roofsOnMap[roofId].azimuthWindow) {
    roofsOnMap[roofId].azimuthWindow.removeFrom(map);
  }
  removeBingLeadEdgeObject(roofId);
  delete roofsOnMap[roofId];
  dispatch(DELETE_ROOF(roofId));
  dispatch(CLEAR_ROOF_LEAD_EDGE(roofId));
  dispatch(CLEAR_SELECTION());
}

function confirmChangeSouthEdge(
  clickRoofEdgePath: { edgeNumber: number; roofId: number },
  roofId: number,
) {
  dispatch(
    OPEN_CONFIRM_CLEAR_ARRAYS_MODAL(
      'South edge selection',
      SOUTH_EDGE_CHANGE_WARNING,
      () => {
        selectBingRoofLeadEdge(clickRoofEdgePath);
        makeBingRoofEmpty(roofId);
      },
    ),
  );
}

export function makeBingRoofEmpty(roofId) {

  const icon = Leaflet.icon({
    iconUrl: emptyRoofMarkerIcon,
    iconSize: [50, 50],
    iconAnchor: [25, 25],
  });

  makeBingLeadEdgeEmpty(roofId);

  roofsOnMap[roofId].roof.overlay.setStyle({
    fillColor: emptyRoofColor,
    color: emptyRoofColor,
  });
  roofsOnMap[roofId].marker.setIcon(icon);
  dispatch(DELETE_PANELS_INSIDE_OF_ROOF(roofId));
  updateRoofDimensions(roofId, false);
  setBingMapInfoWindow(roofId);
}

export function toogleBingRoofSelection(roofId: number) {
  const roof = roofsOnMap[roofId].roof;

  if (roof.overlay.editMode) {
    roof.overlay.editMode = false;
    roof.overlay.disableEdit();
    dispatch(UNSET_SELECTED_ROOF_AND_MARKER(roofId));
    $('.leaflet-middle-icon').remove();
    updateRoofDimensions(roofId, false);
  } else {
    roof.overlay.editMode = true;
    roof.overlay.enableEdit();
    dispatch(SET_SELECTED_ROOF_AND_MARKER(roofId));
    updateRoofDimensions(roofId, true);
  }
}

export function turnOffBingEditing() {
  const getIds = Object.keys(roofsOnMap);

  if (getIds.length) {
    getIds.map(id => {
      const roof = roofsOnMap[id].roof;

      if (roof.overlay.editMode) {
        roof.overlay.editMode = false;
        roof.overlay.disableEdit();
        dispatch(UNSET_SELECTED_ROOF_AND_MARKER(Number(id)));
      }
    });
  }
}

export function redrawBingRoof(roofId: number, newCoords?: {lat: number, lng: number}[]) {
  const { drawingManager: { roofs }, leadEdgeRoofSelector: { leadEdges } } = state();
  const coords = newCoords || roofs[roofId].coords;
  const pixiCoords = roofs[roofId].mapPixiCoords;
  const leadEdge = leadEdges[roofId];
  removeBingShape(roofId);

  addNewBingMapRoofArea(coords, pixiCoords);

  dispatch(SELECT_ROOF_LEAD_EDGE(leadEdge, roofId));
}

export function redrawBingRoofs() {
  if (roofsOnMap) {
    Object.keys(roofsOnMap).map(roofId => {
      redrawBingRoof(parseInt(roofId, 10));
    });
  }
}

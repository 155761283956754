import * as React from 'react';
import { connect } from 'react-redux';
import { PANELS_SET_ROTATION, REMOVE_ROOF_OBSTRUCTIONS, RESET_PANELS_STATE } from 'actions';
import ConfirmationTextField from '__common/components/ConfirmationTextField';
import Tooltip from '__common/components/Tooltip';

interface props {
  degrees: number;
  dispatch: Function;
  changePanelsRotationDegrees: (degrees: Number, roofId: number) => void;
  panels: panelInState[],
  selectedRoofId: number;
  obstructions: obstructionsState;
}

interface state {
  degrees: string | number;
  isValid: boolean;
}


class PanelsRotation extends React.Component<props, state> {
  constructor(props: props) {
    super(props);
    this.state = {
      isValid: true,
      degrees: props.degrees,
    };
  }
  static minSize = 0;
  static maxSize = 45;

  onDegreeChange = (value: number) => {
    if(value >= PanelsRotation.minSize && value <= PanelsRotation.maxSize) {
      this.setState({ ...this.state, degrees: value, isValid: true });
      const degree = parseFloat(Number(value).toFixed(1).replace(/\.?0*$/, ''));
      this.props.changePanelsRotationDegrees(degree, this.props.selectedRoofId);
    }
    else {
      this.setState({ ...this.state, degrees: value, isValid: false });
    }
  }

  getRangeHelper() {
    return `(${PanelsRotation.minSize}-${PanelsRotation.maxSize})`;
  }

  renderError() {
    if (this.state.isValid) {
      return null;
    }

    const errorMessage = `Rotation Angle must be in range ${PanelsRotation.minSize} - ${PanelsRotation.maxSize}`;

    return (
      <div className="input-warning">{errorMessage}</div>
    );
  }

  _hasObstructions = () => {
    const { obstructions: {obstructions}, selectedRoofId } = this.props;
    return obstructions && obstructions[selectedRoofId] && Object.keys(obstructions[selectedRoofId]).length > 0;
  }
  

  _hasPanels = () => {
    const { panels } = this.props;
    return panels && Array.isArray(panels) && panels.length > 0;
  }

  shouldConfirm = (value: number) => {
    if (value >= PanelsRotation.minSize && value <= PanelsRotation.maxSize) {
      return this._hasPanels() || this._hasObstructions();
    }
    else {
      return false;
    }
  }

  render() {
    const { degrees } = this.state;
    return (
      <div className="desired-table-size">
            <div className="input-label">
              Skew Angle (degree):
              <Tooltip
                id={'rotation-angle'}
                content={`Skew Angle (degree)`}
                position="right"
              />
          </div>
          <ConfirmationTextField
            value={degrees ?? ""}
            onConfirm={value => this.onDegreeChange(value)}
            title="Are you sure you want to change skew angle?"
            content="Current layout will be lost"
            shouldConfirm={true}
            shouldConfirmCb={(value) => this.shouldConfirm(value)}
            textFieldProps={{
              lineDirection: 'center',
              type: 'number',
              step: 0.1,
              min: 0,
              max: 45,
              fullWidth: false,
            }}
          />
        {this.renderError()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    changePanelsRotationDegrees: (degrees: number, roofId: number) => {
      dispatch(RESET_PANELS_STATE());
      dispatch(PANELS_SET_ROTATION(degrees));
      dispatch(REMOVE_ROOF_OBSTRUCTIONS(roofId));
    }
  };
};

const mapStateToProps = (state: appState) => {
  return {
    degrees: state.background.panelsRotationDegrees,
    panels: state.panels.panels,
    selectedRoofId: state.background.selectedRoofId,
    obstructions: state.obstructions,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PanelsRotation);

import React from "react";
import TextField from 'react-md/lib/TextFields/TextField';
import { Button } from "react-md";
import { connect } from 'react-redux';
import { UPDATE_PANEL_SPACINGS } from "../../__editor/panelsEditor/components/panels/panelsActions";
import { metersToInches, round } from "maths";
import { getDetectedModuleSpacing } from "__editor/panelsEditor/components/panels/utils/panelSpacingsUpdate";
import hash from 'object-hash';

const precision = 2;

type State = {
  rowSpacingInches: number,
  columnSpacingInches: number,
  detectedRowSpacingInches: number,
  detectedColumnSpacingInches: number,
  productRowSpacingInches: number,
  productColumnSpacingInches: number,
  panelsHash: string,
};

type Props = {
  dispatch: Function,
  productId: number,
  panels: panelInState[],
  stateRowSpacing: number,
  stateColumnSpacing: number,
  metersPerPixel: number,
};

class PanelSpacingUpdator extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    let {stateRowSpacing: _rowSpacing,  stateColumnSpacing: _columnSpacing} = this.props;
    [_rowSpacing, _columnSpacing] = [_rowSpacing, _columnSpacing].map(v => round(metersToInches(v), precision));
    this.state = {
      rowSpacingInches: null,
      columnSpacingInches: null,
      detectedRowSpacingInches: _rowSpacing,
      detectedColumnSpacingInches: _columnSpacing,
      productRowSpacingInches:_rowSpacing,
      productColumnSpacingInches: _columnSpacing,
      panelsHash: hash(this.props.panels),
    };
    let {detectedRowSpacingPx, detectedColumnSpacingPx} = getDetectedModuleSpacing(this.props);
    this.state = {
      ...this.state,
      detectedRowSpacingInches: round(metersToInches(detectedRowSpacingPx * this.props.metersPerPixel), precision),
      detectedColumnSpacingInches: round(metersToInches(detectedColumnSpacingPx * this.props.metersPerPixel), precision),
    };
  }

  componentDidUpdate(prevProps) {
    if (this.state.panelsHash !== hash(this.props.panels)) {
      const {detectedRowSpacingPx, detectedColumnSpacingPx} = getDetectedModuleSpacing(this.props);
      this.setState(
        {
          ...this.state,
          detectedRowSpacingInches: round(metersToInches(detectedRowSpacingPx * this.props.metersPerPixel), precision),
          detectedColumnSpacingInches: round(metersToInches(detectedColumnSpacingPx * this.props.metersPerPixel), precision),
          panelsHash: hash(this.props.panels),
        }
      ); 
    }
  }

  action() {
    this.props.dispatch(UPDATE_PANEL_SPACINGS(this.state.rowSpacingInches, this.state.columnSpacingInches));
  }

  setVal(property, value) {
    if (isNaN(Number(value))) return;
    this.setState({
      ...this.state, [property]: (value === '' || value === null)? null: Number(value)
    });
  }

  getRowSpacingLabel = () => {
    return <div className="input-label">Select Row Spacing </div>;
  }

  getColumnSpacingLabel = () => {
    return <div className="input-label">Select Column Spacing </div>;
  }

  shouldUpdateSpacings = () => {
    const { rowSpacingInches, columnSpacingInches, detectedRowSpacingInches, detectedColumnSpacingInches } = this.state;
    return rowSpacingInches !== null && columnSpacingInches !== null &&
     (rowSpacingInches !== detectedRowSpacingInches || columnSpacingInches !== detectedColumnSpacingInches);
  }

  detectedSpacingsSameAsProductSpacings = () => {
    const { detectedRowSpacingInches, detectedColumnSpacingInches, productRowSpacingInches, productColumnSpacingInches } = this.state;
    return detectedRowSpacingInches === productRowSpacingInches && detectedColumnSpacingInches === productColumnSpacingInches;
  }

  render() {
    if (!this.props.panels.length) return null;
    return (
      <>
        <div className="input-label">Panel Spacings:</div>
        {!this.detectedSpacingsSameAsProductSpacings() &&
        <>
          <p>Product Spec. Row Spacing: {this.state.productRowSpacingInches} </p>
          <p>Product Spec. Column Spacing: {this.state.productColumnSpacingInches} </p>
        </>}
        <p>Detected Row Spacing: {this.state.detectedRowSpacingInches} </p>
        <p>Detected Column Spacing: {this.state.detectedColumnSpacingInches} </p>
        {this.detectedSpacingsSameAsProductSpacings() && <p>(Same as Product Spec. Spacings)</p>}
        <TextField
          id='PanelSpacingUpdator'
          value={this.state.rowSpacingInches}
          label={this.getRowSpacingLabel()}
          onChange={v => this.setVal('rowSpacingInches', v)}
          lineDirection="center"
          type={'number'}
          fullWidth={true}
          key={`row spacing`} />
        <TextField
          id='PanelSpacingUpdator'
          value={this.state.columnSpacingInches}
          label={this.getColumnSpacingLabel()}
          onChange={v => this.setVal('columnSpacingInches', v)}
          lineDirection="center"
          type={'number'}
          fullWidth={true}
          key={`col spacing`} />
          <Button raised={true} primary={true} flat={true} disabled={!this.shouldUpdateSpacings()} onClick={() => this.action()}>
            Update Spacings
          </Button>
          <p></p>
      </>
    );
  }
}


function mapStateToProps(state: appState) {
  return {
    productId: state.projectConfiguration.productId,
    stateRowSpacing: state.settings.rowSpacing,
    stateColumnSpacing: state.settings.columnSpacing,
    metersPerPixel: state.background.metersPerPixel,
    panels: state.panels.panels,
  };
}

export default connect(mapStateToProps)(PanelSpacingUpdator);

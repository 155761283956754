import * as React from 'react';
import AppHistory from '__common/modules/history';
import autobind from 'autobind-decorator';
import BackButton from 'app/components/FooterComponent/BackButton';
import BingMapsRoofsSelector from '__editor/bingMapsRoofsSelector/bingMapsRoofsSelector';
import ContinueButton from 'app/components/FooterComponent/ContinueButton';
import Footer from 'app/components/FooterComponent';
import GoogleMapsRoofsSelector from '__editor/googleMapsRoofsSelector/googleMapsRoofsSelector';
import Header from 'app/components/HeaderComponent';
import LastEditorSave from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/components/lastEditorSave';
import Loader from '__common/components/LoaderComponent';
import PanelsEditor from '__editor/panelsEditor/panelsEditor';
import ProjectInfoHeaderMiddleBar from 'projectDesign/components/ProjectInfoHeaderMiddleBar';
import RedirectFromSmLtAndSmHd from 'projectDesign/components/RedirectFromSmLtAndSmHd';
import { clearRoofsSelectorData } from '__editor/components/roofsSelector/roofsSelectorHelper';
import { connect } from 'react-redux';
import { getBingZipCode } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorMap/bingMapsRoofsSelectorMap';
import { getDefaultProjectConfiguration } from 'projectDesign/components/projectConfiguration/utils/defaultProps';
import { getGoogleZipCode } from '__editor/googleMapsRoofsSelector/components/map/map';
import { getProductTitle } from '__common/constants/products_titles';
import { loadProject, loadProjectData } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject//loadProject';
import { panelsEditorEnabled, startNewProject, userWentBackToDesignLtOrHd } from 'projectDesign/projectDesign';
import { saveProject, clearAutoSaveTimeout, savePanelEditorAndSaveProject } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/saveProject';
import { setProductSettings } from '__editor/panelsEditor/components/productsSettings/productsSettings';
import {
  LOAD_PROJECT_CONFIGURATION,
  SET_FIELD_WARNING,
  SET_PRODUCT_ID,
  SET_PRODUCT_TITLE,
  SET_PROJECT_ID,
} from 'actions';
import {
  products,
  isSMLT,
  isSMHD,
  getProductName,
  isSMFamily,
  isRM10,
  isRM10Evolution,
  isRMGridFlex,
  isSMTiltPR,
  isSMAscenderFlush,
  isSMTiltPRLT,
  isRmGridflex10,
  isSMFlushOrTiltOrTiltPR,
  isSMTilt,
  isNxtTilt,
} from '__common/constants/products';
import { loadDefaultModule } from '__common/components/moduleSelector/moduleSelector';
import { deactivePanelsEditor } from '__editor/panelsEditor/panelsEditorHelper';
import CursorPosition from './utils/cursorPosition';
import { SET_LOADER_MSG } from 'app/appActions';
import { apiField as attachmentTypeApiField, AttachmentType } from 'projectDesign/components/projectConfiguration/fields/attachmentType';
import { adjustableTiltSystem } from '__common/utils/versionCompare/versionCompare';

export interface projectDesignProps {
  background: backgroundState;
  dispatch: Function;
  moduleSelector: moduleSelectorState;
  drawingManager: drawingManagerState;
  projectConfiguration: projectConfigurationState;
  router: any;
  roofsSelector: roofsSelectorState;
  saveLoadProject: saveLoadProjectState;
  userPreferences: userPreferencesState;
  match: any;
  cords: { lat: number, lng: number };
  productDisabledInfo: productDisabledInfoState;
  user: userState;
  app: appConfigState;
  settings: settingsState,
}

type projectDesignState = {
  isProjectLoading: boolean,
  tryRedirectToNormalSM: boolean,
  canvasInited: boolean,
};


class ProjectDesign extends React.Component<projectDesignProps, projectDesignState> {

  constructor(props: projectDesignProps) {
    super(props);
    this.state = { isProjectLoading: false, tryRedirectToNormalSM: false, canvasInited: false };
  }

  shouldComponentUpdate(newProps: projectDesignProps, newState: projectDesignState) {
    const { saveLoadProject: { isSaveProjectPending } } = this.props;
    return newProps.background.cords !== this.props.background.cords ||
      this.props.saveLoadProject.isLoading !== newProps.saveLoadProject.isLoading ||
      this.props.moduleSelector.allInLoading !== newProps.moduleSelector.allInLoading ||
      this.props.roofsSelector.mapType !== newProps.roofsSelector.mapType ||
      this.props.roofsSelector.mapTypeSelected !== newProps.roofsSelector.mapTypeSelected ||
      this.props.saveLoadProject.dataToLoad !== newProps.saveLoadProject.dataToLoad ||
      this.props.saveLoadProject.pk !== newProps.saveLoadProject.pk ||
      this.props.router.location.pathname !== newProps.router.location.pathname ||
      this.props.productDisabledInfo !== newProps.productDisabledInfo ||
      this.state.isProjectLoading !== newState.isProjectLoading ||
      newProps.saveLoadProject.isSaveProjectPending && isSaveProjectPending !== newProps.saveLoadProject.isSaveProjectPending;
  }

  componentDidMount() {
    const { dispatch, match: { params: { projectId, productId } }, projectConfiguration: {projectVersion} } = this.props;
    this.init();
    if (!projectId) {
      this.loadDefaultProjectConfiguration();
    } else {
      dispatch(SET_PROJECT_ID(projectId));
    }

    if ((this._bingMapsEditorEnabled() || this._classicDesignerEnabled()) && (!projectId || projectId.startsWith('IP')) && !panelsEditorEnabled()) {
      getBingZipCode();
    }

    if (this._googleMapsEditorEnabled() && (!projectId || projectId.startsWith('IP')) && !panelsEditorEnabled()) {
      getGoogleZipCode();
    }

    if (products[productId] === 9 || products[productId] === products.sm_tilt_pr || (products[productId] == 34 && adjustableTiltSystem(projectVersion))) {
      this.setState(prevState => ({ tryRedirectToNormalSM: false }));
    }

    dispatch(SET_PRODUCT_TITLE(getProductTitle(products[productId], false)));
  }


  UNSAFE_componentWillReceiveProps(nextProps: projectDesignProps) {
    const { saveLoadProject: { isLoading }, match: { params: { projectId, productId } } } = this.props;
    const { isProjectLoading } = this.state;
    if (this.props.router.location.pathname !== nextProps.router.location.pathname && !isProjectLoading && !isLoading && (!projectId || projectId.startsWith('IP'))) {
      this.init(nextProps);
    }

    if ((userWentBackToDesignLtOrHd(this.props, nextProps) && isSMFamily(products[productId])) || isSMTiltPR(products[productId]) || isNxtTilt(productId)) {
      this.setState({ tryRedirectToNormalSM: false });
    }
  }

  componentDidUpdate(prevProps) {
    const { dispatch,  roofsSelector: { mapType }, saveLoadProject: { dataToLoad, isSaveProjectPending }, match: { params: { projectId, productId } }, projectConfiguration: {projectEnvConfig: {attachment_type}} } = this.props;
    if ((isRM10(products[productId]) || isRM10Evolution(products[productId]) || isRMGridFlex(products[productId]) || isRmGridflex10(productId)) && attachment_type === AttachmentType.UNIRAC_FLASHLOC_RM) {
      dispatch(SET_FIELD_WARNING(attachmentTypeApiField, 'Note: Max roof slope for Flasloc RM is 5 degrees.'));
    }
    if (isSaveProjectPending) {
      if (isSaveProjectPending !== prevProps.saveLoadProject.isSaveProjectPending) {
        dispatch(SET_LOADER_MSG("SAVING PROJECT.."));
      }
    }

    if (Object.keys(dataToLoad).length && projectId && !this.props.saveLoadProject.isLoading) {
      if (this.props.app.isProjectError) {
        let newDataToLoad = dataToLoad
        let newLoadProjectData = {
          project_configuration: JSON.parse(newDataToLoad?.project_configuration),
          roofs: JSON.parse(newDataToLoad.roofs),
          version: newDataToLoad.version
        }
        newLoadProjectData.project_configuration.pk = dataToLoad.project_id
        loadProject(newLoadProjectData);
      } else if (dataToLoad && dataToLoad?.project_configuration.imported_project) {
        if (dataToLoad.project_configuration['railsProductId']===11){
          dataToLoad.project_configuration['productId'] = 11;
          dataToLoad.project_configuration['product'] = 11;  
        }else{
          dataToLoad.project_configuration['productId'] = products[productId];
          dataToLoad.project_configuration['product'] = products[productId]; 
        }
        dataToLoad.project_configuration['imported_project_id'] = dataToLoad.imported_project_id;
        console.log(dataToLoad);
        if (dataToLoad.roofs) {

          let projectConfigurationData = getDefaultProjectConfiguration(productId);

          dataToLoad.project_configuration = Object.assign({}, projectConfigurationData, dataToLoad.project_configuration);

          loadProject(dataToLoad);

        } else {
          const map_type = dataToLoad.project_configuration['map_type'] || mapType;
          dataToLoad.project_configuration['map_type'] = map_type;

          startNewProject(products[productId], dataToLoad.project_configuration);
        }

      } else {
        if (projectId.includes('IP-')) {
          dispatch(SET_PROJECT_ID(dataToLoad.project_configuration.pk));
          AppHistory.push(`/project/design/${productId}/${dataToLoad.project_configuration.pk}`);
        }
        loadProject(dataToLoad);

        let productOnLoad = dataToLoad?.project_configuration?.product;

        if (!(check_sm_url_conversion_criteria(productId, productOnLoad))
          && getProductName(productOnLoad) != productId) {
          dispatch(SET_PRODUCT_ID(productOnLoad));
          AppHistory.push('/projectNotFound');
        }
      }
    }



    if (!this.props.saveLoadProject.isLoading &&
      this.props.drawingManager.roofs &&
      Object.keys(dataToLoad).length === 0) {
      this.setState(prevState => ({ isProjectLoading: false }));
    }

    this.redirectBom();
  }

  componentWillUnmount() {
    clearRoofsSelectorData();
    clearAutoSaveTimeout();
  }

  init(nextProps?: projectDesignProps) {
    const { dispatch, match: { params: { productId, projectId } }, roofsSelector: { mapType }, saveLoadProject: { isLoading }, projectConfiguration: { projectVersion } } = nextProps || this.props;
    const isProjectLoading = projectId ? true : false;

    setProductSettings(products[productId], mapType, isProjectLoading, projectVersion);

    dispatch(SET_PRODUCT_TITLE(getProductTitle(products[productId])));

    if (projectId && !isLoading && !this.state.isProjectLoading) {

      if (this.state.canvasInited) {
        return;
      }

      this.setState(prevState => ({ isProjectLoading: true }));
      loadProjectData(productId, projectId);
    } else {
      loadDefaultModule(productId);
    }


    this.setState(state => ({ ...state, canvasInited: true }));
  }

  _googleMapsEditorEnabled() {
    const { isProjectLoading } = this.state;
    const { saveLoadProject: { isLoading }, roofsSelector: { mapType }, match: { params: { projectId } } } = this.props;
    return mapType === 'google' && ((projectId && projectId.startsWith('IP')) || !isLoading && !isProjectLoading);
  }

  _bingMapsEditorEnabled() {
    const { isProjectLoading } = this.state;
    const { saveLoadProject: { isLoading }, roofsSelector: { mapType }, match: { params: { projectId, productId } } } = this.props;
    return (mapType === 'bing' || mapType === 'image') && ((projectId && projectId.startsWith('IP')) || (!isLoading && !isProjectLoading));
  }

  _classicDesignerEnabled() {
    const { isProjectLoading } = this.state;
    const { saveLoadProject: { isLoading }, roofsSelector: { mapType }, match: { params: { projectId, productId } } } = this.props;
    return (mapType === 'white') && ((projectId && projectId.startsWith('IP')) || !isLoading && !isProjectLoading);
  }

  @autobind
  _onRedirectToSM() {
    this.setState(prevState => ({ tryRedirectToNormalSM: true }));
  }

  loadDefaultProjectConfiguration(productID?: string) {
    const { dispatch, match: { params: { productId, projectId } } } = this.props;
    if (!projectId) {
      const product = productID || products[productId];
      const projectConfigurationData = getDefaultProjectConfiguration(product);
      if ((isRM10(product) || isRM10Evolution(product) || isRMGridFlex(product) || isRmGridflex10(product)) && projectConfigurationData[attachmentTypeApiField] === AttachmentType.UNIRAC_FLASHLOC_RM) {
        dispatch(SET_FIELD_WARNING(attachmentTypeApiField, 'Note: Max roof slope for Flasloc RM is 5 degrees.'));
      }
      dispatch(LOAD_PROJECT_CONFIGURATION(projectConfigurationData, product));
    }
  }

  redirectBom = () => {
    const { match: { params: { productId } }, saveLoadProject: { pk }, projectConfiguration: { railsProductId } } = this.props;

    if (pk) {
      let productName = railsProductId ? getProductName(railsProductId) : productId;
      if ((products[productId] === 99 || products[productId] === 34) && railsProductId === 9) { //|| (products[productId] === 34 && railsProductId === 34)) {
        productName = getProductName(99);
      }
      
      AppHistory.push(location.pathname);
      AppHistory.push(`/project/bom/${productName}/${pk}`);
    }
  }

  renderPage() {
    const { match: { params: { productId, projectId } }, saveLoadProject: { isLoading, isSaveProjectPending }, } = this.props;

    if (isLoading || isSaveProjectPending) {
      return (<Loader />);
    }

    if (panelsEditorEnabled()) {
      return (
        <div>
          <PanelsEditor productId={productId} />
        </div>
      );
    }

    if (this._googleMapsEditorEnabled()) {
      return <GoogleMapsRoofsSelector className="roofs-selector" productId={productId} projectId={projectId} />;
    }

    if (this._bingMapsEditorEnabled()) {
      return <BingMapsRoofsSelector className="roofs-selector" productId={productId} projectId={projectId} />;
    }

    if (this._classicDesignerEnabled()) {
      return <BingMapsRoofsSelector className="roofs-selector" productId={productId} projectId={projectId} />;
    }

    return null;
  }

  renderFooter = () => {
    const { match: { params: { productId, projectId } }, roofsSelector: { mapTypeSelected } } = this.props;

    if (panelsEditorEnabled()) {
      return (
        <span style={{ display: 'flex' }}>
          <BackButton
            onClick={() => {
              deactivePanelsEditor();
            }}
          />
          <ContinueButton
            onClick={savePanelEditorAndSaveProject}
          />
          <CursorPosition />
        </span>);
    }

    if (mapTypeSelected) {
      return (
        <span style={{ display: 'flex' }}>
          <ContinueButton
            onClick={() => {
              saveProject(productId, projectId, false, true);
            }}
          />
        </span>
      );
    }

    return null;
  }


  render() {
    const { match: { params: { productId, projectId } }, drawingManager: { roofs }, saveLoadProject: { pk }, projectConfiguration: {projectVersion, railsProductId, projectEnvConfig } } = this.props;
    const { tryRedirectToNormalSM } = this.state;

    if (pk || Object.keys(projectEnvConfig).length === 0) {
      return null;

    }

    if (!tryRedirectToNormalSM &&
      projectId &&
      (isSMFamily(products[productId]) &&
        ((isSMLT(products[productId]) ||
          isSMHD(products[productId]) || isSMAscenderFlush(products[productId]))))
      || (isSMTiltPR(products[productId]) && isSMTiltPRLT(products[productId])) ||
      (isNxtTilt(productId))) {
      return (
        <RedirectFromSmLtAndSmHd
          roofs={roofs}
          spansProductId={railsProductId}
          productId={productId}
          projectId={projectId}
          onRedirect={this._onRedirectToSM}
        />
      );
    }

    return (
      <>
        <div className="project_design_page">
          <Header navigation={true} Infobar={<ProjectInfoHeaderMiddleBar projectId={projectId} />} projectId={projectId} productId={productId} />
          {this.renderPage()}
          <Footer props={({ productId, projectId })} ContainerFooter={this.renderFooter} RightSideFooter={() => <LastEditorSave />} />
        </div>
      </>
    );
  }
}

function mapPropTypes(state: appState) {
  return {
    background: state.background,
    moduleSelector: state.moduleSelector,
    drawingManager: state.drawingManager,
    projectConfiguration: state.projectConfiguration,
    router: state.router,
    roofsSelector: state.roofsSelector,
    saveLoadProject: state.saveLoadProject,
    userPreferences: state.userPreferences,
    cords: state.background.cords,
    productDisabledInfo: state.productDisabledInfo,
    user: state.user,
    app: state.app,
    settings: state.settings,
  };
}

export default connect(mapPropTypes)(ProjectDesign);

export function check_sm_url_conversion_criteria(productId, productOnLoad) {
  return (isSMFlushOrTiltOrTiltPR(productOnLoad) && isSMFlushOrTiltOrTiltPR(products[productId]))
}
import React from 'react';
import ReactTooltip from 'react-tooltip';
import ConfirmationSelectField from '__common/components/ConfirmationSelectField';
import { FormattedMessage } from 'react-intl';
import { SelectField } from 'react-md';

const FIELD_TITLE = 'Are you sure you want to change the module specification?';
const FIELD_CONTENT = 'All array layouts will be lost.';

type Props = {
  field: selectBlock,
  index: number,
  value: string,
  updateProjectOption: (field: string, value: any) => void,
  error?: string,
  warn?: string,
};

export default class Select extends React.PureComponent<Props> {
  tooltipButton = () => {
    if(this.props.field.tooltip) {
      return (
        <a 
          data-for={`projectConfigurationTooltip-${this.props.field.apiField}`} 
          data-tip={this.props.field.tooltip()} 
          data-html={true} 
          className="disabled"
        >
          <img src={require('assets/media/icons/questionmark.png')} />
        </a>
      );
    }

    return null;
  }

  tooltip = () => {
    const { field: { apiField, tooltip } } = this.props;

    let position;

    if (apiField === 'roof_type') {
      position = 'bottom';
    } else {
      position = 'right';
    }

    return tooltip && <ReactTooltip className="project-configuration-tooltip" effect="solid" place={position} type="info" id={`projectConfigurationTooltip-${apiField}`} />;
  }

  renderSelectField = () => {
    const { field, value, updateProjectOption } = this.props;

    return (
      <SelectField
        id={field.label}
        menuItems={field.data}
        value={value}
        fullWidth={true}
        onChange={updateProjectOption.bind(this, field.apiField)}
        itemLabel="name"
        itemValue="value"
      />
    );

  }

  renderConfirmSelect = () => {
    const { field, value, updateProjectOption } = this.props;

    return (
      <ConfirmationSelectField
        value={value || null}
        items={field.data}
        onConfirm={(value) => {
          field.onConfirm(value);
          updateProjectOption(field.apiField, value);
        }}
        title={field?.title?.length? field.title: FIELD_TITLE}
        content={field?.content?.length? field.content: FIELD_CONTENT}
        shouldConfirm={field.shouldConfirm()}
        shouldConfirmCb={field.shouldConfirmCb}
        selectFieldProps={{
          lineDirection: 'center',
          type: 'text',
          fullWidth: true,
          itemLabel: 'name',
          itemValue: 'value',
          id: field.label,
        }}
      />
    );
  }

  renderField = (shouldConfirm: boolean) => {
    if (shouldConfirm) {
      return this.renderConfirmSelect();
    }

    return this.renderSelectField();
  }

  render() {
    const { field, index, error, warn } = this.props;

    return (
      <div className="field" key={`select-${index}`}>
        {field.label && (
          <div
            className={`input-label ${field.missing || error ? 'input-warning' : ''}`}
          >
            <FormattedMessage id={field.label} />: {this.tooltipButton()}
          </div>
        )}
        {this.renderField(field.shouldConfirm !== undefined)}
        {this.tooltip()}
        {field.note && field.note.shouldShow && field.note.message && <div className={'note'} dangerouslySetInnerHTML={{ __html: field.note.message}}></div>}
        {error && <div className={'input-label input-warning'}>{error}</div>}
        {warn && <div className={'input-label input-soft-warning'}>{warn}</div>}
      </div>
    );
  }
}

import * as React from 'react';
import DrawRoofBoundsButton from '__editor/components/roofsSelector/components/roofsSelectorControlPanel/components/drawRoofBoundsButton';
import LocationInput from '__editor/components/roofsSelector/components/roofsSelectorControlPanel/components/LocationInput';
import MoveMapButton from '__editor/components/roofsSelector/components/roofsSelectorControlPanel/components/moveMapButton';
import RoofsSelectorMapTypeChanger from 'projectDesign/components/RoofsSelectorMapTypeChanger';
import ZoomingButtons from '__editor/components/roofsSelector/components/roofsSelectorControlPanel/components/zoomingButtons';
import { connect } from 'react-redux';
import Compass from '__editor/panelsEditor/components/panelsEditorControls/components/compass';
import { isBlankMap } from '__common/constants/map';
import { products } from '__common/constants/products';
import { Button } from 'react-md';
import MeasurementToolButton from './components/measurementToolButton';
import {openFaq} from 'app/components/FaqComponent/FaqButton'

type RoofsSelectorControlPanelState = {
  advanceRoofSelectorEnabled: boolean;
  dispatch: Function,
  mapType: string,
  isRoofSelected: boolean,
  drawingManager: drawingManagerState,
  editEnabled: boolean,
  measurementToolEnabled: boolean,
  productId: string,
  classicDesigner?: boolean,
  relocated?: boolean,
};

class RoofsSelectorControlPanel extends React.Component<RoofsSelectorControlPanelState, {}> {
  renderLocationInput() {
    const { mapType } = this.props;
    return <LocationInput mapType={mapType} />;
  }

  renderSelectingTools() {
    const { advanceRoofSelectorEnabled, dispatch, mapType, editEnabled, measurementToolEnabled, productId } = this.props;
    return (
      <div className="selecting-tools buttons-containers">
        <DrawRoofBoundsButton mapType={mapType} dispatch={dispatch} advanceRoofSelectorEnabled={advanceRoofSelectorEnabled} productId={products[productId]} />
        {/* <EditButton /> */}
        <MeasurementToolButton mapType={mapType} dispatch={dispatch} measurementToolEnabled={measurementToolEnabled} productId={products[productId]}/>
        <MoveMapButton mapType={mapType} dispatch={dispatch} editEnabled={editEnabled} advanceRoofSelectorEnabled={advanceRoofSelectorEnabled} measurementToolEnabled={measurementToolEnabled}/>
      </div>
    );
  }

  renderFaqButton() {
    const { mapType } = this.props
    return (
      <div className="selecting-tools buttons-containers">
        <section className='faq-icon'>
          <Button mapType={mapType} className='faq-icon' icon={true} primary={true} onClick={openFaq} tooltipLabel="Click for the help section" tooltipPosition="left">
          <img src={require('assets/media/icons/faq.png')} />
          </Button>
        </section>
      </div>
    );
  }

  renderChangeMapType() {
    const { dispatch, mapType, drawingManager: { roofs } } = this.props;
    
    return (
      <div id="change-map-type-tool" className="buttons-containers classic-designer-change-map-type-tool">
        <RoofsSelectorMapTypeChanger mapType={mapType} dispatch={dispatch} roofsOnMap={roofs} />
      </div>
    );
  }
  
  renderCompass() {
    return <Compass />;
  }

  renderZoomingTools() {
    const { mapType, advanceRoofSelectorEnabled, relocated} = this.props;
    return (<ZoomingButtons mapType={mapType} advanceRoofSelectorEnabled={advanceRoofSelectorEnabled} relocated={relocated}/>);
  }

  renderRoofSelector() {
    const { } = this.props;
    return (
      <div id="roofs-selector-control-panel">
        {this.renderLocationInput()}
        {this.renderChangeMapType()}
        {this.renderSelectingTools()}
        {this.renderFaqButton()}
        {this.renderCompass()}
        {this.renderZoomingTools()}
      </div>
    );
  }

  renderClassicDesigner() {
    return (
      <div id="roofs-selector-control-panel">
        {this.renderCompass()}
        {this.renderSelectingTools()}
        {this.renderFaqButton()}
      </div>
    );
  }

  render() {
    const { mapType } = this.props;

    if (isBlankMap(mapType)) {
      return this.renderClassicDesigner();
    }
    return this.renderRoofSelector();  
  }
}

function mapStateToProps(state: appState) {
  return {
    advanceRoofSelectorEnabled: state.advanceRoofSelecting.enabled,
    mapType: state.roofsSelector.mapType,
    isRoofSelected: state.drawingManager.selectedIds.length > 0,
    editEnabled: state.drawingManager.editEnabled,
    measurementToolEnabled: state.measurementTool.enabled,
    drawingManager: state.drawingManager,
    relocated: state.settings.mapDisplacedFromRoof,
  };
}

export default connect(mapStateToProps)(RoofsSelectorControlPanel);

export const apiField = 'wind_speed';
import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import { note as windSpeedNote } from 'projectDesign/components/projectConfiguration/fieldsTooltips/windSpeedVerifyNote';

interface WindSpeedField {
  type: string;
  apiField: string;
  label: string;
  note: any;
  min: number;
  max: number;
  verifyLinkNote?: boolean;
  missing?: any;
}

function makeConfig(max: number = 180, min: number = 85, label:string= 'windSpeed', verifyLinkNote:boolean= true): WindSpeedField {
  return {
    type: 'Number',
    label,
    apiField,
    note: {
      message: windSpeedNote(),
      shouldShow: verifyLinkNote,
    },
    min,
    max,
    missing: undefined,
  };
}

const config = makeConfig();

export default config;

export const asce705WindSpeedForSfmAndSm = makeConfig(170);

export const asce710WindSpeedForSfmAndSm = makeConfig(190, 95);

export const asce716WindSpeedForSfmAndSm = makeConfig(190);

export const asce705WindSpeedForSmTilt = makeConfig(150);

export const asce716WindSpeedMetric = makeConfig(266, 136, 'windSpeed.kph');

export const asce7107And716WindSpeedForGft = makeConfig(165);

export const asce7107And716WindSpeedForUla = makeConfig(165);

export const getWindSpeedField = (inputUnit: number) =>  isMetricUnit(inputUnit) ? asce716WindSpeedMetric : config;
import * as React from 'react';
import CombinedProjectsBackButton from 'combineProjects/components/CombinedProjectsBackButton';
import CombinedProjectsHeader from 'combineProjects/components/CombinedProjectsHeader';
import CombinedProjectsPartsTable from 'combineProjects/components/CombinedProjectsPartsTable';
import CombinedProjectsTable from 'combineProjects/components/CombinedProjectsTable';
import Footer from 'app/components/FooterComponent';
import Header from 'app/components/HeaderComponent';
import { connect } from 'react-redux';
import { POPULATE_COMBINED_PROJECTS, GET_COMBINED_PROJECT } from 'actions';

type Props = {
  match: any,
  dispatch: Function,
  yourProjects: yourProjectsState,
};

class CombineProjects extends React.Component<Props, {}> {
  UNSAFE_componentWillMount() {
    const { dispatch, match: { params: { combinedProjectId } } } = this.props;
    dispatch(GET_COMBINED_PROJECT(combinedProjectId));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(POPULATE_COMBINED_PROJECTS({ combined_project: '', parts_list: [], projects: {} }));
  }

  renderPage = () => {
    const { yourProjects: { combinedPartsList, combinedProjects }, match: { params: { combinedProjectId } } } = this.props;
    return (
      <div className="app-content">
        <Header />
        <div className="combined-projects">
          <CombinedProjectsHeader combinedId={combinedProjectId} combinedPartsList={combinedPartsList} />
          <CombinedProjectsTable data={combinedProjects} />
          <CombinedProjectsPartsTable data={combinedPartsList} />
        </div>
        <Footer ContainerFooter={CombinedProjectsBackButton} />
      </div>
    );
  }

  render() {
    const { yourProjects: { combinedProjectId } } = this.props;
    const projectId = this.props.match.params.combinedProjectId;

    if (combinedProjectId === projectId) {
      return this.renderPage();
    }

    return null;
  }
}

function mapStateToProps(appState: appState) {
  return {
    yourProjects: appState.yourProjects,
  };
}

export default connect(mapStateToProps)(CombineProjects);

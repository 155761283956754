// part mutating here
// TO REFACTOR !!!! 
export function restorePartQty(state: bomState, part: bomPart) {
  const partNumber = part.number;
  const { extraParameters } = state;

  if (extraParameters && extraParameters.quantities && extraParameters.quantities[getPartNotBasedOnColor(extraParameters.quantities, partNumber, false)]) {
    const number = getPartNotBasedOnColor(extraParameters.quantities, partNumber, false);
    const { qty, suggestedQty } = extraParameters.quantities[number];

    if (suggestedQty === part.suggested_quantity) {
      part.quantity = qty;
    }

    // delete assigned qty from storage
    delete extraParameters.quantities[partNumber];

    return;
  }

  const flattenedPartList = state.partsList.reduce((acc, part) => { acc[part.number] = part.quantity; return acc; }, {});

  const partInPartList = state.partsList.find((part) => part.number === getPartNotBasedOnColor(flattenedPartList, partNumber, true));

  if (
    (part.type === 'Rail' || part.type === 'Splice') && 
    extraParameters && 
    extraParameters.quantities && 
    typeof extraParameters.quantities === 'object' && 
    !extraParameters.quantities[partNumber] && 
    Object.keys(extraParameters.quantities).length &&
    partInPartList
  ) {
    part.quantity = partInPartList.quantity;
  }
}

function getPartNotBasedOnColor(quantities: bom_quantities, partNumber, isRail: boolean) {
  const flattenedQuantities = Object.keys(quantities);
  const flattenedIndex = flattenedQuantities.findIndex(number => number.includes(partNumber.slice(isRail ? 3 : 0, -1)));
  return flattenedQuantities[flattenedIndex];
}


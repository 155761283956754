export const VERSION_MAP = {
    rm10_rm10evo_ecofoot2plus_module_validation_changes: '0.0.2',
    rm5_rmdt_module_validation_changes: '0.0.3',
    // gft_bolt_capacity_calc_change: '0.0.4', Version was removed because it was over 1 year old
    roof_zone_and_classfication_changes: '0.0.5',
    new_to_old_roof_zone_mapping_change: '0.0.6', // unused version 0.0.6
    // non_zero_blocks_rm_notations_change: '0.0.7', Version was removed because it was over 1 year old
    sm_heavy_rail_option: '0.0.8',
    sm_hd_ula_rail_option: '0.0.8',
    rm10evo_row_spacing_change: '0.0.9',
    // small_array_factor_changes: '0.0.10',   Version was removed because it was over 1 year old
    // asd_factor_correction_changes: '0.0.10',    Version was removed because it was over 1 year old
    // eff_area_formula_changes: '0.0.11', Version was removed because it was over 1 year old
    // mri_factor_changes: '0.0.11',   Version was removed because it was over 1 year old
    mid_and_end_clamps_changes_in_gft_ula: '0.0.12',
    remove_gft_166_rail :'0.0.14',
    building_height_factor_and_drag_gcp_changes: '0.0.15',
    velocity_pressure_exposure_coefficient_changes: '0.0.15',
    remove_ula_166_rail : '0.0.16',
    rm5_central_support_changes: '0.0.17',
    module_thickness_changes: '0.0.19',
    panels_rotation_factor_changes_rm_10_evo: '0.0.20',
    rm10_rm10evo_small_array_factor_changes: '0.0.21', // For RM10 & RM10 EVO
    shortest_building_length_changes: '0.0.23', // For RM10, RM10 EVO, RM5 & RMDT
    tension_transverse_clamp_formula_for_mix_match: '0.0.24',
    deadload_factor_limit_changes: '0.0.25',
    soil_class_D_to_D_Default_changes: '0.0.26',        // For RM10, RM10 EVO
    rm5_revamp: '0.0.27',    
    rm10_evo_ballast_based_on_bay_potential_and_rm10_evo_rm5_corrected_attachment_capacity_distribution: '0.0.28',
    rm10_evo_module_clamp_remove: '0.0.28',
    rm10_rm10evo_ecofoot2plus_minimum_setback_validation_and_setback_factor_changes: '0.0.29', // calculation and validation of setback for EF2+, RM10 and RM10 Evo
    module_length_width_validation: '0.0.29',   // width can not be greater than length validation changes
    rmdt_small_array_factor_module_class_changes: '0.0.30',     // TODO CAN BE deleted during version deletion
    solar_eq_factor_for_mod_len_greater_than_84p42: '0.0.31',  // TODO CAN BE deleted during version deletion
    rm5_updated_deflector_selection_and_its_quantity_based_on_module_type: '0.0.32',  // TODO cannot be deleted during version control deletion, as previous projects should retain deflector type condition
    removed_25_yrs_mri_option_in_ef2: '0.0.32', // previous versions sh'ld have mri=25
    sm_tilt_same_span_for_all_zones: '0.0.33',  // TODO can be deleted
    exculde_no_deflector_option_under_wind_deflectors: '0.0.34',
    changing_soil_class_to_d_default_for_building_code_asce_716: '0.0.35',
    remove_user_has_exclusive_access_in_sfm: '0.0.36',
    nxt_mgmt_clip_as_default_accessory_for_nxt: '0.0.37',
    rm5_setback_changes: '0.0.38',
    rm_gridflex_5_setback_changes: '0.0.38',
    rm10_rm10evo_rm5_attachment_capacity_changes: '0.0.38',
    nxt_new_splice_sh_butyl_attachment: '0.0.39',  // TODO cannot be deleted as previous projects use old part
    gft_cantilever_ratio_check_and_ballast_block_engg_corrections: '0.0.40',  // TODO can be deleted
    ula_krinner_new_part_change: '0.0.42',
    rm10_rm10_evo_seismic_attachments_with_goal_seek_approach: '0.0.43',  // TODO can be deleted
    module_length_restrict_to_90inch_for_ef2Plus: '0.0.44',
    nxt_84_inch_rail: '0.0.45',
    mple_combo_clamp_rail_clamp_part_changes: '0.0.45',
    sm_flush_nxt_point_load_and_snow_factor_corrections: '0.0.46', // TODO can't be deleted
    gft_ula_seismic_site_class: '0.0.47',
    lat_lng_to_pixel_conversion_changes : '0.0.48',
    gf10_attachment_part_replacement: '0.0.49', // TODO - can be deleted
    gf5_added_small_array_check_for_dead_load_factor: '0.0.50',  //  can't be deleted,
    module_length_restrict_to_97inch_for_gft: '0.0.51',
    adjustable_tilt_system : '0.0.52',
    update_lateral_bearing_capacity: '0.0.52',
    min_setback_validation_based_on_seismic_design_category: '0.0.52',
    gft_removal_of_old_top_chord_option_and_modified_snow_load_calcs: '0.0.53',
    ula_modified_snow_load_calculations: '0.0.53',
    stacking_tray_for_gf5: '0.0.53',
    nxt_part_changes: '0.0.53',
    remove_upslope_rail_direction_for_flashloc_duo: '0.0.54',
    add_185_inch_rail_sm_standard_and_ascender_rail_update: '0.0.55',
    large_modules_check_sm_tilt_pr: '0.0.55',
}
import React from 'react';
import ReactTooltip from 'react-tooltip';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';
import { getTranslation } from '_translations/translations';
import SelectionControlGroup from 'react-md/lib/SelectionControls/SelectionControlGroup';
import { addActiveCheckbox } from '../../utils/updateProjectOption';

type Props = {
  updateProjectOption: (field: string, value: any) => void,
  value: string,
  fieldBlock: radioBlock,
};

export default class RadioField extends React.PureComponent<Props> {
  tooltipButton = () => {
    return this.props.fieldBlock.tooltip ? <a data-for="projectConfigurationTooltip" data-tip="" className="disabled"><img src={require('assets/media/icons/questionmark.png')} /></a> : null;
  }

  render() {
    const { fieldBlock, value, updateProjectOption } = this.props;

    let translatedFields = [...fieldBlock.fields];
    translatedFields = translatedFields.map(field => {
      if (field.label.includes('.')) {
        field.label = getTranslation(field.label);
      }
      if (!field.checkedRadioIcon) {
        field.checkedRadioIcon = (
          <SVG src={require(`assets/media/icons/${field.icon}`)} />
        );
      }

      if (!field.uncheckedRadioIcon) {
        field.uncheckedRadioIcon = (
          <SVG src={require(`assets/media/icons/${field.icon}`)} />
        );
      }

      if (field.checkedRadioIcon) {
        delete field.icon_active;
      }

      if (field.uncheckedRadioIcon) {
        delete field.icon_inactive;
      }

      return field;
    });

    addActiveCheckbox(fieldBlock.apiField, value);
    
    return (
      <div id={`${fieldBlock.id}`} key={fieldBlock.label}>
        <div
          className={`input-label ${fieldBlock.missing ? 'input-warning' : ''}`}
        >
          <FormattedMessage id={fieldBlock.label} />: {this.tooltipButton()}
        </div>
        <SelectionControlGroup
          name={fieldBlock.label}
          type="radio"
          id={`${fieldBlock.label}`}
          value={value}
          controls={translatedFields}
          onChange={updateProjectOption.bind(this, fieldBlock.apiField)}
        />
        {fieldBlock.tooltip && <ReactTooltip className=" project-configuration-tooltip" effect="solid" place="right" type="info" id="projectConfigurationTooltip" getContent={() => fieldBlock.tooltip()} />}
      </div>
    );
  }
}

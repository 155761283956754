import appConfig from 'local_config';
import * as Sentry from '@sentry/browser';
import { LogLevel } from '@sentry/types';

const userContext = {
  email: 'Guest',
  username: 'Guest',
};

let S;

export function initRaven() {
  if (appConfig.disableRaven) {
    return null;
  }

  Sentry.init({
    release: appConfig.release,
    logLevel: LogLevel.Error,
    attachStacktrace: true,
    dsn: appConfig.sentryLink,
  });

  console.log('appConfig', appConfig);

  Sentry.configureScope(scope => {
    scope.setTag('username', userContext.username);
    scope.setTag('email', userContext.email);
  });

  S = Sentry;
  return S;
}

export function getRaven() {
  if (S) {
    return S;
  }

  return initRaven();
}

export function setRavenEmail(username: string, email: string) {
  const Sentry = S;
  
  if (Sentry) {
    Sentry.configureScope(scope => {
      scope.setTag('username', username);
      scope.setTag('email', email);
    });
  
  }
}

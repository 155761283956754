import { isASCE716or722 } from '__common/constants/buildingCodes';
import { isRMFamily, isGroundProduct, isRMIFIProduct, isRMAndEcofootFamily } from '__common/constants/products';
import { isBlankMap } from '__common/constants/map';
import { hexStringToNumber } from '__common/calculations/color';
import { shouldUseVirtualRoofEdges } from '__editor/panelsEditor/panelsEditorHelper';

// I create numbers from string for convenience.
// There are IDE plugins that display color in IDE when the color is in a string '#<value>'
const FORBIDDEN_COLOR = hexStringToNumber('#000000');
const CURSOR_SNAPPING_COLOR = hexStringToNumber('#538151');

export const NEAR_ONE_EDGE_COLOR = hexStringToNumber('#FFA500');
export const NEAR_OBSTRUCTION_COLOR = hexStringToNumber('#fff42d');
export const ON_THE_CORNER_COLOR = hexStringToNumber('#FF4500');
export const MIDDLE_ROOF_COLOR = hexStringToNumber('#5999D9');
export const MIDDLE_ROOF_PRIM_COLOR = hexStringToNumber('#b4c9de');

export const ASCE_7_16_SALMON = hexStringToNumber('#eba57d'); // South Leading Edge
export const ASCE_7_16_GREEN = hexStringToNumber('#00fe5c'); // North Leading Edge
export const ASCE_7_16_YELLOW = hexStringToNumber('#f8f5b7'); // Field
export const ASCE_7_16_BROWN = hexStringToNumber('#6b554c'); // South Corner
export const ASCE_7_16_VIOLET = hexStringToNumber('#9893c2'); // North Corner
export const ASCE_7_16_ORANGE = hexStringToNumber('#fa633f'); // East & West Edges
export const ASCE_7_16_BEIGE = hexStringToNumber('#fcfbd9');

const ASCE_7_16_BLACK = FORBIDDEN_COLOR;
export const ASCE_7_16_SECOND_EAVE = MIDDLE_ROOF_COLOR;
export const ASCE_7_16_SECOND_RIDGE = NEAR_ONE_EDGE_COLOR;

export function getPanelColor(roofZone: roofZoneNumber, nearObstruction: boolean, buildingCode: number, productId: number, mapType: string, roofZonesEnabled: boolean, projectVersion: string) {

  if (nearObstruction) {
    return NEAR_OBSTRUCTION_COLOR;
  }
  const isASCE716or722Selected = isASCE716or722(buildingCode);

  // we check if it is blank and not use virtual edges, because virtual edges are displayed
  // on blank design, but we want to display regular colors as on a map design.
  if (
    (!isASCE716or722Selected || isRMIFIProduct(productId) || (isBlankMap(mapType) && !shouldUseVirtualRoofEdges(mapType, productId, projectVersion))) &&
    (roofZonesEnabled || [0, 3, 4, 5].includes(roofZone)) && (!(isRMAndEcofootFamily(productId)))) {
    switch (roofZone) {
      case 0:
        return MIDDLE_ROOF_COLOR; // blue
      case 1:
        return NEAR_ONE_EDGE_COLOR; // orange
      case 2:
        return ON_THE_CORNER_COLOR; // red
      case 3:
        return FORBIDDEN_COLOR; // black
      case 4:
        return CURSOR_SNAPPING_COLOR; // green
      case 9:
        return NEAR_OBSTRUCTION_COLOR;
      default:
        return MIDDLE_ROOF_COLOR; // blue
    }
  }
  
  if (isRMAndEcofootFamily(productId) ) {
    switch (roofZone) {
      case 1:
        return ASCE_7_16_GREEN;
      case 2:
        return ASCE_7_16_YELLOW;
      case 3:
        return ASCE_7_16_BLACK;
      case 4:
        return ASCE_7_16_BROWN;
      case 5:
        return ASCE_7_16_VIOLET;
      case 6:
        return ASCE_7_16_ORANGE;
      case 7:
        return ASCE_7_16_SALMON;
      case 8:
        return NEAR_OBSTRUCTION_COLOR;
      case 9:
        return CURSOR_SNAPPING_COLOR;
    }
  }

  if (isASCE716or722Selected && isGroundProduct(productId)) {
    switch (roofZone) {
      case 0:
        return MIDDLE_ROOF_COLOR;
      case 3:
        return FORBIDDEN_COLOR;
      case 4:
        return CURSOR_SNAPPING_COLOR;
    }
  }


  if (isASCE716or722Selected && !isRMFamily(productId)) {
    switch (roofZone) {
      case 1:
        return MIDDLE_ROOF_COLOR;
      case 2:
        return NEAR_ONE_EDGE_COLOR;
      case 3:
        return ON_THE_CORNER_COLOR;
      case 4:
        return MIDDLE_ROOF_PRIM_COLOR; // 1' zone for flat roof
      case 5:
        return ASCE_7_16_BEIGE; // 2r zone for hip roof roof pitch 27°-45°
      case 6:
        return FORBIDDEN_COLOR;
      case 7:
        return ON_THE_CORNER_COLOR;
      case 8:
        return NEAR_OBSTRUCTION_COLOR;
      case 9:
        return CURSOR_SNAPPING_COLOR;
    }
  }

  return MIDDLE_ROOF_COLOR; // blue
}

import { OPEN_DRAWER_PAGE, SET_FLYOUT_VERIFIED } from 'actions';
import { ADDITIONAL_USER_INPUTS_PAGE, LOCATION_AND_LOADS_PAGE, MODULE_SELECTION_PAGE, RAIL_SYSTEM_PAGE } from 'projectDesign/components/projectConfiguration/utils/projectConfigurationDrawerPages';
import * as React from 'react';
import SVG from 'react-inlinesvg';
import { Checkbox } from 'react-md';
import Tooltip from '__common/components/Tooltip';
import { dispatch } from '__common/store';

type Props = {
  label?: string,
  onClick: () => void,
  page?: string
  verifiedFlyouts?: verifiedFlyouts
};

export default class SaveChangesButton extends React.Component<Props> {
  render() {
    const { label, page, verifiedFlyouts, onClick } = this.props;
    function onChangeHandler(): void {
      dispatch(SET_FLYOUT_VERIFIED(page, !verifiedFlyouts[page]?.checked));
      if(verifiedFlyouts[page]?.checked) {
        onClick();
        Object.entries(verifiedFlyouts).some(([page, {checked}]) => {
          if(!checked) {
            dispatch(OPEN_DRAWER_PAGE(page));
            return;
          }
        })
      }
    }
    const verifiableFlyouts = [MODULE_SELECTION_PAGE, LOCATION_AND_LOADS_PAGE, RAIL_SYSTEM_PAGE, ADDITIONAL_USER_INPUTS_PAGE]
    function shouldVerifyTheFlyout(page){
      return verifiableFlyouts.includes(page)
    }
    return (
      <div className='save-changes-container'>
          {
          shouldVerifyTheFlyout(page) ? 
          <div className='inputs-verified-container'>
            <Checkbox className={'save-changes-button'} label='' id={page} name={page} checked={verifiedFlyouts[page]?.checked} disabled={verifiedFlyouts[page]?.error} onChange={onChangeHandler}/>
            <div id='save-changes' className={"save-changes-button"} onClick={!verifiedFlyouts[page]?.error ? onChangeHandler : null}>
            {label || 'INPUTS VERIFIED '}
            {<Tooltip id='verify-inputs-tooltip' content='Correct input results correct design. Please ensure that all the inputs are accurate.' position='right' />}
            </div>
          </div>
          : (
            <div role="button" onClick={onClick} className="save-changes-button">
              <SVG src={require('assets/media/icons/icon_checkbox.svg')} />
              {label || 'SAVE CHANGES'}
            </div>)
          }
      </div>
    );
  }
}

import { RoofZonesActionTypes } from 'actionsConstants';

export function TOGGLE_WIND_ZONE() {
  return {
    type: RoofZonesActionTypes.TOGGLE_WIND_ZONE,
  };
}

export function TOGGLE_EXP_ZONE() {
  return {
    type: RoofZonesActionTypes.TOGGLE_EXP_ZONE,
  };
}

export function TOGGLE_RESTRICTED_ZONE() {
  return {
    type: RoofZonesActionTypes.TOGGLE_RESTRICTED_ZONE,
  };
}

export function TOGGLE_OBSTRUCTIONS_ZONE() {
  return {
    type: RoofZonesActionTypes.TOGGLE_OBSTRUCTIONS_ZONE,
  };
}

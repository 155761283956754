export const apiField = 'building_height';


export const buildingHeightTooltip = () => `
  <div class="content">
    Average height of roof relative to grade in feet.
  </div>
`;

export const buildingHeightTooltipMetric =  () => `
  <div class="content">
    Average height of roof relative to grade in meter.
  </div>
`;


interface option {
  value: string;
  name: string;
}

function makeOption(value: number): option {
  return {
    value: value.toString(),
    name: value.toString(),
  };
}

function makeConfig(options: number[] = [], type: string = 'Select', max?: number, min?: number, label: string = 'buildingHeight', tooltip: Function = buildingHeightTooltip) {
  return {
    type,
    label,
    apiField,
    tooltip,
    ...(type === 'Select' ? { data: options.map(makeOption) } : {}),
    min: min ?? 15,
    max: max?? 100,
    missing: undefined,
  };
}


export default makeConfig([15, 30]);

export const buildingHeightForSfmAndSm = makeConfig([15, 30, 60]);

export const asce716BuildingHeightForSmAndSfm = makeConfig([15, 20, 25, 30, 35, 40, 45, 50, 55, 60]);

export const rmIFIBuildingHeight = makeConfig([], 'Number');

export const rmDTbuildingHeight = makeConfig([], 'Number');

export const rm10rm10evobuildingHeight = makeConfig([], 'Number', 150)

export const asce705BuildingHeightForSmTilt = makeConfig([30])

export const adjustableTiltBuildingHeight = makeConfig([], 'Number', 60);

export const smBuildingHeight = makeConfig([], 'Number', 60);

export const SMTiltPRBuildingHeight = makeConfig([], 'Number', 60, 1);

export const buildingHeightMetric = makeConfig([], 'Number', 45.7, 4.6,  'buildingHeightMetric', buildingHeightTooltipMetric);

export const  buildingHeightSMFamilyNonAscenderFlushMetric = makeConfig([], 'Number', 45.7, 4.6,  'buildingHeightMetric', buildingHeightTooltipMetric);

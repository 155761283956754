import * as React from 'react';
import AccessoriesSelector from 'bom/components/accessoriesSelector/accessoriesSelector';
import AppHistory from '__common/modules/history';
import AttachmentsSelector from 'bom/components/attachmentsSelector/attachmentsSelector';
import TileReplacementSelector from 'bom/components/tileReplacementSelector/tileReplacementSelector';
import MetalxAttachmentSelector from 'bom/components/attachmentsSelector/components/metalXAttachmentSelector';
import autobind from 'autobind-decorator';
import BackButton from 'app/components/FooterComponent/BackButton';
import BomPrices from 'bom/components/Prices';
import BomTable from 'bom/components/bomTable/bomTable';
import Drawer from '__common/components/Drawer/Drawer';
import Footer from 'app/components/FooterComponent';
import Header from 'app/components/HeaderComponent';
import HeaderMiddleBar from 'app/components/HeaderComponent/HeaderMiddleBar';
import Loader from '__common/components/LoaderComponent';
import RailsSelector from 'bom/components/railsSelector/railsSelectorComponent';
import SaveBom from 'bom/components/saveBomButton';
import { calculatePrices } from 'bom/bomHelpers';
import { connect } from 'react-redux';
import { footerHeight, headerHeight } from '__common/constants/layout_css_dims';
import { getBomBasicPages } from './utils/bomDrawerPages';
import { getDefaultRailsList } from 'bom/components/railsSelector/utils/getDefaultRailsList';
import { getProductTitle } from '__common/constants/products_titles';
import { loadBomSelections } from 'bom/components/utils/loadBomSelections';
import { saveBom } from 'bom/utils/saveBom';
import {
  isRMFamily,
  isSFMFamily,
  isSMFamily,
  products,
  isULA,
  isMetalX,
  isEcoFoot2Plus,
  isNxtHorizon,
  isSMTiltPR,
  isAscender,
  getProductName,
  isNxtTilt,
  isSMTilt,
} from '__common/constants/products';
import {
  CLEAR_PK,
  FETCH_BOM_PART_LIST_REQUEST,
  SET_BOM_PRODUCT,
  SET_NEW_PART_QUANTITY,
  SET_PRODUCT_TITLE,
  LOAD_RAILS_LIST,
  SET_PROJECT_NAME,
  BOM_RESET,
  CLEAR_RAILS_LIST,
  SET_PRODUCT_ID,
} from 'actions';
import LoaderComponent from '__common/components/LoaderComponent';
import BomWarnings from 'bom/components/warnings/bomWarnings';
import { check_sm_url_conversion_criteria } from './ProjectDesign';

type Props = {
  bom: bomState,
  dispatch: Function,
  match: any,
  railsList: railsSelectorState,
  isRailsFetching: boolean,
  projectConfiguration: projectConfigurationState,
};

type State = {
  bomIsRedirecting: boolean,
};

class Bom extends React.Component<Props, State> {
  state = {
    bomIsRedirecting: false,
  };

  UNSAFE_componentWillMount() {
    const { dispatch, match: { params: { projectId, productId } } } = this.props;
    dispatch(CLEAR_PK());
    dispatch(CLEAR_RAILS_LIST());
    dispatch(FETCH_BOM_PART_LIST_REQUEST(projectId, productId));
    dispatch(SET_BOM_PRODUCT(products[productId], projectId));
  }

  componentDidMount() {
    const { dispatch, match: { params: { productId } } } = this.props;

    window.addEventListener('resize', () => {
      this.forceUpdate();
    });

    if (products[productId] !== 9){
    // if (products[productId] !== 9 || products[productId] !== 34) {
      dispatch(SET_PRODUCT_TITLE(getProductTitle(products[productId], true)));
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { dispatch, match: { params: { productId, projectId } }, bom: { extraParameters: { is_sm_tilt, selections, project_rail_finish }, reload_selections }, railsList: { railsLoaded } } = this.props;
    if (prevProps.bom.project_name === '' && this.props.bom.project_name) {
      dispatch(SET_PROJECT_NAME(this.props.bom.project_name));
    }
    if (Object.keys(prevProps.bom.extraParameters).length === 0 && Object.keys(this.props.bom.extraParameters).length > 0) {
      if (!(check_sm_url_conversion_criteria(productId,this.props.bom.extraParameters.productId) )
      && getProductName(this.props.bom.extraParameters.productId) != productId) {
        dispatch(SET_PRODUCT_ID(this.props.bom.extraParameters.productId));
        AppHistory.push('/projectNotFound');
      }

      if (this._shouldLoadRails()) {
        const railsList = getDefaultRailsList(products[productId], project_rail_finish);
        dispatch(LOAD_RAILS_LIST(railsList));
      }

      const id = is_sm_tilt ? 99 : products[productId];
      if (reload_selections) {

        if (isSMFamily(productId) && railsLoaded) {
          loadBomSelections(selections, productId, projectId);
        } else if (isAscender(productId) && railsLoaded) {
          loadBomSelections(selections, productId, projectId);
        } else if (isNxtHorizon(productId) && railsLoaded) {
          loadBomSelections(selections, productId, projectId);
        } else if (isNxtTilt(productId) && railsLoaded) {
          loadBomSelections(selections, productId, projectId);
        } else if (!isSMFamily(productId)) {
          loadBomSelections(selections, productId, projectId);
        } else if (isSMTiltPR(productId) && railsLoaded) {
          loadBomSelections(selections, productId, projectId);
        }
      }
      dispatch(SET_PRODUCT_TITLE(getProductTitle(id)));
    }

    if (this._shouldRedirectToThirdPageAfterSelections() && !this.state.bomIsRedirecting) {
      this.setState({ bomIsRedirecting: true });
      saveBom(false);
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    window.addEventListener('resize', null);
    dispatch(BOM_RESET());
  }

  _shouldLoadRails() {
    const { match: { params: { productId } } } = this.props;
    return [
      products.solarmount_hd,
      products.sunframe,
      products.solarmount_2,
      products.solarmount_lt,
      products.solarmount_ascender_flush,
      products.solarmount_2_tilt,
      products.nxt_tilt,
      products.gft,
      products.ula,
      products.nxt_umount,
      products.nxt_horizon_hd,
      products.nxt_horizon_lt,
      products.ascender,
      products.sm_tilt_pr,
      products.sm_tilt_pr_lt
    ].includes(products[productId]);
  }

  _shouldRedirectToThirdPageAfterSelections() {
    const { bom: { partsList, reload_selections, extraParameters: { selections }, stdClampsFetched, goToEngineeringFromBom }, railsList: { railsLoaded } } = this.props;

    if (
      reload_selections &&
      goToEngineeringFromBom &&
      ((this._shouldLoadRails() && railsLoaded) || (this._shouldLoadRails() && (!selections.selected_rails || !selections.selected_rails.length)) || (!this._shouldLoadRails() && !railsLoaded)) &&
      ((selections.accessories.includes('STDCLAMPS') && stdClampsFetched) || !selections.accessories.includes('STDCLAMPS'))
    ) {
      return true;
    }

    if (goToEngineeringFromBom && !reload_selections && partsList.length) {
      return true;
    }

    if (!railsLoaded) {
      return false;
    }
  }

  calculateTotalPrices() {
    const { partsList } = this.props.bom;

    return calculatePrices(partsList);
  }

  @autobind
  onChangeQuantity(partNumber, newQty) {
    const { dispatch } = this.props;
    if (newQty > 1000000) {
      dispatch(SET_NEW_PART_QUANTITY(partNumber, 1000000));
    } else {
      dispatch(SET_NEW_PART_QUANTITY(partNumber, newQty));
    }
  }

  renderLoader() {
    return (<Loader />);
  }
  renderHeaderPrices() {
    const { match: { params: { projectId } } } = this.props;
    const { baseSystemPrice, accessoriesPrice } = this.calculateTotalPrices();
    return (
      <HeaderMiddleBar
        projectId={projectId}
        rightSiteComponent={
          <BomPrices
            baseSystemPrice={baseSystemPrice}
            accessoriesPrice={accessoriesPrice}
          />
        }
      />);
  }

  renderBom() {
    const { match: { params: { productId, projectId } }, railsList: { railsList }, dispatch } = this.props;
    const { partsList, accessoriesList, attachmentsList, tileReplacementList, warningsInfo } = this.props.bom;

    const offsetHeight = document.body ? document.body.offsetHeight : 0;
    const drawerHeight = offsetHeight - headerHeight - footerHeight;
    const PAGES = getBomBasicPages(productId, warningsInfo?.warnings);
    const showMetalxAttachments = isMetalX(products[productId]) && attachmentsList && Object.keys(attachmentsList).length > 0;

    if (PAGES[0]) {
      PAGES[0].content = (
        <>
          {railsList && (Object.keys(railsList).length > 0 || isULA(products[productId]) || isNxtHorizon(products[productId]) || isNxtTilt(products[productId]) || isSMTiltPR(products[productId]) || isAscender(products[productId])) && (<RailsSelector productId={productId} projectId={projectId} />)}
          {!isMetalX(products[productId]) && attachmentsList && Object.keys(attachmentsList).length > 0 && (<AttachmentsSelector productId={productId} />)}
          {tileReplacementList && Object.keys(tileReplacementList).length > 0 && (<TileReplacementSelector productId={productId} />)}
          {showMetalxAttachments && (<MetalxAttachmentSelector productId={productId} />)}

        </>
      );
    }

    if (PAGES[1]) {
      PAGES[1].content = (
        <>
          {accessoriesList.length > 0 && (<AccessoriesSelector productId={productId} projectId={projectId} />)}
        </>
      );
    }

    if (PAGES[2]) {
      PAGES[2].content = (
        <>
          {warningsInfo?.warnings?.length && (<BomWarnings productId={productId} projectId={projectId} />)}
        </>
      );
      PAGES[0] = { ...PAGES[0], active: false };
      PAGES[2] = { ...PAGES[2], active: true };
    }

    const shouldRemoveFirstPage = isRMFamily(products[productId]) || (isEcoFoot2Plus(products[productId])) || isULA(products[productId]) ||
      (isSFMFamily(products[productId]) && !(tileReplacementList && Object.keys(tileReplacementList).length)) ||
      (isMetalX(products[productId]) && !showMetalxAttachments);

    if (shouldRemoveFirstPage) {
      PAGES.shift();
      PAGES[0].active = true;
    }

    return (
      <div className="bom-content">
        <Drawer keepOpen={true} productId={products[productId]} pages={PAGES} drawerHeight={drawerHeight} listClass="bom-control-panel" />
        <BomTable projectId={projectId} dispatch={dispatch} partsList={partsList} onChangeQuantity={this.onChangeQuantity} productId={productId} />
      </div>
    );
  }

  @autobind
  renderFooter() {
    const { dispatch, match: { params: { projectId, productId } } } = this.props;
    const url = `/project/design/${productId}/${projectId}`;
    return (
      <span style={{ display: 'flex' }}>
        <BackButton url={url} dispatch={dispatch} action={CLEAR_PK} />
        <SaveBom projectId={projectId} productId={productId} />
      </span>
    );
  }

  render() {
    const {
      bom: {
        error,
        isLoading,
        isReady,
        bomPk,
        goToEngineeringFromBom,
      },
      match: {
        params: {
          productId,
          projectId,
        },
      },
      railsList: {
        railsList,
      },
      isRailsFetching
    } = this.props;

    if (bomPk) {
      AppHistory.push(`/project/engineering/${productId}/${projectId}`);
      return null;
    }

    let content: JSX.Element;

    if (error && error.status === 401) {
      content = (<p>Not authorized</p>);
    }

    if (isLoading) {
      content = this.renderLoader();
    }

    if (isReady && !isSMFamily(products[productId])) {
      content = this.renderBom();
    }

    if (isReady && isSMFamily(products[productId]) && railsList.length) {
      content = this.renderBom();
    }

    if (isReady && isAscender(products[productId]) && railsList.length) {
      content = this.renderBom();
    }

    if (isReady && isNxtHorizon(products[projectId]) && railsList.length) {
      content = this.renderBom();
    }

    if (isReady && isSMTiltPR(products[projectId]) && railsList.length) {
      content = this.renderBom();
    }

    if (!content || goToEngineeringFromBom) {
      content = this.renderLoader();
    }
    return (
      <div className="bom-page">
        <Header navigation={true} Infobar={this.renderHeaderPrices()} productId={productId} projectId={projectId} />
        {isRailsFetching ? <LoaderComponent /> : content || null}
        <Footer ContainerFooter={this.renderFooter} />
      </div>
    );
  }
}

function mapPropTypes(state: appState) {
  return {
    bom: state.bom,
    railsList: state.railsSelector,
    isRailsFetching: state.railsSelector.railsFetching,
    projectConfiguration: state.projectConfiguration,
  };
}

export default connect(mapPropTypes)(Bom);

import React from 'react';
import DialogContainer from 'react-md/lib/Dialogs/DialogContainer';
import TextField from 'react-md/lib/TextFields/TextField';

type Props = {
  value: string | number,
  onConfirm?: Function,
  onBlur?: (event: any) => void,
  onCancel?: Function,
  textFieldProps?: Object,
  shouldConfirm: boolean,
  shouldConfirmCb?: (val) => boolean
  title: string,
  content: string,
};

type State = {
  value: string,
  tempValue: string,
  confirmVisible: boolean,
};

export default class ConfirmationTextField extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      tempValue: '',
      confirmVisible: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.value !== nextProps.value) {
      this.setState({ value: nextProps.value, tempValue: this.state.value, confirmVisible: false });
    }
  }

  handleFieldChange = (value: string) => {
    const { shouldConfirm, onConfirm, shouldConfirmCb } = this.props;
    const confirmed = shouldConfirm && (!shouldConfirmCb || shouldConfirmCb(value));
    if (confirmed) {
      this.setState({ value, tempValue: this.state.value, confirmVisible: true });
    } else {
      this.setState({ value, tempValue: this.state.value, confirmVisible: false });

      if (onConfirm) {
        onConfirm(value);
      }
    }
    
  }

  confirm = () => {
    const { onConfirm } = this.props;
    this.setState({ tempValue: this.state.value, confirmVisible: false });

    if (onConfirm) {
      onConfirm(this.state.value);
    }
  }

  cancel = () => {
    const { onCancel } = this.props;
    this.setState({ value: this.state.tempValue, confirmVisible: false });

    if (onCancel) {
      onCancel(this.state.tempValue);
    }
  }

  render() {
    const { textFieldProps, title, content, onBlur } = this.props;
    const { value, confirmVisible } = this.state;
    return (
      <>
        <TextField
          id=""
          value={value}
          onChange={this.handleFieldChange}
          onBlur={onBlur}
          {...textFieldProps}
        />
        <DialogContainer
          id="arrays-lost-modal"
          title={title}
          actions={[{
            className: 'cancel-button',
            children: 'NO, CANCEL',
            onClick: this.cancel,
          }, {
            className: 'submit-button',
            children: 'YES, CONTINUE',
            onClick: this.confirm,
          }]}
          visible={confirmVisible}
          onHide={this.cancel}
        >
          {content}
        </DialogContainer>
      </>
    );
  }
}

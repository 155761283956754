import keyMirror from 'keymirror';

export const DrawingManagerActionTypes = keyMirror({
  CLEAR_SELECTION: undefined,
  SET_SELECTED_ROOF_AND_MARKER: undefined,
  UNSET_SELECTED_ROOF_AND_MARKER: undefined,
  UNSELECT_ALL_SELECTED_ROOF_AND_MARKER: undefined,
  DELETE_ROOF: undefined,
  DELETE_ALL_ROOFS: undefined,
  ADD_ROOF: undefined,
  UPDATE_ONE_ROOF_CORNER: undefined,
  SAVE_PANELS_TO_DRAWING_MANAGER: undefined,
  LOAD_ROOFS_FROM_API: undefined,
  RESET_DRAWING_MANAGER: undefined,
  SET_ARRAY_NAME: undefined,
  UPDATE_ROOF_COORDS: undefined,
  SORT_ROOFS_ON_MAP: undefined,
  TURN_ON_EDIT: undefined,
  TURN_OFF_EDIT: undefined,
  DELETE_PANELS_INSIDE_OF_ROOF: undefined,
  CHANGE_ROOF_ZOOM: undefined,
  SET_ROOF_ROW_SPACING: undefined,
  SET_ROOF_TABLE_ROW_SPACING: undefined,
  ADD_POINT_TO_ROOF: undefined,
  SET_ROOF_ZOOM: undefined,
  SET_ROOF_ROOF_PITCH: undefined,
  RESIZE_PANELS: undefined,
  REPLACE_PANELS_ON_ROOF: undefined,
  CHANGE_CHOOSE_BASED_ON_LAYOUT: undefined,
  UPDATE_BLANK_MAP_ROOF_LENGTH_WIDTH_FOR_BLANK_ROOF: undefined,
  REMOVE_PANELS_ON_ROOF: undefined,
  SET_ROOF_AREA_SEEN: undefined,
  SET_USER_CLICKED_YES_OR_NO: undefined,
  SET_PANELS_IN_OVERFLOWING_STATE: undefined,
});

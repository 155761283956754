import * as PIXI from 'pixi.js';
import * as React from 'react';
import BingMapsRoofsSelector from '__editor/bingMapsRoofsSelector/bingMapsRoofsSelector';
import FogOfWarComponent from '__common/components/FogOfWar';
import GoogleMapsRoofsSelector from '__editor/googleMapsRoofsSelector/googleMapsRoofsSelector';
import Loader from '__common/components/LoaderComponent';
import PanelsEditorControls from '__editor/panelsEditor/components/panelsEditorControls/panelsEditorControls';
import PanelsEditorDrawer from '__editor/panelsEditor/components/panelsEditorDrawer/panelsEditorDrawer';
import window from 'global/window';
import { clearTextures } from './models/utils/textureManager';
import { connect } from 'react-redux';
import { destroyRenderer, setupRenderer } from '__editor/panelsEditor/components/renderer/renderer';
import { determineZoomLevel } from '__editor/panelsEditor/components/background/utils/determineZoomLevel';
import { dispatch } from '__common/store';
import { drawCursorOnStage } from '__editor/panelsEditor/components/cursor/drawingCursorOnStage';
import { drawFogOfWarOnStage } from '__editor/panelsEditor/components/fogOfWar/drawFogOfWarOnStage';
import { drawingScaleToolOnStage } from '__editor/panelsEditor/components/scale/drawingScaleToolOnStage';
import { drawRubberBandOnStage } from '__editor/panelsEditor/components/rubberBand/drawingRubberBandOnStage';
import { footerHeight, headerHeight } from '__common/constants/layout_css_dims';
import { getTiltedModuleRowSpacings, getTiltedModuleTableRowSpacingsGFT } from './components/tiltedModule/tiltedModule';
import { inchesToMeters } from 'maths';
import { initKeyboard } from '__editor/panelsEditor/keyboardConfg';
import { isRMFamily, products, isULA, isGFT, isAscender, isSMTiltPR, isEcoFoot2Plus, isRmGridflex10 } from '__common/constants/products';
import { loadRoofRowSpacing, loadRoofTableRowSpacing } from 'projectDesign/components/projectConfiguration/utils/loadRoofRowSpacing';
import { resetPanelEditorState } from './components/lifeCycle/panelsEditorUnmount';
import { resetRTree } from './components/panels/panelsCollisions';
import { setCanvas } from './components/canvas/canvas';
import { setEditorDims, shouldUseVirtualRoofEdges } from './panelsEditorHelper';
import 'fpsmeter';
import {
  BG_CLEAR_LOADED,
  BG_LOADED,
  SET_BACKGROUND_XY_POS,
  SET_CANVAS_CENTER,
  SET_ROW_SPACING,
  RESET_HISTORY,
  CLEAR_HISTORY,
  SET_TABLE_ROW_SPACING,
  HIDE_CONFIRM_CLEAR_ARRAYS_MODAL,
} from 'actions';
import {
  drawCursor,
  removeCursor,
} from '__editor/panelsEditor/components/cursor/cursor';
import {
  drawLeadEdge,
  drawRotatedLeadEdge,
  removeLeadEdge,
  removeRotatedLeadEdge,
} from '__editor//panelsEditor/components/leadEdge/leadEdge';
import {
  getBingMapsImageLink,
  getGoogleMapImageLink,
} from '__editor/panelsEditor/models/backgrounds';
import {
  getScaleFromPitchRoof,
  loadRoofPitch,
  loadStructureTilt,
} from '__editor/panelsEditor/components/tiltedRoof/tiltedRoof';
import {
  initZoom,
  terminateZoom,
  zoomRoof,
} from '__editor/panelsEditor/components/stage/zoom';
import {
  panelsEditorDidUpdate,
  shouldUpdateComponent,
} from './components/lifeCycle/panelsEditorDidUpdate';
import {
  getBackground,
  getBackgroundContainer,
  getCenterBgCords,
  removeBackground,
  createRoofEdges,
  createRoofDimensions,
} from '__editor/panelsEditor/components/background/background';
import {
  setPanelsDimensions,
} from '__editor/panelsEditor/components/panels/panels';
import {
  getMeterPerPixel,
  setStagedMouseEvents,
  setupStage,
  destroyPanelsEditorStage,
  updateLayersOrder,
} from '__editor/panelsEditor/components/stage/stage';
import { createObstructionContainer, loadObstructions } from './components/obstructions/obstructions';
import { drawObstructionsOnStage } from './components/obstructions/drawObstructionsOnStage';
import { drawRoofZonesOnStage } from './components/roofZones/drawRoofZonesOnStage';
import { removePanelsContainer, createPanelsContainer, createBaysContainer, removeBaysContainer } from './components/panels/utils/panelsContainer';
import { loadPanels } from './components/panels/utils/panelsManagment';
import { clearLastSnapGroup } from './components/panels/utils/panelsSnappingGroup';
import { PanelsContainer } from '__editor/panelsEditor/models/panelsContainer';
import { isBlankMap } from '__common/constants/map';
import { loadPanelsRotationDegrees } from './components/background/utils/rotatedPanels';
import { applyPanelRotationFactorChanges } from '__common/utils/versionCompare/versionCompare';
import { loadLowEdgeToRoof } from './components/background/utils/loadLowEdgeToRoof';

type state = {
  isImgLoading: boolean;
  zoomedIn: boolean;
  bgAdded: boolean;
  panelsDict: any;
};

class PanelsEditor extends React.Component<panelsEditorComponentState, state> {

  state = {
    isImgLoading: true,
    zoomedIn: false,
    bgAdded: false,
    panelsDict: {}
  };

  canvasHeight = document.body.offsetHeight - headerHeight - footerHeight;

  screenshotsRes = {
    width: 1366,
    height: 768,
  };

  roofEdges: PIXI.Graphics;
  roofDimensions: PIXI.Graphics;
  outerDiv: HTMLElement;
  renderer: any;
  canvas: HTMLElement;
  panelsContainer: PanelsContainer;
  stopAnimation: boolean;
  stage: PIXI.Container;
  backgroundContainer: PIXI.Container;
  cursor: PIXI.Graphics;
  background: PIXI.Container;
  fogOfWar: any;
  baysContainer: PanelsContainer;

  shouldComponentUpdate(oldProps, oldState) {
    return shouldUpdateComponent(this.props, oldState, this.state);
  }

  componentDidMount() {
    const {
      roofsSelector: { mapType },
    } = this.props;    

    this.loadMapImage(mapType);
  }

  componentDidUpdate(prevProps, prevState) {
    panelsEditorDidUpdate(
      prevProps,
      this.props,
      prevState,
      this.state,
      this.outerDiv,
      this.runEditor,
      this.resetZoom,
      this.redrawBackground,
      this.redrawCursor,
      this.drawRubberBand,
      this.redrawLeadEdge,
      this.resizeBackgroundContainer,
      drawingScaleToolOnStage,
      this.redrawRotatedLeadEdge,
      this.drawRoofDimensions,
    );
    if(this.props.panels.panels.length){
      const newPanelsDict = this.props.panels.panels.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});
      this.setState({panelsDict:newPanelsDict})
    }
    // console.log('isEqualEEEE',isEqual(prevProps.panels.bays.panelBayMapping, currentProps.panels.bays.panelBayMapping))

  }

  componentWillUnmount() {
    terminateZoom(this.canvas, this.stage, this.renderer);
    cancelAnimationFrame(this.animate.bind(this));
    resetPanelEditorState();
    resetRTree();
    clearTextures();
    clearLastSnapGroup();
    dispatch(RESET_HISTORY());
    dispatch(CLEAR_HISTORY());
    destroyPanelsEditorStage();
    destroyRenderer();
    this.onBgReload();
    this.stopAnimation = true;
    this.canvas = null;
    this.fogOfWar = undefined;
    document.body.style.cursor = 'default';
    removePanelsContainer();
    removeBaysContainer();
    this.setState({panelsDict:{}})
    if (this.props.isDialogOpen) dispatch(HIDE_CONFIRM_CLEAR_ARRAYS_MODAL());
  }

  getMapLink = (mapType: string) => {
    if (mapType === 'google') {
      return getGoogleMapImageLink();
    }

    if (mapType === 'bing') {
      return getBingMapsImageLink();
    }
  }

  loadMapImage = (mapType: string) => {
    const mapLink = this.getMapLink(mapType);

    if (PIXI.loader.resources[mapLink] || mapType === 'white') {
      this.finishImageLoading();
    } else {
      this.addMapImage(mapLink);
    }
  }

  addMapImage = (mapLink: string) => {
    PIXI.loader
      .add(mapLink)
      .on('complete', () =>
        this.finishImageLoading(),
      )
      .load();
  }

  finishImageLoading = () => {
    this.setState(prevState => ({ isImgLoading: false }));
  }

  runEditor = () => {
    const { screenshots, projectConfiguration: { projectEnvConfig: { tilt }, productId } } = this.props;

    this.setState(prevState => ({ isImgLoading: false }));

    if (screenshots) {
      this.renderer = setupRenderer(this.screenshotsRes.width, this.screenshotsRes.height);
    } else {
      this.renderer = setupRenderer(this.outerDiv.offsetWidth, this.canvasHeight);
    }

    this.canvas.appendChild(this.renderer.view);
    const { dispatch } = this.props;

    const isGridflex = productId === products.rm_gridflex;
    const isRM5 = productId === products.rm_5;

    if (isRM5 || isGridflex || isSMTiltPR(productId) || isRmGridflex10(productId) ) {
      loadRoofRowSpacing();
    } else if (tilt && !isULA(productId) && !isAscender(productId)) {
      const rowSpacing = getTiltedModuleRowSpacings(tilt);
      dispatch(SET_ROW_SPACING(inchesToMeters(rowSpacing)));
      if(isGFT(productId)){
        const tableRowSpacing = getTiltedModuleTableRowSpacingsGFT(tilt);
        dispatch(SET_TABLE_ROW_SPACING(tableRowSpacing));
      }
    }

    this.initCanvas(productId);
    this.panelsContainer = createPanelsContainer();
    this.baysContainer = createBaysContainer();
    getMeterPerPixel();
    setPanelsDimensions();
    loadObstructions();
    loadPanels();
    createObstructionContainer();
    drawObstructionsOnStage();
    initKeyboard(isRMFamily(productId));

    dispatch(
      SET_CANVAS_CENTER(this.outerDiv.offsetWidth / 2, this.canvasHeight / 2),
    );
  }

  initCanvas(productId: number) {
    const { screenshots, projectConfiguration: {projectVersion} } = this.props;
    this.stage = setupStage(this.outerDiv.offsetWidth, this.canvasHeight);
    window.stage = this.stage;
    determineZoomLevel(this.outerDiv.offsetWidth, this.canvasHeight);
    this.backgroundContainer = getBackgroundContainer();
    this.stage.addChild(this.backgroundContainer);
    this.drawBackground('google');
    setStagedMouseEvents(this.canvas);
    initZoom(this.canvas, this.stage, this.renderer);
    this.drawRoofEdges();
    this.drawRoofDimensions();

    isSMTiltPR(productId) ? loadStructureTilt() : loadRoofPitch();
    
    loadRoofPitch();

    if(applyPanelRotationFactorChanges(projectVersion)) loadPanelsRotationDegrees();

    if(isAscender(productId)) loadLowEdgeToRoof();
    
    this.animate();

    this.fogOfWar = drawFogOfWarOnStage(this.outerDiv.offsetWidth, this.canvasHeight, screenshots);

    this.cursor = drawCursor({ x: 0, y: 0 }, productId);
    this.stage.addChild(this.cursor);
  }

  drawfogOfWar() {

  }

  resetZoom = () => {
    this.setState(prevState => ({ zoomedIn: false }));
    this.stage.x = 0;
    this.stage.y = 0;
    this.stage.scale.set(1);
  }

  onBgReload = () => {
    const { dispatch } = this.props;
    this.setState({ bgAdded: false });
    dispatch(BG_CLEAR_LOADED());
  }

  redrawCursor = () => {
    const {
      productId,
      rubberBand: { mode },
      scale: { enabled },
      panels: {panelsToBeMoved},
      background: {moveArrayMode}
    } = this.props;

    if (!this.stage) {
      return;
    }

    this.stage.children = removeCursor(this.stage.children);

    if (mode === 'draw' || enabled || (moveArrayMode &&  panelsToBeMoved.length > 0) ) {
      this.cursor = drawCursor({ x: 0, y: 0 }, products[productId]);
      this.stage.addChild(this.cursor);
    }
    drawObstructionsOnStage();
  }

  drawBackground(type = 'google') {
    const {
      dispatch,
      roofsSelector: { mapType },
    } = this.props;
    const { x, y, rotationDegrees } = getCenterBgCords(
      this.outerDiv.offsetWidth,
      this.canvasHeight,
    );
    this.background = getBackground(x, y, rotationDegrees, mapType);
    this.backgroundContainer.addChild(this.background);
    const backgroundScale: pixelPoint = {
      x: this.background.scale.x,
      y: this.background.scale.y,
    };
    dispatch(SET_BACKGROUND_XY_POS(x, y, backgroundScale));
    dispatch(CLEAR_HISTORY());
  }

  redrawBackground = () => {
    const { projectConfiguration: { productId }, screenshots } = this.props;
    this.onBgReload();
    getMeterPerPixel();
    this.backgroundContainer.children = removeBackground(
      this.backgroundContainer.children,
    );
    this.drawBackground(this.props.background.backgroundType);
    this.drawRoofEdges();
    this.drawRoofDimensions();
    this.drawLeadEdge();
    this.drawRotatedLeadEdge();

    this.stage.removeChild(this.fogOfWar);

    this.fogOfWar = undefined;

    drawFogOfWarOnStage(this.outerDiv.offsetWidth, this.canvasHeight, screenshots);

    const isGridflex = productId === products.rm_gridflex;
    const isRM5 = productId === products.rm_5;

    if (isRM5 || isGridflex || isAscender(productId) || isRmGridflex10(productId)) {
      loadRoofRowSpacing();
    }
    
    if (isGFT(productId) || isAscender(productId)) {
      loadRoofTableRowSpacing();
    }

    this.resizeBackgroundContainer();
    dispatch(CLEAR_HISTORY());
    clearLastSnapGroup();
    drawRoofZonesOnStage();
  }

  drawRubberBand = (prevState) => {
    const prevRubberBandState = prevState.rubberBand;
    drawRubberBandOnStage(
      this.stage,
      this.panelsContainer,
      prevRubberBandState,
    );
  }

  drawRoofEdges() {
    const {
      roofsSelector: { mapType },
      projectConfiguration: { productId, projectVersion},
    } = this.props;

    this.roofEdges = createRoofEdges();
    if (isBlankMap(mapType) && !shouldUseVirtualRoofEdges(mapType, productId, projectVersion)) {
      this.roofEdges.visible = false;
    }

    this.background.addChild(this.roofEdges);
  }

  drawRoofDimensions = () => {
    const {
      roofsSelector: { mapType },
      background: { showRoofDimensions }
    } = this.props;
    if (isBlankMap(mapType)) {
      return;
    }

    this.removeRoofDimensions();

    if (showRoofDimensions){
      this.roofDimensions = createRoofDimensions(this.stage.scale.x);
      this.stage.addChild(this.roofDimensions);
    }
  
    return;
  }

  removeRoofDimensions() {
    if (this.roofDimensions) {
      this.stage.removeChild(this.roofDimensions);
    }
  }

  drawLeadEdge() {
    const {
      roofsSelector: { mapType },
    } = this.props;

    if (mapType === 'white') {
      return;
    }

    const leadEdge = drawLeadEdge();
    this.background.addChild(leadEdge);
  }

  redrawLeadEdge = () => {
    this.background.children = removeLeadEdge(this.background.children);
    const leadEdge = drawLeadEdge();
    this.stage.addChild(leadEdge);
  }


  drawRotatedLeadEdge() {
    const {
      roofsSelector: { mapType },
    } = this.props;

    if (mapType === 'white') {
      return;
    }

    const rotatedLeadEdge = drawRotatedLeadEdge();
    this.background.addChild(rotatedLeadEdge);
  }

  redrawRotatedLeadEdge = () => {
    this.background.children = removeRotatedLeadEdge(this.background.children);
    const rotatedLeadEdge = drawRotatedLeadEdge();
    this.stage.addChild(rotatedLeadEdge);
  }

  
  resizeBackgroundContainer = () => {
    const {
      background: { bgXY },
      tiltedRoof: { roofPitch },
      screenshots,
      projectConfiguration: { productId } 
    } = this.props;
    const scale = getScaleFromPitchRoof(roofPitch, productId);
    this.backgroundContainer.children[0].y = (bgXY.y / scale);
    this.backgroundContainer.scale.y = scale;

    drawFogOfWarOnStage(this.outerDiv.offsetWidth, this.canvasHeight, screenshots, this.backgroundContainer.children[0].y);
  }

  _shouldZoomResidentalProducts() {
    const {
      background: { selectedRoofId },
      drawingManager: { roofs },
      roofsSelector: { mapType },
      projectConfiguration: { productId },
    } = this.props;

    return mapType === 'white' && !(isRMFamily(productId) || isEcoFoot2Plus(productId)) && roofs[selectedRoofId].panels;
  }

  animate = () => {
    const { zoomedIn, bgAdded } = this.state;
    const {
      productId,
      roofsSelector: { mapType },
      dispatch,
      screenshots,
      background: { bgLoaded, moveMapMode, },
    } = this.props;
    const classicDesignerZoom = mapType === 'white';

    if (this.stopAnimation) {
      return;
    }

    setEditorDims(this.outerDiv.offsetWidth, this.canvasHeight);

    if (
      !zoomedIn &&
      (screenshots || this._shouldZoomResidentalProducts()) &&
      zoomRoof(
        this.stage,
        this.renderer,
        this.outerDiv.offsetWidth,
        this.canvasHeight,
        classicDesignerZoom,
        products[productId],
      )
    ) {
      this.setState(prevState => ({ zoomedIn: true }));
    }

    if (
      (!bgAdded &&
        !bgLoaded &&
        (this.background.getLocalBounds().width === 1280 || this.background.getLocalBounds().width > 0)) ||
      (mapType === 'white' && !bgLoaded)
    ) {
      this.setState({ bgAdded: true });
      dispatch(BG_LOADED());
    }

    if (this.cursor && moveMapMode ) {
      this.cursor.alpha = 0.0001;
    } else if (this.cursor) {
      this.cursor.alpha = 1;
    }

    !screenshots && drawCursorOnStage( // don't draw cursor when Panel Editor is to be opened in screenshot mode
      this.renderer,
      this.stage,
      this.cursor,
      this.canvas,
      productId,
    );

    this.stage.children = updateLayersOrder(this.stage.children);

    this.renderer.render(this.stage);
    requestAnimationFrame(this.animate.bind(this));
  }

  renderPreviewWindow() {
    const {
      roofsSelector: { mapType },
    } = this.props;
    if (mapType === 'google') {
      return (
        <GoogleMapsRoofsSelector
          className="roofs-selector google-maps-roof-selector"
          previewMode={true}
        />
      );
    }

    return (
      <BingMapsRoofsSelector
        className="roofs-selector bing-maps-roof-selector"
        previewMode={true}
      />
    );
  }

  renderEditorForScreenshots = () => {
    const {
      background: { bgLoaded },
    } = this.props;
    if (this.state.isImgLoading || !bgLoaded || !this.state.bgAdded || !this.state.zoomedIn) {
      return (
        <div
          style={{
            width: this.screenshotsRes.width,
            height: this.screenshotsRes.height,
          }}
          ref={outerDiv => {
            this.outerDiv = outerDiv;
          }}
        >
          <div
            style={{ opacity: 0 }}
            ref={c => {
              setCanvas(c);
              this.canvas = c;
            }}
          />
          <Loader />
        </div>
      );
    }

    return (
      <div
        style={{
          width: this.screenshotsRes.width,
          height: this.screenshotsRes.height,
        }}
        ref={outerDiv => {
          this.outerDiv = outerDiv;
        }}
        id="project_info"
      >
        <div
          id="panels_editor"
          ref={c => {
            setCanvas(c);
            this.canvas = c;
          }}
        />
      </div>
    );
  }

  renderEditor = () => {
    const {
      background: { bgLoaded },
    } = this.props;
    const { zoomedIn } = this.state;
    if (this.state.isImgLoading && !bgLoaded && !zoomedIn) {
      return (
        <div
          className="panels-loader-loading"
          ref={outerDiv => {
            this.outerDiv = outerDiv;
          }}
        >
          <div
            style={{ opacity: 0 }}
            ref={c => {
              setCanvas(c);
              this.canvas = c;
            }}
          />
          <Loader />
        </div>
      );
    }

    return (
      <div ref={(outerDiv) => { this.outerDiv = outerDiv; }} id="project_info">
        <FogOfWarComponent />
        <div id="panels_editor" ref={(c) => { setCanvas(c); this.canvas = c; }} />
        {!this.state.isImgLoading && <PanelsEditorControls />}
        {this.renderPreviewWindow()}
      </div>
    );
  }

  renderFullEditor() {
    const { productId, background:{ bgLoaded } } = this.props;
    return (
      <div className="panels-editor-container">
        {(!this.state.isImgLoading && bgLoaded) && <PanelsEditorDrawer productId={productId} />}
        {this.renderEditor()}
      </div>
    );
  }

  render() {
    const { screenshots } = this.props;

    if (screenshots) {
      return this.renderEditorForScreenshots();
    }

    return this.renderFullEditor();
  }
}

function mapStateToProps(state: appState) {
  return {
    advanceRoofSelecting: state.advanceRoofSelecting,
    background: state.background,
    drawingManager: state.drawingManager,
    editorCursor: state.editorCursor,
    dimensions: state.dimensions,
    history: state.history,
    moduleSelector: state.moduleSelector,
    measurementTool: state.measurementTool,
    leadEdgeRoofSelector: state.leadEdgeRoofSelector,
    panels: state.panels,
    projectConfiguration: state.projectConfiguration,
    roofsSelector: state.roofsSelector,
    roofZones: state.roofZones,
    rubberBand: state.rubberBand,
    scale: state.scale,
    settings: state.settings,
    tiltedRoof: state.tiltedRoof,
    tooltipCursor: state.tooltipCursor,
    obstructions: state.obstructions,
    isDialogOpen: !!state.confirmClearArraysModal?.dialogStyleProps,
    isStaff: state.user.isStaff,
    stage: state.stage,
  };
}

export default connect(mapStateToProps)(PanelsEditor);

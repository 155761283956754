import { getBomCSVName } from 'bom/utils/bomCSVNames';
import React from 'react';
import DataTable from 'react-md/lib/DataTables/DataTable';
import TableBody from 'react-md/lib/DataTables/TableBody';
import TableColumn from 'react-md/lib/DataTables/TableColumn';
import TableHeader from 'react-md/lib/DataTables/TableHeader';
import TableRow from 'react-md/lib/DataTables/TableRow';
import { FETCH_STATIC } from '__common/components/FetchStatics/fetchStaticsActions';
import { dispatch } from '__common/store';
import SVG from 'react-inlinesvg';

type Props = {
  data: any[],
};

export default class CombinedProjectsTable extends React.Component<Props> {

  fetchBom = projectId => {
    dispatch(
      FETCH_STATIC(`${getBomCSVName(projectId)}.csv`, 'text/csv', `download/bom/`, { projectId }),
    );
  }
  
  render() {
    const { data } = this.props;
    return (
    <DataTable plain={true} className="combined-projects-parts-table">
      <TableHeader>
        <TableRow>
        <TableColumn>PROJECT ID</TableColumn>
          <TableColumn>PROJECT NAME</TableColumn>
          <TableColumn>CREATED</TableColumn>
          <TableColumn>MFG</TableColumn>
          <TableColumn>MODEL</TableColumn>
          <TableColumn>CLIENT NAME</TableColumn>
          <TableColumn>ADDRESS</TableColumn>
          <TableColumn>ZIP</TableColumn>
          <TableColumn>MODULES</TableColumn>
          <TableColumn>WATTS</TableColumn>
          <TableColumn>LIST PRICE</TableColumn>
          <TableColumn>DOWNLOAD BOM</TableColumn>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.map((project, index) => (
          <TableRow key={index}>
            <TableColumn>{project.project_id}</TableColumn>
            <TableColumn>{project.project_name}</TableColumn>
            <TableColumn>{project.created}</TableColumn>
            <TableColumn>{project.panel_mfg}</TableColumn>
            <TableColumn>{project.panel_model}</TableColumn>
            <TableColumn>{project.client_name}</TableColumn>
            <TableColumn>{project.client_address}</TableColumn>
            <TableColumn>{project.zipcode}</TableColumn>
            <TableColumn>{project.total_modules}</TableColumn>
            <TableColumn>{project.total_watts}</TableColumn>
            <TableColumn>{project.total_price}</TableColumn>
            <TableColumn>
            <button onClick={() => this.fetchBom(project.project_id)}><i className="md-icon material-icons md-text--inherit">download</i></button>
           </TableColumn>
          </TableRow>
        ))}
      </TableBody>
    </DataTable>);
  }
}

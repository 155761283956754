import smClampsTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/smClampsTooltip';
import midClampsTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/midClampsTooltip';
import endClampsTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/endClampsTooltip';
import { makeAllRoofsEmpty } from '__editor/components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManager';
import { anyPanelsDrawn } from '__editor/googleMapsRoofsSelector/components/drawingManager/drawingManagerHelper';
import nhMidClampsToolTip from '../fieldsTooltips/nhMidClampsToolTip';
import nhEndClampToolTip from '../fieldsTooltips/nhEndClampToolTip';
import { SM_TILT_MODULE_LENGTH_ORIENTATION_LIMIT } from '__common/constants/modules';


export const apiField = 'clamps_choices';
export const midClampsApiField = 'mid_clamps_choices';
export const endClampsApiField = 'end_clamps_choices'
export const smClampsOptions = {
  proClamps: {
      value: 1,
      name: 'PRO CLAMPS',
    },
  standardClamps: {
      value: 2,
      name: 'STANDARD CLAMPS',
  },
  universalClamps: {
      value : 3,
      name : 'UNIVERSAL AF',
  },
  nxtHorizonComboClamps: {
      value: 4,
      name: 'NXT UMOUNT COMBO CLAMP',
    },
  nxtHorizonHiddenEndClamps: {
      value: 5,
      name: 'NXT UMOUNT HIDDEN END CLAMP',
    },
}


const smClampsChoiceOptionsData = {
  proStdUni: [
    smClampsOptions.proClamps,
    smClampsOptions.standardClamps,
    smClampsOptions.universalClamps,
  ],
  proUni: [
    smClampsOptions.proClamps,
    smClampsOptions.universalClamps,
  ],
  nxtHorizonCombo: [
    smClampsOptions.nxtHorizonComboClamps,
  ],
  nxtHorizonComboWithHiddenEndClamp : [
    smClampsOptions.nxtHorizonComboClamps,
    smClampsOptions.nxtHorizonHiddenEndClamps
  ],
  uni: [
    smClampsOptions.universalClamps
  ]
}

export default {
  type: 'Select',
  label: 'clamps',
  apiField,
  tooltip: smClampsTooltip,
  onConfirm: () => {
    makeAllRoofsEmpty();
  },
  shouldConfirm: () => anyPanelsDrawn(),
  data: smClampsChoiceOptionsData.proStdUni,
};

export const smMidAndEndClamps = (apiField: string, label: string, isHelioscope = false) => {
  return {
    type: 'Select',
    label,
    apiField,
    data: smClampsChoiceOptionsData.proStdUni,
    tooltip: apiField === midClampsApiField ? midClampsTooltip : endClampsTooltip,
    ...(!isHelioscope? {
      onConfirm: () => {
        apiField ===midClampsApiField ? makeAllRoofsEmpty() : null ;
      },
      shouldConfirm: () => apiField ===midClampsApiField ? anyPanelsDrawn() : null
    }: {}),
  };
};

export const nhMidAndEndClamps = (apiField, label, isHelioscope = false) => {
  return {
    type: 'Select',
    missing: undefined,
    label,
    apiField,
    tooltip: apiField === midClampsApiField ? nhMidClampsToolTip : nhEndClampToolTip,
    ...(!isHelioscope ? {
      onConfirm: () => {
      apiField ===midClampsApiField ? makeAllRoofsEmpty() : null ;
      },
      shouldConfirm: () => apiField ===midClampsApiField ? anyPanelsDrawn() : null
    } : {}),
    data: apiField === midClampsApiField ? smClampsChoiceOptionsData.nxtHorizonCombo : smClampsChoiceOptionsData.nxtHorizonComboWithHiddenEndClamp
  };
};


export const smTiltClamps = (moduleLength) => {
  return {
    type: 'Select',
    label: 'clamps',
    apiField: apiField,
    tooltip: smClampsTooltip,
    onConfirm: () => {
      makeAllRoofsEmpty();
    },
    shouldConfirm: () => anyPanelsDrawn(),
    data: moduleLength <= SM_TILT_MODULE_LENGTH_ORIENTATION_LIMIT ? smClampsChoiceOptionsData.proStdUni : smClampsChoiceOptionsData.proUni
    ,
  };
  };


  export const ascenderMidAndEndClamps = (apiField) => {
    return apiField === midClampsApiField? 
       {
        type: 'Select',
        label: "mid_clamps",
        apiField,
        tooltip: midClampsTooltip,
        data: smClampsChoiceOptionsData.uni,
      }
    : 
    {
        type: 'Select',
        label: "end_clamps",
        apiField,
        tooltip: endClampsTooltip,
        data: smClampsChoiceOptionsData.proUni,
      };
};
    

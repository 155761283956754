import { isRM10, isRM10Evolution, isRM5, isRmGridflex10, products } from "__common/constants/products";
import { isMetricUnit } from "engineering/components/engineeringProjectDocuments/utils/unitTypes";
import upliftShearAllowableTooltip, { upliftShearAllowableGridflexTooltip } from "../fieldsTooltips/upliftShearAllowableTooltip";
import { round } from "lodash";
import { lbsToKgs } from "__common/calculations/unitConversions";
import { options } from "../fields/uAnchorType"
import { applyAttachmentCapacityChanges } from "__common/utils/versionCompare/versionCompare";

export const apiField = 'shear_allowable';

export const shearAllowabledefaultValue = 500;
export const shearAllowabledefaultValueKg = round(lbsToKgs(shearAllowabledefaultValue), 2);

export enum AttachmentTypeShearIFIMaxLimit {
  UNIRAC_FLASHLOC_RM = 500,
  U_ANCHOR_U2400 = 500,
  CUSTOM = 500,
}

export enum AttachmentTypeShearIFIMaxLimitKg {
  UNIRAC_FLASHLOC_RM = round(lbsToKgs(AttachmentTypeShearIFIMaxLimit.UNIRAC_FLASHLOC_RM), 1),
  U_ANCHOR_U2400 = round(lbsToKgs(AttachmentTypeShearIFIMaxLimit.U_ANCHOR_U2400), 1),
  CUSTOM = round(lbsToKgs(AttachmentTypeShearIFIMaxLimit.CUSTOM), 1),
}

export enum AttachmentTypeShearRM10MaxLimit {
  UNIRAC_FLASHLOC_RM = 621,
  U_ANCHOR_U2400 = 656,
  OMG = 358,
  CUSTOM = 1000,
}

export enum AttachmentTypeShearRMGridflex10MaxLimit {
  UNIRAC_FLASHLOC_RM = 500,
  U_ANCHOR_U2400 = 404,
  OMG = 500,
  CUSTOM = 500,
}
export enum AttachmentTypeShearRM10MaxLimitKg {
  UNIRAC_FLASHLOC_RM = round(lbsToKgs(AttachmentTypeShearRM10MaxLimit.UNIRAC_FLASHLOC_RM), 1),
  U_ANCHOR_U2400 = round(lbsToKgs(AttachmentTypeShearRM10MaxLimit.U_ANCHOR_U2400), 1),
  OMG = round(lbsToKgs(AttachmentTypeShearRM10MaxLimit.OMG), 1),
  CUSTOM = round(lbsToKgs(AttachmentTypeShearRM10MaxLimit.CUSTOM), 1),
}

export enum AttachmentTypeShearRM5MaxLimit {
  UNIRAC_FLASHLOC_RM = 621,
  U_ANCHOR_U2400_MA = 656,
  U_ANCHOR_U2400_HW = 455,
  OMG = 358,
  CUSTOM = 1000,
}

export enum AttachmentTypeShearRM5MaxLimitKg {
  UNIRAC_FLASHLOC_RM = round(lbsToKgs(AttachmentTypeShearRM5MaxLimit.UNIRAC_FLASHLOC_RM), 1),
  U_ANCHOR_U2400_MA = round(lbsToKgs(AttachmentTypeShearRM5MaxLimit.U_ANCHOR_U2400_MA), 1),
  U_ANCHOR_U2400_HW = round(lbsToKgs(AttachmentTypeShearRM5MaxLimit.U_ANCHOR_U2400_HW), 1),
  OMG = round(lbsToKgs(AttachmentTypeShearRM5MaxLimit.OMG), 1),
  CUSTOM = round(lbsToKgs(AttachmentTypeShearRM5MaxLimit.CUSTOM), 1),
}
export enum AttachmentTypeShearRMGridflex10MaxLimitKg {
  UNIRAC_FLASHLOC_RM = round(lbsToKgs(500), 1),
  U_ANCHOR_U2400 = round(lbsToKgs(404), 1),
  OMG = round(lbsToKgs(500), 1),
  CUSTOM = round(lbsToKgs(500), 1),
}

function makeConfig(max: number = 500, defaultValue: number = shearAllowabledefaultValue, label = 'shearAllowable', tooltip = upliftShearAllowableTooltip) {
  return {
    label,
    type: 'Number',
    apiField,
    missing: undefined,
    min: 1,
    max,
    default: defaultValue,
    tooltip,
  };
}


export default makeConfig();

export const shearAllowableForOMG = (productId: number = products.rm_gridflex, inputUnit: number = 1) => {
  if (isRmGridflex10(productId)){
    if (isMetricUnit(inputUnit)){
      return makeConfig(AttachmentTypeShearRMGridflex10MaxLimitKg.OMG, AttachmentTypeShearRMGridflex10MaxLimitKg.OMG, 'shearAllowableMetric');
    } 
    return makeConfig(AttachmentTypeShearRMGridflex10MaxLimit.OMG, AttachmentTypeShearRMGridflex10MaxLimit.OMG);
  }
  if (isMetricUnit(inputUnit)){
    return makeConfig(AttachmentTypeShearRM10MaxLimitKg.OMG, AttachmentTypeShearRM10MaxLimitKg.OMG, 'shearAllowableMetric');
  } 
  return makeConfig(AttachmentTypeShearRM10MaxLimit.OMG, AttachmentTypeShearRM10MaxLimit.OMG);
}

export const shearAllowableForUAnchor = (productId: number = products.rm_gridflex, anchor_type = 1, projectVersion, inputUnit: number = 1) => {
  const isMetric = isMetricUnit(inputUnit)
  if (isRM10(productId) || isRM10Evolution(productId) || isRM5(productId) && !applyAttachmentCapacityChanges(productId, projectVersion)) {
    if (isMetric)
      return makeConfig(AttachmentTypeShearRM10MaxLimitKg.U_ANCHOR_U2400, AttachmentTypeShearRM10MaxLimitKg.U_ANCHOR_U2400, 'shearAllowableMetric');
    return makeConfig(AttachmentTypeShearRM10MaxLimit.U_ANCHOR_U2400, AttachmentTypeShearRM10MaxLimit.U_ANCHOR_U2400);
  } else if (isRM5(productId)) {
    if (anchor_type === options.mechanicalAttachment.value){
      if (isMetric)
        return makeConfig(AttachmentTypeShearRM5MaxLimitKg.U_ANCHOR_U2400_MA, AttachmentTypeShearRM5MaxLimitKg.U_ANCHOR_U2400_MA, 'shearAllowableMetric');
      return makeConfig(AttachmentTypeShearRM5MaxLimit.U_ANCHOR_U2400_MA, AttachmentTypeShearRM5MaxLimit.U_ANCHOR_U2400_MA);
    }
    else
      if (isMetric)
        return makeConfig(AttachmentTypeShearRM5MaxLimitKg.U_ANCHOR_U2400_HW, AttachmentTypeShearRM5MaxLimitKg.U_ANCHOR_U2400_HW, 'shearAllowableMetric');
      return makeConfig(AttachmentTypeShearRM5MaxLimit.U_ANCHOR_U2400_HW, AttachmentTypeShearRM5MaxLimit.U_ANCHOR_U2400_HW);
  }
  else if (isRmGridflex10(productId)){
    if (isMetricUnit(inputUnit))
      return makeConfig(AttachmentTypeShearRMGridflex10MaxLimitKg.U_ANCHOR_U2400, AttachmentTypeShearRMGridflex10MaxLimitKg.U_ANCHOR_U2400, 'shearAllowableMetric');
    return makeConfig(AttachmentTypeShearRMGridflex10MaxLimit.U_ANCHOR_U2400, AttachmentTypeShearRMGridflex10MaxLimit.U_ANCHOR_U2400);
  }
  return makeConfig(AttachmentTypeShearIFIMaxLimit.U_ANCHOR_U2400, AttachmentTypeShearIFIMaxLimit.U_ANCHOR_U2400, 'shearAllowable', upliftShearAllowableGridflexTooltip);
}

export const shearAllowableForFlashloc = (productId: number = products.rm_gridflex, inputUnit: number = 1) => {
  if (isRM10(productId) || isRM10Evolution(productId) || isRM5(productId)){
    if (isMetricUnit(inputUnit))
      return makeConfig(AttachmentTypeShearRM10MaxLimitKg.UNIRAC_FLASHLOC_RM, AttachmentTypeShearRM10MaxLimitKg.UNIRAC_FLASHLOC_RM, 'shearAllowableMetric');
    return makeConfig(AttachmentTypeShearRM10MaxLimit.UNIRAC_FLASHLOC_RM, AttachmentTypeShearRM10MaxLimit.UNIRAC_FLASHLOC_RM);
  }
  if (isRmGridflex10(productId)){
    if (isMetricUnit(inputUnit))
      return makeConfig(AttachmentTypeShearRMGridflex10MaxLimitKg.UNIRAC_FLASHLOC_RM, AttachmentTypeShearRMGridflex10MaxLimitKg.UNIRAC_FLASHLOC_RM, 'shearAllowableMetric');
    return makeConfig(AttachmentTypeShearRMGridflex10MaxLimit.UNIRAC_FLASHLOC_RM, AttachmentTypeShearRMGridflex10MaxLimit.UNIRAC_FLASHLOC_RM);
  }
  return makeConfig(AttachmentTypeShearIFIMaxLimit.UNIRAC_FLASHLOC_RM, AttachmentTypeShearIFIMaxLimit.UNIRAC_FLASHLOC_RM, 'shearAllowable', upliftShearAllowableGridflexTooltip);
}

export const shearAllowableForCustom = (productId: number = products.rm_gridflex, inputUnit: number = 1) => {
  if (isRM10(productId) || isRM10Evolution(productId) || isRM5(productId) ){
    if (isMetricUnit(inputUnit))
      return makeConfig(AttachmentTypeShearRM10MaxLimitKg.CUSTOM, shearAllowabledefaultValueKg, 'shearAllowableMetric');
    return makeConfig(AttachmentTypeShearRM10MaxLimit.CUSTOM);
  }
  if (isRmGridflex10(productId)){
    if (isMetricUnit(inputUnit))
      return makeConfig(AttachmentTypeShearRM10MaxLimitKg.CUSTOM, shearAllowabledefaultValueKg, 'shearAllowableMetric');
    return makeConfig(AttachmentTypeShearRMGridflex10MaxLimit.CUSTOM);
  }

  return makeConfig(AttachmentTypeShearIFIMaxLimit.CUSTOM, 500, 'shearAllowable', upliftShearAllowableGridflexTooltip);
}

import RmRoofTypeTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/rmRoofTypeTooltip';
import { isRMGridFlex, isRmGridflex10 } from '__common/constants/products';
const RM_ROOF_TYPE = [
  {
    value: '5',
    name: 'MINERAL CAP',
  },
  {
    value: '6',
    name: 'EPDM',
  },
  {
    value: '7',
    name: 'TPO',
  },
  {
    value: '8',
    name: 'PVC',
  },
  {
    value: '9',
    name: 'OTHER',
  },
]

const RM_IFI_ROOF_TYPE = [...RM_ROOF_TYPE].concat([
  {
    value: '13',
    name: 'FINE GRAVEL',
  },
  {
    value: '14',
    name: 'HONED CONCRETE',
  },
  {
    value: '15',
    name: 'PAINTED CONCRETE',
  },
  {
    value: '16',
    name: 'BROOMED CONCRETE',
  }
])

function makeConfig(data) {
  return {
    type: 'Select',
    label: 'roofType',
    apiField: 'roof_type',
    missing: undefined,
    tooltip: RmRoofTypeTooltip,
    data
  };
}

export default function getRMRoofType(productId: number) {
  if (isRMGridFlex(productId) || isRmGridflex10(productId))
    return makeConfig(RM_IFI_ROOF_TYPE);

  return makeConfig(RM_ROOF_TYPE);
}

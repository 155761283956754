import { EngineeringProjectDocumentsActionsConstants } from 'actionsConstants';
import { Action } from '__common/store/action';
import nprogress from 'nprogress';
import { state } from '__common/store';



export interface COMMON_REPORT_GENERATE_START_ACTION {
  projectId: string;
  productId?: string;
  unitsType: unitsType;
} 

export interface COMMON_DXF_GENERATE_START_ACTION {
  projectId?: string;
  arrayId? : any;
  roofId? : any;
  filename?: string,
  type?:string,
  resource?:string,
  pathVariables?: { [parameter: string]: string | number }, 
  layoutType?:number
}

export const nprogressStartForDxf = () => {
  const { engineeringProjectDocuments : { is_dxf_zip_generating, is_consolidated_dxf_generating, is_dxf_generating } } = state()
  if (is_dxf_zip_generating || is_consolidated_dxf_generating || is_dxf_generating) {
    nprogress.start();
  }
}

export function LAYOUT_GENERATE_START(projectId: string, unitsType: unitsType): Action<COMMON_REPORT_GENERATE_START_ACTION> {
  return {
    type: EngineeringProjectDocumentsActionsConstants.LAYOUT_GENERATE_START,
    payload: {
      projectId,
      unitsType,
    },
  };
}
export function BOM_GENERATE_START(projectId: string, unitsType: unitsType): Action<COMMON_REPORT_GENERATE_START_ACTION>  {
  return {
    type: EngineeringProjectDocumentsActionsConstants.BOM_GENERATE_START,   
    payload: {
      projectId,
      unitsType,
    },
  };
}
export function ENGINEERING_GENERATE_START(projectId: string, unitsType: unitsType): Action<COMMON_REPORT_GENERATE_START_ACTION>  {
  return {
    type: EngineeringProjectDocumentsActionsConstants.ENGINEERING_GENERATE_START,
    payload: {
      projectId,
      unitsType,
    },
  };
}
export function ENGINEERING_SUMMARY_GENERATE_START(projectId: string, unitsType: unitsType): Action<COMMON_REPORT_GENERATE_START_ACTION>  {
  return {
    type: EngineeringProjectDocumentsActionsConstants.ENGINEERING_SUMMARY_GENERATE_START,
    payload: {
      projectId,
      unitsType,
    },
  };
}
export function INTERNAL_ENGINEERING_GENERATE_START(projectId: string, unitsType: unitsType): Action<COMMON_REPORT_GENERATE_START_ACTION>  {
  return {
    type: EngineeringProjectDocumentsActionsConstants.INTERNAL_ENGINEERING_GENERATE_START,
    payload: {
      projectId,
      unitsType,
    },
  };
}

export function DETAILED_ENGINEERING_GENERATE_START(projectId: string, unitsType: unitsType): Action<COMMON_REPORT_GENERATE_START_ACTION>  {
  return {
    type: EngineeringProjectDocumentsActionsConstants.DETAILED_ENGINEERING_GENERATE_START,
    payload: {
      projectId,
      unitsType,
    },
  };
}

export function PE_STAMP_GENERATE_START(projectId: string, unitsType: unitsType): Action<COMMON_REPORT_GENERATE_START_ACTION>  {
  return {
    type: EngineeringProjectDocumentsActionsConstants.PE_STAMP_GENERATE_START,
    payload: {
      projectId,
      unitsType,
    },
  };
}

export function LAYOUT_GENERATE_SUCCESSFUL() {
  nprogressStartForDxf()
  return {
    type: EngineeringProjectDocumentsActionsConstants.LAYOUT_GENERATE_SUCCESSFUL,
  };
}
export function BOM_GENERATE_SUCCESSFUL() {
  nprogressStartForDxf()
  return {
    type: EngineeringProjectDocumentsActionsConstants.BOM_GENERATE_SUCCESSFUL,
  };
}
export function ENGINEERING_GENERATE_SUCCESSFUL() {
  nprogressStartForDxf()
  return {
    type: EngineeringProjectDocumentsActionsConstants.ENGINEERING_GENERATE_SUCCESSFUL,
  };
}

export function ENGINEERING_SUMMARY_GENERATE_SUCCESSFUL() {
  nprogressStartForDxf()
  return {
    type: EngineeringProjectDocumentsActionsConstants.ENGINEERING_SUMMARY_GENERATE_SUCCESSFUL,
  };
}

export function INTERNAL_ENGINEERING_GENERATE_SUCCESSFUL() {
  nprogressStartForDxf()
  return {
    type: EngineeringProjectDocumentsActionsConstants.INTERNAL_ENGINEERING_GENERATE_SUCCESSFUL,
  };
}

export function DETAILED_ENGINEERING_GENERATE_SUCCESSFUL() {
  nprogressStartForDxf()
  return {
    type: EngineeringProjectDocumentsActionsConstants.DETAILED_ENGINEERING_GENERATE_SUCCESSFUL,
  };
}

export function PE_STAMP_GENERATE_SUCCESSFUL() {
  nprogressStartForDxf()
  return {
    type: EngineeringProjectDocumentsActionsConstants.PE_STAMP_GENERATE_SUCCESSFUL,
  };
}

export function OPEN_BOM_REPORT(projectId: string, productId: string, unitsType: unitsType): Action<COMMON_REPORT_GENERATE_START_ACTION> {
  return {
    type: EngineeringProjectDocumentsActionsConstants.OPEN_BOM_REPORT,
    payload: {
      projectId,
      productId,
      unitsType,
    },
  };
}

export function DOWNLOAD_FULL_REPORT(projectId: string, productId: string, unitsType: unitsType): Action<COMMON_REPORT_GENERATE_START_ACTION> {
  return {
    type: EngineeringProjectDocumentsActionsConstants.DOWNLOAD_FULL_REPORT,
    payload: {
      projectId,
      productId,
      unitsType,
    },
  };
}

export function OPEN_FULL_REPORT(projectId: string, productId: string, unitsType: unitsType): Action<COMMON_REPORT_GENERATE_START_ACTION> {
  return {
    type: EngineeringProjectDocumentsActionsConstants.OPEN_FULL_REPORT,
    payload: {
      projectId,
      productId,
      unitsType,
    },
  };
}

export function PROJECT_DOCUMENTS_RESET() {
  return {
    type: EngineeringProjectDocumentsActionsConstants.PROJECT_DOCUMENTS_RESET,
  };
}

export function TOGGLE_UNITS() {
  return {
    type: EngineeringProjectDocumentsActionsConstants.TOGGLE_UNITS,
  };
}

export function SET_UNITS(units: string) {
  return {
    type: EngineeringProjectDocumentsActionsConstants.SET_UNITS,
    payload: {
      units
    }
  };
}

export function DXF_GENERATE_START(projectId: string, arrayId: number, filename:string, type:string, resource:string, pathVariables : { [parameter: string]: string | number }) {
  return {
    type: EngineeringProjectDocumentsActionsConstants.DXF_GENERATE_START,
    payload: {
      projectId,
      arrayId,
      filename,
      type,
      resource,
      pathVariables
    },
  };
}

export function LAYOUT_TYPE_DXF_GENERATE_START(projectId: string, arrayId: number, filename:string, type:string, resource:string, pathVariables : { [parameter: string]: string | number }, layoutType: number) {
  return {
    type: EngineeringProjectDocumentsActionsConstants.LAYOUT_TYPE_DXF_GENERATE_START,
    payload: {
      projectId,
      arrayId,
      filename,
      type,
      resource,
      pathVariables,
      layoutType
    },
  };
}

export function DXF_GENERATE_SUCCESSFUL(payload) {
  return {
    type: EngineeringProjectDocumentsActionsConstants.DXF_GENERATE_SUCCESSFUL,
    payload
  };
}

export function LAYOUT_TYPE_DXF_GENERATE_SUCCESSFUL(payload) {
  return {
    type: EngineeringProjectDocumentsActionsConstants.LAYOUT_TYPE_DXF_GENERATE_SUCCESSFUL,
    payload
  };
}


export function CONSOLIDATED_DXF_GENERATE_START(projectId: string, roofId: number, filename:string, type:string, resource:string, pathVariables : { [parameter: string]: string | number }) {
  return {
    type: EngineeringProjectDocumentsActionsConstants.CONSOLIDATED_DXF_GENERATE_START,
    payload: {
      projectId,
      roofId,
      filename,
      type,
      resource,
      pathVariables
    },
  };
}

export function CONSOLIDATED_DXF_GENERATE_SUCCESSFUL(payload) {
  return {
    type: EngineeringProjectDocumentsActionsConstants.CONSOLIDATED_DXF_GENERATE_SUCCESSFUL,
    payload
  };
}

export function CONSTRUCTION_DXF_GENERATE_START(projectId: string, roofId: number, filename:string, type:string, resource:string, pathVariables : { [parameter: string]: string | number }) {
  return {
    type: EngineeringProjectDocumentsActionsConstants.CONSTRUCTION_DXF_GENERATE_START,
    payload: {
      projectId,
      roofId,
      filename,
      type,
      resource,
      pathVariables
    },
  };
}

export function CONSTRUCTION_DXF_GENERATE_SUCCESSFUL(payload) {
  return {
    type: EngineeringProjectDocumentsActionsConstants.CONSTRUCTION_DXF_GENERATE_SUCCESSFUL,
    payload
  };
}

export function PLANSET_DXF_GENERATE_START(projectId: string, filename:string, type:string, resource:string) {
  return {
    type: EngineeringProjectDocumentsActionsConstants.PLANSET_DXF_GENERATE_START,
    payload: {
      projectId,
      filename,
      type,
      resource,
    },
  };
}

export function PLANSET_DXF_GENERATE_SUCCESSFUL(payload) {
  return {
    type: EngineeringProjectDocumentsActionsConstants.PLANSET_DXF_GENERATE_SUCCESSFUL,
    payload
  };
}

export function DXF_ZIP_ALL_GENERATE_START(projectId: string, filename:string, type:string, resource:string) {
  return {
    type: EngineeringProjectDocumentsActionsConstants.DXF_ZIP_ALL_GENERATE_START,
    payload: {
      projectId,
      filename,
      type,
      resource,
    },
  };
}

export function DXF_ZIP_ALL_GENERATE_SUCCESSFUL(payload) {
  return {
    type: EngineeringProjectDocumentsActionsConstants.DXF_ZIP_ALL_GENERATE_SUCCESSFUL,
    payload
  };
}



import AppHistory from '__common/modules/history';
import { AUTO_SAVE_PROJECT_FINISHED, AUTO_SAVE_PROJECT_FAILED, SAVE_PROJECT_FAILED, AUTO_SAVE_PROJECT_FAILED_ACTION, SAVE_PROJECT_FAILED_ACTION, SAVE_PROJECT_STARTED_BEFORE_AUTO_SAVE_FINISH } from './saveLoadProjectActions';
import { hitApi, ObservableAjax } from '__common/utils/api';
import { SaveLoadProjectActionTypes } from 'actionsConstants';
import { SET_CLAMP_CHECK_FAILED, SET_PROJECT_ID } from 'projectDesign/components/projectConfiguration/projectConfigurationActions';
import {
  LOAD_DATA_FROM_API,
  REDIRECT_TO_BOM,
  REDIRECT_TO_ENGINEERING,
} from 'actions';
import { ActionsObservable } from 'redux-observable';
import { Store, AnyAction } from 'redux';
import { Observable } from 'rxjs';
import { isGFT, getProductName } from '__common/constants/products';
import { of } from 'rxjs/observable/of';
import { Action } from '__common/store/action';
import { saveProject as saveProjectFun } from './saveProject';


export function saveProject(action$) {
  return action$.ofType(SaveLoadProjectActionTypes.SAVE_PROJECT)
    .debounceTime(300)
    .switchMap((action) => {
      const { data, productId, projectId } = action.payload;
      let url: string;
      let method: string;
      let errorMessage: string;
      const onSuccesAction = action.payload.withBom ? REDIRECT_TO_ENGINEERING.bind(null, productId) : REDIRECT_TO_BOM;

      if (action.payload.withBom) {
        data.generate_default_bom = true;
      }
      if (projectId.includes('IP')) {
        data.project_configuration['imported_project'] = true;
        data.project_configuration['imported_project_id'] = projectId;
      }

      if (projectId && !projectId.includes('IP')) {
        url = `api/v1/project/${projectId}/`;
        method = 'put';
        errorMessage = 'Cannot update project';
      } else {
        url = 'api/v1/project/';
        method = 'post';
        errorMessage = 'Cannot save project';
      }

      return ObservableAjax({
        takeUntil: action$.ofType(onSuccesAction),
        onSuccess: onSuccesAction,
        onError: SAVE_PROJECT_FAILED,
        onErrorMessage: errorMessage,
        socketName: 'project',
        onSocketPending: 'SAVING PROJECT WAITING IN A QUEUE...',
        onSocketStart: 'SAVING PROJECT...',
        onSocketFailure: 'SAVING PROJECT FAILURE',
        link: hitApi(method, url, data),
      });
    });
}

export function autoSave(action$) {
  return action$.ofType(SaveLoadProjectActionTypes.AUTO_SAVE_PROJECT)
    .debounceTime(300)
    .switchMap((action) => {
      const { data, projectId } = action.payload;
      let url: string;
      let method: string;
      let errorMessage: string;

      if (projectId.includes('IP')) {
        data.project_configuration['imported_project'] = true;
        data.project_configuration['imported_project_id'] = projectId;
      }


      if (projectId && !projectId.includes('IP')) {
        url = `api/v1/project/${projectId}/`;
        method = 'put';
        errorMessage = 'Cannot update project';
      } else {
        url = 'api/v1/project/';
        method = 'post';
        errorMessage = 'Cannot save project';
      }

      return ObservableAjax({
        takeUntil: action$.ofType(SaveLoadProjectActionTypes.AUTO_SAVE_PROJECT),
        onSuccess: [AUTO_SAVE_PROJECT_FINISHED],
        onErrorAction: AUTO_SAVE_PROJECT_FAILED,
        onError: AUTO_SAVE_PROJECT_FAILED,
        onErrorMessage: errorMessage,
        socketName: 'project',
        link: hitApi(method, url, data),
      });
    });
}

export function fetchProject(action$) {
  return action$.ofType(SaveLoadProjectActionTypes.FETCH_PROJECT)
    .switchMap((action) => {
      const { projectId } = action.payload;

      return ObservableAjax({
        takeUntil: action$.ofType(SaveLoadProjectActionTypes.LOAD_DATA_FROM_API),
        onSuccess: LOAD_DATA_FROM_API,
        onErrorMessage: 'Cannot load project',
        socketName: 'project',
        onSocketPending: 'LOADING PROJECT WAITING IN A QUEUE...',
        onSocketStart: 'LOADING PROJECT...',
        onSocketFailure: 'SAVING PROJECT FAILURE',
        onErrorAction: () => AppHistory.push('/projectNotFound'),
        link: hitApi('get', `api/v1/project/${projectId}/`),
      });
    });
}

export function setClampCheckFailed(action$: ActionsObservable<AnyAction>, store: Store<appState>): Observable<any> {
  return action$.ofType(SaveLoadProjectActionTypes.AUTO_SAVE_PROJECT_FAILED, SaveLoadProjectActionTypes.SAVE_PROJECT_FAILED)
    .filter((action: Action<AUTO_SAVE_PROJECT_FAILED_ACTION | SAVE_PROJECT_FAILED_ACTION>) => {
      const {
        projectConfiguration: { productId },
      } = store.getState();
      if (!action.payload) return false;
      const { clamp_check_failed, project_id } = action.payload;
      return isGFT(productId) && clamp_check_failed && !!project_id;
    })
    .switchMap((action: Action<AUTO_SAVE_PROJECT_FAILED_ACTION | SAVE_PROJECT_FAILED_ACTION>) => {
      const { project_id } = action.payload;
      store.dispatch(SET_PROJECT_ID(project_id));
      return of(SET_CLAMP_CHECK_FAILED());
    });
}

export function saveProjectStartedBeforeAutosaveFinished(action$: ActionsObservable<AnyAction>, store: Store<appState>): Observable<any> {
  return action$.ofType(SaveLoadProjectActionTypes.AUTO_SAVE_PROJECT_FINISHED, SaveLoadProjectActionTypes.AUTO_SAVE_PROJECT_FAILED)
    .filter(() => {
      const { saveLoadProject: { isSaveProjectPending } } = store.getState();
      return isSaveProjectPending;
    }).switchMap(() => {
      const { projectConfiguration: { productId, projectId } } = store.getState();
      store.dispatch(SAVE_PROJECT_STARTED_BEFORE_AUTO_SAVE_FINISH({ isSaveProjectPending: false }));
      saveProjectFun(getProductName(productId), projectId);
      return Observable.empty();
    });
}

import keyMirror from 'keymirror';

export const EngineeringProjectDocumentsActionsConstants = keyMirror({
  LAYOUT_GENERATE_START: undefined,
  BOM_GENERATE_START: undefined,
  ENGINEERING_GENERATE_START: undefined,
  ENGINEERING_SUMMARY_GENERATE_START: undefined,
  INTERNAL_ENGINEERING_GENERATE_START: undefined,
  DETAILED_ENGINEERING_GENERATE_START: undefined,
  LAYOUT_GENERATE_SUCCESSFUL: undefined,
  BOM_GENERATE_SUCCESSFUL: undefined,
  ENGINEERING_GENERATE_SUCCESSFUL: undefined,
  ENGINEERING_SUMMARY_GENERATE_SUCCESSFUL: undefined,
  INTERNAL_ENGINEERING_GENERATE_SUCCESSFUL: undefined,
  DETAILED_ENGINEERING_GENERATE_SUCCESSFUL: undefined,
  PROJECT_DOCUMENTS_RESET: undefined,
  DOWNLOAD_FULL_REPORT: undefined,
  OPEN_BOM_REPORT: undefined,
  OPEN_FULL_REPORT: undefined,
  TOGGLE_UNITS: undefined,
  SET_UNITS: undefined,
  DXF_GENERATE_START : undefined,
  DXF_GENERATE_SUCCESSFUL : undefined,
  CONSOLIDATED_DXF_GENERATE_START : undefined,
  CONSOLIDATED_DXF_GENERATE_SUCCESSFUL : undefined,
  CONSTRUCTION_DXF_GENERATE_START : undefined,
  CONSTRUCTION_DXF_GENERATE_SUCCESSFUL : undefined,
  PLANSET_DXF_GENERATE_START : undefined,
  PLANSET_DXF_GENERATE_SUCCESSFUL : undefined,
  DXF_ZIP_ALL_GENERATE_START : undefined,
  DXF_ZIP_ALL_GENERATE_SUCCESSFUL : undefined,
  LAYOUT_TYPE_DXF_GENERATE_START : undefined,
  LAYOUT_TYPE_DXF_GENERATE_SUCCESSFUL : undefined,
  PE_STAMP_GENERATE_START: undefined,
  PE_STAMP_GENERATE_SUCCESSFUL: undefined,
});

import { createReducer } from '__common/utils/helpers';
import { DrawingManagerActionTypes } from 'actionsConstants';
import { getLastRoofPitch } from '../../../panelsEditor/components/tiltedRoof/tiltedRoof';
import { getRoofName } from './drawingManagerHelper';
import { sortRoofsOnMap } from '__editor/components/roofsSelector/components/roofsSelectorClassicDesigner/roofsSelectorClassicDesigner';
import { recalculatePanelsPosition } from '__common/components/moduleSelector/utils/modelChangePanelsReposition';
import { getRoofApproximateCenter } from './utils/getRoofApproximateCenter';
import { Action } from '__common/store/action';
import { SAVE_PANELS_TO_DRAWING_MANAGER_ACTION } from './drawingManagerActions';
import RoofShape from '__editor/bingMapsRoofsSelector/models/selectedArea';
import { isAscender } from '__common/constants/products';
import { inchesToCms, lbsToKgs } from '__common/calculations/unitConversions';
import _ from 'lodash';

export const drawingManagerState: drawingManagerState = {
  selectedIds: [],
  roofs: null,
  editEnabled: false,
};

export default {
  drawingManager: createReducer(drawingManagerState, {
    [DrawingManagerActionTypes.RESET_DRAWING_MANAGER]() {
      return {
        selectedIds: [],
        roofs: null,
      };
    },
    [DrawingManagerActionTypes.CLEAR_SELECTION](state: drawingManagerState) {
      return { ...state, selectedIds: [] };
    },
    [DrawingManagerActionTypes.SET_SELECTED_ROOF_AND_MARKER](state: drawingManagerState, action: action & { payload: { roofId: number } }) {
      state.selectedIds.push(action.payload.roofId);
      return { ...state };
    },
    [DrawingManagerActionTypes.UNSET_SELECTED_ROOF_AND_MARKER](state: drawingManagerState, action: action & { payload: { roofId: number } }) {
      const selectedIds = state.selectedIds.filter(id => action.payload.roofId !== id);
      return { ...state, selectedIds };
    },
    [DrawingManagerActionTypes.UNSELECT_ALL_SELECTED_ROOF_AND_MARKER](state: drawingManagerState) {
      state.selectedIds = [];
      return { ...state };
    },
    [DrawingManagerActionTypes.DELETE_ALL_ROOFS](state: drawingManagerState) {
      return { ...state, roofs: null, selectedIds: [] };
    },
    [DrawingManagerActionTypes.ADD_ROOF](state: drawingManagerState, action: action & { payload: { coords: cordPoint[], roofEdgesPixiCords: { x: number; y: number }[], marker: cordPoint, id: number, zoom: number } }) {
      const roofs = state.roofs === null ? {} : state.roofs;
      const { coords, roofEdgesPixiCords, marker, id, zoom } = action.payload;
      const roofName = getRoofName();
      const roofPitch = getLastRoofPitch();
      roofs[id] = { coords, roofEdgesPixiCords, marker, id, zoom, roofName, roofPitch };
      return { ...state, roofs };
    },
    [DrawingManagerActionTypes.CHANGE_ROOF_ZOOM](state: drawingManagerState, action) {
      state.roofs[action.payload.roofId].zoom = action.payload.zoom;
      return { ...state };
    },
    [DrawingManagerActionTypes.DELETE_ROOF](state: drawingManagerState, action: action & { payload: { id: number } }) {
      const { id } = action.payload;
      delete state.roofs[id];
      return { ...state };
    },
    [DrawingManagerActionTypes.DELETE_PANELS_INSIDE_OF_ROOF](state: drawingManagerState, action: action & { payload: { id: number } }) {
      const { id } = action.payload;
      state.roofs[id].panels = [];
      return { ...state };
    },
    [DrawingManagerActionTypes.UPDATE_ONE_ROOF_CORNER](state: drawingManagerState, action: { payload: { roofId: number, corner: number, coords: { lat: number, lng: number } } }) {
      const { roofId, coords, corner } = action.payload;
      state.roofs[roofId].coords[corner] = coords;
      const googlePoly = new google.maps.Polygon({ paths: state.roofs[roofId].coords });
      getRoofApproximateCenter();
      const center = googlePoly.getRoofApproximateCenter();
      state.roofs[roofId].marker = center.toJSON();
      return { ...state };
    },
    [DrawingManagerActionTypes.UPDATE_ROOF_COORDS](state: drawingManagerState, action: { payload: { roofId: number, coords: { lat: number, lng: number }[] } }) {
      const { roofId, coords } = action.payload;
      const roof = RoofShape(coords, roofId);
      state.roofs[roofId].marker = roof.overlay.getBounds().getCenter();
      state.roofs[roofId].coords = coords;
      return { ...state };
    },
    [DrawingManagerActionTypes.SET_ROOF_AREA_SEEN](state: drawingManagerState, action: action & { payload: { roofId: number, seen: boolean } }) {
      const { roofId, seen } = action.payload;
      state.roofs[roofId].seen = seen;
      return { ...state };
    },
    [DrawingManagerActionTypes.SAVE_PANELS_TO_DRAWING_MANAGER](state: drawingManagerState, action: Action<SAVE_PANELS_TO_DRAWING_MANAGER_ACTION>) {
      const {
        selectedRoofId,
        panels,
        bgRotation,
        roofPitch,
        structureTilt,
        metersPerPixel,
        zoom,
        rowSpacing,
        tableRowSpacing,
        roofEdgesPixiCords,
        exposureRecaluclationHash,
        bgOffset,
        bgScale,
        blank_map_building_length,
        blank_map_building_width,
        panelsRotationDegrees,
        lowEdgeToRoof,
        panelBayMapping
      } = action.payload;

      state.roofs[selectedRoofId].panels = panels;
      state.roofs[selectedRoofId].bgRotation = bgRotation;
      state.roofs[selectedRoofId].roofPitch = roofPitch;
      state.roofs[selectedRoofId].structureTilt = structureTilt;
      state.roofs[selectedRoofId].metersPerPixel = metersPerPixel;
      state.roofs[selectedRoofId].zoom = zoom;
      state.roofs[selectedRoofId].bgOffset = bgOffset;
      state.roofs[selectedRoofId].bgScale = bgScale;
      state.roofs[selectedRoofId].roofEdgesPixiCords = roofEdgesPixiCords;
      state.roofs[selectedRoofId].blank_map_building_length = blank_map_building_length ?? 0;
      state.roofs[selectedRoofId].blank_map_building_width = blank_map_building_width ?? 0;

      if (exposureRecaluclationHash) {
        state.roofs[selectedRoofId].exposureRecaluclationHash = exposureRecaluclationHash;
      }

      if (rowSpacing) {
        state.roofs[selectedRoofId].rowSpacing = rowSpacing;
      }

      if (tableRowSpacing) {
        state.roofs[selectedRoofId].tableRowSpacing = tableRowSpacing;
      }

      if(panelsRotationDegrees !== null) {
        state.roofs[selectedRoofId].panelsRotationDegrees = panelsRotationDegrees;
      }

      if(lowEdgeToRoof !== null) {
        state.roofs[selectedRoofId].lowEdgeToRoof = lowEdgeToRoof;
      }

      if (panelBayMapping){
        state.roofs[selectedRoofId].panelBayMapping = panelBayMapping
      }
      return { ...state };
    },
    [DrawingManagerActionTypes.LOAD_ROOFS_FROM_API](state: drawingManagerState, action) {
      return { ...state, ...action.payload };
    },
    [DrawingManagerActionTypes.SET_ARRAY_NAME](state, action) {
      const { roofName, arrayId } = action.payload;
      const roofs = state.roofs;
      roofs[arrayId].roofName = roofName;
      return { ...state, roofs };
    },
    [DrawingManagerActionTypes.SORT_ROOFS_ON_MAP](state) {
      const roofs = sortRoofsOnMap(state.roofs);

      return { ...state, roofs };
    },
    [DrawingManagerActionTypes.TURN_ON_EDIT](state) {
      return { ...state, editEnabled: true };
    },
    [DrawingManagerActionTypes.TURN_OFF_EDIT](state) {
      return { ...state, editEnabled: false };
    },
    [DrawingManagerActionTypes.SET_ROOF_ROW_SPACING](state, action) {
      const { roofId, rowSpacing } = action.payload;

      state.roofs[roofId].rowSpacing = rowSpacing;

      return { ...state };
    },
    [DrawingManagerActionTypes.SET_ROOF_TABLE_ROW_SPACING](state, action) {
      const { roofId, tableRowSpacing } = action.payload;

      state.roofs[roofId].tableRowSpacing = tableRowSpacing;

      return { ...state };
    },
    [DrawingManagerActionTypes.ADD_POINT_TO_ROOF](state: drawingManagerState, action) {
      const { index, position, roofId } = action.payload;

      state.roofs[roofId].coords.splice(index, 0, position);

      return { ...state };
    },
    [DrawingManagerActionTypes.SET_ROOF_ZOOM](state: drawingManagerState, action) {
      const { zoom, roofId } = action.payload;

      state.roofs[roofId].zoom = zoom;

      return { ...state };
    },
    [DrawingManagerActionTypes.SET_ROOF_ROOF_PITCH](state: drawingManagerState, action) {
      const { roofPitch, roofId } = action.payload;

      state.roofs[roofId].roofPitch = roofPitch;

      return { ...state };
    },
    [DrawingManagerActionTypes.RESIZE_PANELS](state: drawingManagerState, action) {
      const { oldModel, newModel, productId } = action.payload;
      let modelDataClone = _.cloneDeep(newModel);
      if(isAscender(productId)) {
        modelDataClone.thickness = Number(inchesToCms(newModel.thickness).toFixed(2));
        modelDataClone.width = Number(inchesToCms(newModel.width).toFixed(2));
        modelDataClone.height = Number(inchesToCms(newModel.height).toFixed(2));
        modelDataClone.weight = Number(lbsToKgs(newModel.weight).toFixed(2));
      }        
      const { roofs } = state;
      if (roofs && Object.keys(roofs).length) {
        const recaluclatedRoofs = Object.keys(roofs).reduce((acc, roofId) => {
          if (roofs[roofId] && roofs[roofId].panels && roofs[roofId].panels.length) {
            roofs[roofId].panels = recalculatePanelsPosition(roofs[roofId].panels, oldModel, modelDataClone, roofs[roofId].metersPerPixel, roofs[roofId].rowSpacing, productId);
          }

          acc[roofId] = roofs[roofId];

          return acc;
        }, {});

        return { ...state, roofs: recaluclatedRoofs };
      }

      return state;
    },
    [DrawingManagerActionTypes.REPLACE_PANELS_ON_ROOF](state: drawingManagerState, action) {
      const { panels, roofId } = action.payload;

      state.roofs[roofId].panels = panels;

      return { ...state };
    },
    [DrawingManagerActionTypes.REMOVE_PANELS_ON_ROOF](state: drawingManagerState, action) {
      const { panelIds, roofId } = action.payload;
      if( state.roofs[roofId].panels && state.roofs[roofId].panels.length > 0) {
        const updatedPanels = state.roofs[roofId].panels.filter(
          panel => !panelIds.includes(panel.id),
        );    
        state.roofs[roofId].panels = updatedPanels;
        return { ...state };
      }
    },
    [DrawingManagerActionTypes.UPDATE_BLANK_MAP_ROOF_LENGTH_WIDTH_FOR_BLANK_ROOF](state: drawingManagerState, action) {
      const { roofId, blank_map_building_length, blank_map_building_width } = action.payload;

      state.roofs[roofId].blank_map_building_length = blank_map_building_length;
      state.roofs[roofId].blank_map_building_width = blank_map_building_width;

      return { ...state };
    },
    [DrawingManagerActionTypes.SET_USER_CLICKED_YES_OR_NO](state: drawingManagerState, action: action & { payload: { roofId: number, isConfirmedYesorNo: boolean | null } }) {
      const { roofId, isConfirmedYesorNo } = action.payload;
      state.roofs[roofId].isConfirmedYesorNo = isConfirmedYesorNo;
      return { ...state };
    },
    [DrawingManagerActionTypes.SET_PANELS_IN_OVERFLOWING_STATE](state: drawingManagerState, action: action & { payload: { roofId: number, check: boolean | null, numberOfOverflowedPanels: number } }) {
      const { roofId, check, numberOfOverflowedPanels } = action.payload;
      state.roofs[roofId].overflowingSetbackLine = {check, numberOfOverflowedPanels};
      return { ...state };
    },
  }),
};

import * as React from 'react';
import ConfirmationSelectField from '__common/components/ConfirmationSelectField';
import roofPitchTooltip from './projectConfiguration/fieldsTooltips/roofPitchTooltip';
import Tooltip from '__common/components/Tooltip';
import { connect } from 'react-redux';
import { RESET_PANELS_STATE, SET_TILTED_ROOF } from 'actions';
import SelectField from 'react-md/lib/SelectFields/SelectField';
import { isFlatRoof, isGableRoof, isHipRoof } from './projectConfiguration/projectConfiguration';
import { isRMIFIProduct, isRMGridFlex, isEcoFoot2Plus, isAscender, isRM5, isRM10orRM10Evo, isRmGridflex10, isSMTilt, isNxtTilt } from '__common/constants/products';
import { isASCE710, isASCE716or722 } from '__common/constants/buildingCodes';
import { AttachmentType } from './projectConfiguration/fields/attachmentType';
import { adjustableTiltSystem } from '__common/utils/versionCompare/versionCompare';

type Props = {
  productId: number,
  dispatch: Function;
  panels: panelsState;
  tiltedRoof: tiltedRoofState;
  background: backgroundState,
  projectEnvConfig: projectEnvConfig;
  seismicDesignCategory: string;
  location: string;
  projectVersion: string;
};

class RoofPitchSelector extends React.Component<Props, {}> {
  roofPitches = [
    '---',
    '1/12',
    '2/12',
    '3/12',
    '4/12',
    '5/12',
    '6/12',
    '7/12',
    '8/12',
    '9/12',
    '10/12',
    '11/12',
    '12/12',
  ];

  roofPitchesIFI = [
    '---',
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
  ];

  roofPitchesIFILimitedForUNIRAC_FLASHLOC_RM = [ // if UNIRAC_FLASHLOC_RM is selected, then only these roof pitches are available for selection in the roof pitch selector dropdown list 
    '---',
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
  ];

  roofPitchesIFILimited = [
    '---',
    '0',
    '1',
    '2',
    '3',
  ];

  asce716RoofPitch = [
    '---',
    '2/12',
    '3/12',
    '4/12',
    '5/12',
    '6/12',
    '7/12',
    '8/12',
    '9/12',
    '10/12',
    '11/12',
    '12/12',
  ];

  getRoofPitches = () => {
    const { projectEnvConfig: { building_type, building_code, attachment_type, allow_mechanical_attachments }, productId, location, projectVersion } = this.props;

    if (isRMIFIProduct(productId)) {
      let choices = (attachment_type === AttachmentType.UNIRAC_FLASHLOC_RM && allow_mechanical_attachments) ? this.roofPitchesIFILimitedForUNIRAC_FLASHLOC_RM : this.roofPitchesIFI;

      if (isASCE710(building_code) && location.split(',')[1] && location.split(',')[1].trim() === 'CA') {
        choices = this.roofPitchesIFILimited;
      }
      return choices;
    }

    if((isSMTilt(productId) && adjustableTiltSystem(projectVersion))|| isNxtTilt(productId)) return this.roofPitchesIFI;

    if(isRM5(productId) || isRmGridflex10(productId) || isRM10orRM10Evo(productId)){
      return (attachment_type === AttachmentType.UNIRAC_FLASHLOC_RM && this.props.projectEnvConfig.allow_mechanical_attachments) ? this.roofPitchesIFILimitedForUNIRAC_FLASHLOC_RM : this.roofPitchesIFI;
    }

    if(isEcoFoot2Plus(productId)) {
      return this.roofPitchesIFILimited;
    }

    if (!isASCE716or722(building_code) || isAscender(productId)) {
      return this.roofPitches;
    }
    
    if (isFlatRoof(building_type)) {
      return [];
    }

    if (isGableRoof(building_type)) {
      return this.asce716RoofPitch;
    }

    if (isHipRoof(building_type)) {
      return this.asce716RoofPitch;
    }
  }

  onRoofPitchChange = (roofPitch: string) => {
    const { dispatch, productId } = this.props;
    !(isRM5(productId)  || isRmGridflex10(productId) || isRM10orRM10Evo(productId)) && dispatch(RESET_PANELS_STATE());
    dispatch(SET_TILTED_ROOF(roofPitch));
  }

  warningMessage = (productId) => {
    return (
      <div className="input-warning lack-of-roof-pitch">
        You should set roof {isRMGridFlex(productId) ? 'slope' : 'pitch'}
      </div>
    );
  }

  getLabel = (productId) => {

    if (isRMGridFlex(productId) || isRmGridflex10(productId) || isEcoFoot2Plus(productId) || isRM5(productId) || isRM10orRM10Evo(productId)) {
      return <div className="input-label">Select roof slope (degrees) </div>;
    }

    return <div className="input-label">Select roof pitch <Tooltip id="roof-pitch-selector" content={roofPitchTooltip()} position="bottom" /></div>;
  }

  getTitle = productId => isRMGridFlex(productId) || isRmGridflex10(productId) || isEcoFoot2Plus(productId) || isRM5(productId) || isRM10orRM10Evo(productId) ? 'Are you sure you want to change the roof slope?' : 'Are you sure you want to change the roof pitch?'

  roofPitchSelect(roofPitches: string[], label) {
    const {
      panels: { panels },
      productId,
    } = this.props;
    const {
      tiltedRoof: { roofPitch },
      background: { bgLoaded },
    } = this.props;
    return (
      <>
        {roofPitch === '---' && this.warningMessage(productId)}
        <ConfirmationSelectField
          value={roofPitch}
          items={roofPitches}
          onConfirm={this.onRoofPitchChange}
          title={this.getTitle(productId)}
          content="Current layout will be lost."
          shouldConfirm={!(isRM5(productId) || isRmGridflex10(productId) || isRM10orRM10Evo(productId)) && panels.length > 0}
          label={label}
          selectFieldProps={{
            fullWidth: true,
            id: 'roofPitch',
            name: 'select-field',
            position: SelectField.Positions.BELOW,
            listZDepth: 0,
            minBottom: 100,
            disabled: !bgLoaded,
          }}
        />
      </>
    );
  }

  render() {
    const { productId } = this.props;

    const roofPitches = this.getRoofPitches();

    const label = this.getLabel(productId);

    if (roofPitches.length === 0) {
      return null;
    }

    return this.roofPitchSelect(roofPitches, label);
  }
}

function mapStateToProps(state: appState) {
  return {
    panels: state.panels,
    tiltedRoof: state.tiltedRoof,
    background: state.background,
    projectEnvConfig: state.projectConfiguration.projectEnvConfig,
    seismicDesignCategory: state.projectConfiguration.seismicDesignCategory,
    location: state.projectConfiguration.area,
    projectVersion : state.projectConfiguration.projectVersion
  };
}

export default connect(mapStateToProps)(RoofPitchSelector);

import config from 'config';
import nprogress from 'nprogress';
import Rx, { Observable } from 'rxjs';
import { dispatch, state } from '../store/index';
import { SET_APP_ERROR } from 'actionsConstants';
import { SET_INVALID_PROJECT, SET_LOADER_MSG } from 'app/appActions';
import {
  AJAX_ERROR,
  MULTI_ACTIONS_DISPATCH_DONE,
  WAIT_FOR_SOCKET,
} from 'actions';
import { showErrorAlert } from '__common/modules/alerts';
import { SET_PROJECT_ID_ON_AUTOSAVE } from 'projectDesign/components/projectConfiguration/projectConfigurationActions';

export function getToken(
  resource: string,
  body: {
    username?: string;
    password?: string;
    token?: string;
    withCredentials?: boolean;
  },
) {
  return {
    url: `${location.protocol}//${config.apiUrl}${resource}`,
    body,
    method: 'POST',
  };
}

export function hitApi(
  method: string,
  resource: string,
  body?: any,
  additioanlRequestsOpts?: object,
  oldUb?: boolean,
) {
  const token = state().user.token;
  const requestHeaders: {
    Accept: string;
    'Content-Type': string;
    Authorization?: string;
  } = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (token) {
    requestHeaders.Authorization = `Token ${token}`;
  }

  const apiUrl = oldUb ? config.apiUrlUb1 : config.apiUrl;

  const request = {
    url: `${location.protocol}//${apiUrl}${resource}`,
    headers: requestHeaders,
    body,
    method,
    ...additioanlRequestsOpts,
  };
  return request;
}

export function hitWebsockets(resource: string) {
  const wsProtocol = location.protocol === 'https:' ? 'wss' : 'ws';
  return `${wsProtocol}://${config.apiUrl}${resource}`;
}

export function ObservableAjax(cfg: {
  link: apiData;
  socketName?: string;
  onSocketStart?: string;
  onSocketPending?: string;
  onSocketFailure?: string;
  takeUntil?: any;
  onError?: Function;
  onSuccess: any;
  onErrorMessage?: string;
  onErrorAction?: Function;
  nonReduxExtraAction?: Function;
  showBackendErrorMsgOnFailure? : boolean;
}) {
  const {
    link,
    socketName,
    takeUntil,
    onError,
    onSuccess,
    onErrorMessage,
    onErrorAction,
    nonReduxExtraAction,
    showBackendErrorMsgOnFailure,
  } = cfg;
  return Observable.ajax(link)
    .map(data => {
      if (data.status === 204) {
        return { status: 'SUCCESS' };
      }

      return data.response;
    })
    .map(tasks => {
      if (tasks.status === 'SUCCESS') {
        if (tasks.hasOwnProperty('error_msg')) {
          dispatch(SET_INVALID_PROJECT(true))
        }
      }
      if (tasks.status === 'STARTED' || tasks.status === 'PENDING') {
        nprogress.start();
        if (!socketName || socketName.length === 0) {
          console.log('socket is going to open but socket name not provided');
          return;
        }

        setLoaderMsg(tasks, cfg);
        const socket = Rx.Observable.webSocket(
          hitWebsockets(`${socketName}/task/${tasks.task_id}/`),
        );
        socket.subscribe((data: any | any[]) => {
          if (data.status === 'SUCCESS') {
            dispatch(SET_INVALID_PROJECT(false))
            if (data?.result?.pk) {
              dispatch(SET_PROJECT_ID_ON_AUTOSAVE(data?.result?.pk))
            }
            nprogress.done();
            unsubscribeSocket(socket);
            if (Array.isArray(onSuccess)) {
              onSuccess.map((action, index) => {
                if (index === 0) {
                  return dispatch(onSuccess[0](data.result));
                }
                dispatch(action(data.result));
              });
            } else {
              dispatch(onSuccess(data.result));
            }
          } else if (data.status === 'FAILURE' && onError) {
            nprogress.done();
            dispatch(onError(data.result));
          }
          setLoaderMsg(data, cfg);
        }, 
                         (error) => { console.log(error); dispatch(onError(error)); setLoaderMsg({ status: 'FAILURE' }, cfg); },
        );
        cancelSocketOn(socket, takeUntil);
        socket.takeUntil(takeUntil);
        return WAIT_FOR_SOCKET();
      }
      if (Array.isArray(onSuccess)) {
        if (nonReduxExtraAction) {
          nonReduxExtraAction(tasks.result);
        }
        onSuccess.map((action, index) => {
          if (index === 0) {
            dispatch(action(tasks.result || tasks));
            if (nonReduxExtraAction) {
              nonReduxExtraAction(tasks.result);
            }

            return;
          }
          dispatch(action(tasks.result || tasks));

          if (nonReduxExtraAction) {
            nonReduxExtraAction(tasks.result);
          }

          return;
        });
        return MULTI_ACTIONS_DISPATCH_DONE();
      }

      if (nonReduxExtraAction) {
        nonReduxExtraAction(tasks.result);
      }
      return onSuccess(tasks.result || tasks);
    })
    .catch(ajaxError => {
      if (ajaxError.response !==null && typeof(ajaxError.response) === 'object' && ajaxError.response['invalid_project']) {
        let projectId = ajaxError.response['project_id']
        dispatch(SET_PROJECT_ID_ON_AUTOSAVE(projectId))
        dispatch(SET_INVALID_PROJECT(true))
      }
      if (onErrorMessage) {
        let errorString = `Error: ${onErrorMessage}`;
        if (ajaxError.response['error_message'] !== undefined && ajaxError.response['error_message']){
          showErrorAlert(`Error: ${ajaxError.response['error_message']}`);
          errorString = `Error: ${ajaxError.response['error_message']}`;
          }
        else{
          showErrorAlert(onErrorMessage);
        }
        if (showBackendErrorMsgOnFailure) {
          const _ajaxError = ajaxError?.response?.result?.[0];
          if (_ajaxError) errorString += `, ${_ajaxError}`;
        }

        setLoaderMsg({ status: 'FAILURE' }, { onSocketFailure: errorString });
      }

      if (onErrorAction) {
        onErrorAction(ajaxError.response);
      }

      console.log('Message error', ajaxError.message);

      if (ajaxError.status !== 0 && !ajaxError.request.url.includes('api/v1/get_environmental_factors/') && ajaxError.status >= 500) {
        dispatch(SET_APP_ERROR(JSON.stringify(ajaxError, undefined, 4)));
      }
    
      if (onError) {
        return Observable.of(onError(ajaxError));
      }
      return Observable.of(AJAX_ERROR());
    });
}

function unsubscribeSocket(socket) {
  try {
    socket.unsubscribe();
  } catch { 
    console.log('socket already unsubscribed');
  }
}

function cancelSocketOn(socket, action) {
  try {
    socket.takeUntil(action);
  } catch {
    console.log('take until');
  }
 
}

function setLoaderMsg(tasks, cfg) {
  const {
    onSocketStart,
    onSocketPending,
    onSocketFailure,
  } = cfg;
  if (tasks.status === 'STARTED') {
    dispatch(SET_LOADER_MSG(onSocketStart));
  }

  if (tasks.status === 'PENDING') {
    dispatch(SET_LOADER_MSG(onSocketPending));
  }

  if (tasks.status === 'FAILURE') {
    dispatch(SET_LOADER_MSG(tasks.result || onSocketFailure));
  }
}

import _  from 'lodash';
import { state } from '__common/store';
import { applyAdd185InchRail, greaterThanEqualToProjectVersion, removeGFT166Rail } from '__common/utils/versionCompare/versionCompare';
import { VERSION_MAP } from '__common/utils/versionCompare/version_info';
export const railsSet1 = [{
  length: 132,
  selected: false,
}, {
  length: 168,
  selected: true,
}, {
  length: 208,
  selected: false,
}, {
  length: 240,
  selected: true,
}];

export const railsSet2 = [{
  length: 132,
  selected: false,
}, {
  length: 168,
  selected: true,
},{
  length: 185,
  selected: true,
}, {
  length: 208,
  selected: false,
}, {
  length: 246,
  selected: true,
}];

const smLtRails = [{
  length: 168,
  selected: true,
},{
  length: 208,
  selected: false,
},{
  length: 246,
  selected: true,
}];

const oneRails = [{
  length: 192,
  selected: true,
}];

const smHdRails = [{
  length: 144,
  selected: false,
}, {
  length: 168,
  selected: true,
}, {
  length: 204,
  selected: false,
}, {
  length: 246,
  selected: true,
}];
const nxtRails = (projectVersion: string) => {
  return greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP['nxt_84_inch_rail']) ? [
    {
      length: 84,
      selected: false
    },{
    length: 168,
    selected: true,
  }]: [
    {
    length: 168,
    selected: true,
  }];
}

const ascenderRails = (projectVersion: string) => {
  return applyAdd185InchRail(projectVersion) ? [{length: 185, selected: true,}] : [{length: 181, selected: true,}];
  }

const smStandardRails = (projectVersion: string) => {  
  const{projectConfiguration:{railsProductId}} = state();
  return applyAdd185InchRail(projectVersion) && [9,30].includes(railsProductId) ? [{
    length: 132,
    selected: false,
  }, {
    length: 168,
    selected: false,
  },{
    length: 185,
    selected: true,
  }, {
    length: 208,
    selected: false,
  }, {
    length: 246,
    selected: false,
  }]: [{
    length: 132,
    selected: false,
  }, {
    length: 168,
    selected: true,
  },{
    length: 185,
    selected: false,
  }, {
    length: 208,
    selected: false,
  }, {
    length: 246,
    selected: true,
  }];
}

const gftRails = (projectVersion: string) => {
  return [
   ...(removeGFT166Rail(projectVersion) ? [{length: 168 ,selected: true}] : [{length: 166 ,selected: true}] ),
   {
    length: 246,
    selected: true,
  }];
}
const ulaRails = [];

export function getDefaultRailsList(productId: number, railFinish: string) {
  const {projectConfiguration:{projectVersion}} = state()
  switch (productId) {
    case 2:
      return  _.cloneDeep(smHdRails);
    case 99:
    case 30:
    case 9:
      return _.cloneDeep(smStandardRails(projectVersion));
    case 11:
    case 31:
      return  _.cloneDeep(smLtRails);
    case 5:
      return _.cloneDeep(oneRails);
    case 19:
      return _.cloneDeep(gftRails(projectVersion));
    case 20:
      return _.cloneDeep(ulaRails);
    case 25:
    case 34:
      return _.cloneDeep(nxtRails(projectVersion));
    case 29:
    case 32:
      return _.cloneDeep(ascenderRails(projectVersion))
    default:
      return _.cloneDeep(railsSet2);
  }
}


import HeaderMiddleBar from 'app/components/HeaderComponent/HeaderMiddleBar';
import ModulesInfo from './modulesInfo/ModulesInfo';
import React from 'react';
import TotalModulesInfo from './totalModulesInfo/TotalModulesInfo';
import { panelsEditorEnabled } from '__editor/panelsEditor/panelsEditorHelper';

type Props = {
  projectId?: string,
};

export default class ProjectInfoHeaderMiddleBar extends React.Component<Props> {

  renderRightSiteInfoBar() {
    const { projectId } = this.props;

    return (
      <div className="project-info">
        {projectId && <div className="project-id">
          <div className="label">#ID</div>
          <div className="value">{projectId}, </div>
        </div>}
        <div className="modules">
          {panelsEditorEnabled() ? <ModulesInfo /> : <TotalModulesInfo />}
        </div>
      </div>);
  }

  render() {
    const { projectId } = this.props;
    return (<HeaderMiddleBar projectId={projectId} rightSiteComponent={this.renderRightSiteInfoBar()} />);
  }
}




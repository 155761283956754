import { BackgroundActionTypes } from 'actionsConstants';
import { createReducer } from '__common/utils/helpers';
import { Action } from '__common/store/action';
import { UPDATE_ROOF_EDGES_ACTION, ACTIVE_PANELS_EDITOR_ACTION, SET_BACKGROUND_XY_POS_ACTION } from './backgroundActions';

export const backgroundState: backgroundState = {
  panelEditorActive: false,
  selectedRoofId: null,
  roofEdges: [],
  roofEdgesPixiCords: [],
  cords: null,
  bgXY: { x: 0, y: 0 },
  bgScale: { x: 0, y: 0 },
  backgroundType: 'google',
  bgLoaded: false,
  rotationDegrees: 0,
  panelsRotationDegrees: 0,
  bgResized: true,
  metersPerPixel: 0,
  zoom: 18,
  moveMapMode: false,
  moveArrayMode: false,
  blank_map_building_width: 0,
  blank_map_building_length: 0,
  toggleAttachments: false,
  toggleBaysContainer: false,
  showRoofDimensions: false,
  lowEdgeToRoof: 0,
};

export default {
  background: createReducer(backgroundState, {
    [BackgroundActionTypes.BACKGROUND_SET_ROTATION](state: backgroundState, action) {
      return { ...state, rotationDegrees: action.payload.degrees };
    },
    [BackgroundActionTypes.PANELS_SET_ROTATION](state: backgroundState, action) {
      return { ...state, panelsRotationDegrees: action.payload.degrees };
    },
    [BackgroundActionTypes.BACKGROUND_SET_LOW_EDGE_TO_ROOF](state: backgroundState, action) {
      return { ...state, lowEdgeToRoof: action.payload.lowEdgeToRoof };
    },
    [BackgroundActionTypes.BACKGROUND_CHANGE](state: backgroundState, action) {
      return { ...state, backgroundType: action.payload.background };
    },
    [BackgroundActionTypes.UPDATE_ROOF_EDGES](state: backgroundState, action: Action<UPDATE_ROOF_EDGES_ACTION>) {
      return { ...state, roofEdges: action.payload.roofEdges };
    },
    [BackgroundActionTypes.RESIZE_BACKGROUND](state: backgroundState, action) {
      const shouldResize = action.payload.shouldResize;
      let modifySelectors;
      if (shouldResize) {
        modifySelectors = {
          bluredBackground: false,
          bluredBackgroundRotation: false,
        };
      } else {
        modifySelectors = {
          bluredBackground: true,
          bluredBackgroundRotation: true,
        };
      }
      return { ...state, bgResized: action.payload.shouldResize, ...modifySelectors };
    },
    [BackgroundActionTypes.BACKGROUND_ZOOM_CHANGE](state: backgroundState, action) {
      return { ...state, zoom: action.payload.zoom };
    },
    [BackgroundActionTypes.TURN_ON_BLURED_BACKGROUND](state: backgroundState, action) {
      const shouldBluredBackground = action.payload.bluredBackground;
      let modifySelectors;
      if (shouldBluredBackground && state.bgResized) {
        modifySelectors = {
          bgResized: false,
        };
      } else {
        modifySelectors = {};
      }

      if (!shouldBluredBackground && !state.bgResized) {
        modifySelectors.bgResized = true;
      }
      return { ...state, bluredBackground: shouldBluredBackground, ...modifySelectors };
    },
    [BackgroundActionTypes.TURN_ON_BLURED_BACKGROUND_ROTATION](state: backgroundState, action) {
      const shouldBluredBackgroundRotation = action.payload.bluredBackgroundRotation;
      let modifySelectors;
      if (shouldBluredBackgroundRotation && state.bgResized) {
        modifySelectors = {
          bgResized: false,
        };
      } else {
        modifySelectors = {};
      }

      return { ...state, bluredBackgroundRotation: shouldBluredBackgroundRotation, ...modifySelectors };
    },
    [BackgroundActionTypes.SET_METERS_PER_PIXEL](state: backgroundState, action) {
      return { ...state, metersPerPixel: action.payload.metersPerPixel };
    },
    [BackgroundActionTypes.ACTIVE_PANELS_EDITOR](state: backgroundState, action: Action<ACTIVE_PANELS_EDITOR_ACTION>) {
      const { cords, roofEdges, selectedRoofId, roofEdgesPixiCords, blank_map_building_length, blank_map_building_width, panelsRotationDegrees, lowEdgeToRoof} = action.payload;
      return { ...state, cords, roofEdges, selectedRoofId, roofEdgesPixiCords, blank_map_building_length: blank_map_building_length?? 0, blank_map_building_width: blank_map_building_width?? 0, panelsRotationDegrees: panelsRotationDegrees?? 0, lowEdgeToRoof: lowEdgeToRoof?? 50, panelEditorActive: true };
    },
    [BackgroundActionTypes.DEACTIVE_PANELS_EDITOR](state: backgroundState) {
      return { ...state, cords: null, roofEdges: null, selectedRoofId: null, panelsRotationDegrees: 0, panelEditorActive: false };
    },
    [BackgroundActionTypes.SET_BACKGROUND_XY_POS](state: backgroundState, action: Action<SET_BACKGROUND_XY_POS_ACTION>) {
      const { bgScale, bgXY } = action.payload;
      return {
        ...state,
        bgXY,
        bgScale,
      };
    },
    [BackgroundActionTypes.SAVE_ROOF_EDGES_POINTS](state: backgroundState, action) {
      const { roofEdgesPixiCords } = action.payload;
      return { ...state, roofEdgesPixiCords };
    },
    [BackgroundActionTypes.CLEAR_BACKGROUND_REDUCER]() {
      return {
        selectedRoofId: null,
        roofEdges: [],
        roofEdgesPixiCords: [],
        cords: null,
        bgXY: { x: 0, y: 0 },
        backgroundType: 'google',
        rotationDegrees: 0,
        panelsRotationDegrees: 0,
        bgResized: true,
        metersPerPixel: 0,
        zoom: 18,
        blank_map_building_length: 0,
        blank_map_building_width: 0,
      };
    },
    [BackgroundActionTypes.BG_LOADED](state) {
      return { ...state, bgLoaded: true };
    },
    [BackgroundActionTypes.BG_CLEAR_LOADED](state) {
      return { ...state, bgLoaded: false };
    },
    [BackgroundActionTypes.MOVE_MAP_MODE_ENABLE](state) {
      document.body.style.cursor = 'grab';
      return { ...state, moveMapMode: true };
    },
    [BackgroundActionTypes.MOVE_MAP_MODE_DISABLE](state) {
      document.body.style.cursor = 'default';
      return { ...state, moveMapMode: false };
    },
    [BackgroundActionTypes.TOGGLE_ATTACHMENTS_ENABLE](state) {
      return { ...state, toggleAttachments: true };
    },
    [BackgroundActionTypes.ENABLE_BAYS_CONTAINER](state) {
      return { ...state, toggleBaysContainer: true };
    },
    [BackgroundActionTypes.DISABLE_BAYS_CONTAINER](state) {
      return { ...state, toggleBaysContainer: false };
    },
    [BackgroundActionTypes.TOGGLE_ATTACHMENTS_DISABLE](state) {
      return { ...state, toggleAttachments: false };
    },
    [BackgroundActionTypes.TOGGLE_ROOF_DIMENSIONS](state) {
      return { ...state, showRoofDimensions: !state.showRoofDimensions };
    },
    [BackgroundActionTypes.BLANK_MAP_BUILDING_LENGTH_WIDTH](state: backgroundState, action) {
      return { ...state, ...action.payload };
    },
    [BackgroundActionTypes.MOVE_ARRAY_MODE_ENABLE](state) {
      return { ...state, moveArrayMode: true };
    },
    [BackgroundActionTypes.MOVE_ARRAY_MODE_DISABLE](state) {
      return { ...state, moveArrayMode: false };
    },
  }),
};

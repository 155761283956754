import React from 'react';
import SVG from 'react-inlinesvg';
import {
  OPEN_DRAWER_PAGE,
  SET_BLANK_MAPS_ROOFS_SELECTOR,
  SET_IMAGE_ROOFS_SELECTOR,
  SET_MAP_STATE,
  SET_DEFAULT_MAP_ROOFS_SELECTOR,
} from 'actions';
import { resetZipCode } from '../../../../bingMapsRoofsSelector/components/bingMapsRoofsSelectorMap/bingMapsRoofsSelectorMap';
import { designOnMap, blankPage } from '__common/utils/svgPreload';
import { connect } from 'react-redux';
import { MODULE_SELECTION_PAGE } from 'projectDesign/components/projectConfiguration/utils/projectConfigurationDrawerPages';
import { Button } from 'react-md';

const MAP = 1;
const BLANK = 2;
const IMAGE = 3;

type Props = {
  dispatch: Function,
  openedDrawerPage: boolean,
  windSpecial: boolean,
};

type State = {
  onDesignLoaded: boolean,
  blankDesignLoaded: boolean,
  // importImageLoaded: boolean,
  selected: boolean,
};

class RoofsSelectorMap extends React.Component<Props, State> {
  designOnMapImg;
  blankDesignImg;
  // importImageImg;

  state = {
    onDesignLoaded: false,
    blankDesignLoaded: false,
    // importImageLoaded: false,
    selected: false,
  };

  delay: 2000;

  UNSAFE_componentWillMount() {
    this.designOnMapImg = <SVG onLoad={() => this.setState({ onDesignLoaded: true })} src={designOnMap} />;
    this.blankDesignImg = <SVG onLoad={() => this.setState({ blankDesignLoaded: true })} src={blankPage} />;
    // this.importImageImg = <SVG onLoad={() => this.setState({ importImageLoaded: true })} src={importImage} />;
  }

  openDrawer() {
    const { dispatch } = this.props;
    dispatch(OPEN_DRAWER_PAGE('module-selection'));
  }

  setMap = (map: number) => {
    const { dispatch, openedDrawerPage } = this.props;
    const { selected } = this.state;

    if (selected) {
      return;
    }

    this.setState({ selected: true });
    if (!openedDrawerPage) {
      dispatch(OPEN_DRAWER_PAGE(MODULE_SELECTION_PAGE));
    }

    setTimeout(() => {
      switch (map) {
        case MAP:
          return this._setGoogle();
        case BLANK:
          return this._setWhite();
        case IMAGE:
          return this._setImage();
      }
    }, 500);
  }

  _setGoogle = () => {
    const { dispatch } = this.props;
    dispatch(SET_DEFAULT_MAP_ROOFS_SELECTOR());
    dispatch(SET_MAP_STATE(true));
  }

  _setWhite = () => {
    const { dispatch } = this.props;
    dispatch(SET_BLANK_MAPS_ROOFS_SELECTOR());
    dispatch(SET_MAP_STATE(true));
    resetZipCode();
  }

  _setImage = () => {
    const { dispatch } = this.props;
    dispatch(SET_IMAGE_ROOFS_SELECTOR('https://trello-attachments.s3.amazonaws.com/59d6418cc5e27f664a26b4e4/5ab2ba388b7361aa0604c2f5/a624d5a61d2c33cc0f646ca936f8f9f1/image.png'));
    dispatch(SET_MAP_STATE(true));
  }

  renderBackground(opacity: number, bgOpacity?: 0) {
    return (
      <div className={`map-selector ${bgOpacity ? 'selected' : ''}`} style={{ opacity: bgOpacity === 0 || bgOpacity !== undefined ? 0 : 1 }}>
        {this.renderContent(opacity)}
      </div>
    );
  }

  renderContent(opacity: number) {
    return (
      <div className="map-selector-container" style={{ opacity }}>
        <div className="option design-on-map-button" onClick={() => this.setMap(MAP)}>
          {this.designOnMapImg}
          <div className="label">Design on Map</div>
        </div>
        <div className="option blank-page-button" onClick={() => this.setMap(BLANK)}>
          {this.blankDesignImg}
          <div className="label">Blank Page</div>
        </div>
        {/* <div className="option import-image-button">
            {this.importImageImg}
            <div className="tbd">COMING SOON</div>
            <div className="label">Import Image</div>
          </div> */}
      </div>
    );
  }

  render() {
    const { onDesignLoaded, blankDesignLoaded, selected } = this.state;
    if (selected) {
      return this.renderBackground(0, 0);
    }

    if (onDesignLoaded && blankDesignLoaded) {
      return this.renderBackground(1);
    }

    return this.renderBackground(0);
  }
}

function mapStateToProps(appState: appState) {
  return {
    openedDrawerPage: appState.projectConfiguration.openedDrawerPage,
    windSpecial: appState.projectConfiguration.projectEnvConfig.wind_special,
  };
}

export default connect(mapStateToProps)(RoofsSelectorMap);

import React from 'react';
import { dispatch, state } from '__common/store';
import { HIDE_REPORT_PROBLEM_FORM, SET_PROBLEM_FORM_DATA, SHOW_REPORT_PROBLEM_FORM } from './ReportProblemActions';
import { getFormattedWord } from './ReportProblemForm';

export const openReportProblem = () => {
  const { reportProblem: { open }, user } = state();

  if (open) {
    dispatch(HIDE_REPORT_PROBLEM_FORM());
  } else {
    dispatch(SHOW_REPORT_PROBLEM_FORM());
    if (user && user.email) {
      dispatch(SET_PROBLEM_FORM_DATA('email', user.email));
    }
    const { location: { pathname, href } } = window;
    const pageList = ['bom','design','engineering']
    const urlPage = pageList.find(page => pathname.includes(page));

    if (urlPage){
      dispatch(SET_PROBLEM_FORM_DATA('project_links', href));
      const productKey = pathname?.split(urlPage)[1]?.split('/')[1];
      if (productKey) {
        const productName = getFormattedWord(productKey);
        dispatch(SET_PROBLEM_FORM_DATA('product_affected', [productName]));
      }
    }

  }
};

export default () => (
  <div className="report-problem-button" onClick={openReportProblem}>Contact Support</div>
);

import { createReducer } from '__common/utils/helpers';
import { EngineeringProjectDocumentsActionsConstants } from 'actionsConstants';
import { CUSTOMARY_UNIT, METRIC_UNIT } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';

export let engineeringProjectDocumentsState: engineeringProjectDocumentsState = {
  bom: false,
  layout: false,
  engineering: false,
  engineeringSummary: false,
  internalEngineering: false,
  detailedEngineering: false,
  units: CUSTOMARY_UNIT,
  layout_type_dxf:false,
  dxf : false,
  consolidatedDXF : false,
  constructionDXF: false,
  dxf_zip : false,
  is_dxf_generating : false,
  is_consolidated_dxf_generating : false,
  is_dxf_zip_generating : false,
  peStamp: false
};

export default {
  engineeringProjectDocuments: createReducer(engineeringProjectDocumentsState, {
    [EngineeringProjectDocumentsActionsConstants.LAYOUT_GENERATE_START](state: engineeringProjectDocumentsState): engineeringProjectDocumentsState {
      return { ...state, layout: false };
    },
    [EngineeringProjectDocumentsActionsConstants.BOM_GENERATE_START](state: engineeringProjectDocumentsState): engineeringProjectDocumentsState {
      return { ...state, bom: false };
    },
    [EngineeringProjectDocumentsActionsConstants.ENGINEERING_GENERATE_START](state: engineeringProjectDocumentsState): engineeringProjectDocumentsState {
      return { ...state, engineering: false };
    },
    [EngineeringProjectDocumentsActionsConstants.ENGINEERING_SUMMARY_GENERATE_START](state: engineeringProjectDocumentsState): engineeringProjectDocumentsState {
      return { ...state, engineeringSummary: false };
    },
    [EngineeringProjectDocumentsActionsConstants.INTERNAL_ENGINEERING_GENERATE_START](state: engineeringProjectDocumentsState): engineeringProjectDocumentsState {
      return { ...state, internalEngineering: false };
    },
    [EngineeringProjectDocumentsActionsConstants.DETAILED_ENGINEERING_GENERATE_START](state: engineeringProjectDocumentsState): engineeringProjectDocumentsState {
      return { ...state, detailedEngineering: false };
    },
    [EngineeringProjectDocumentsActionsConstants.PE_STAMP_GENERATE_START](state: engineeringProjectDocumentsState): engineeringProjectDocumentsState {
      return { ...state, peStamp: false };
    },
    [EngineeringProjectDocumentsActionsConstants.LAYOUT_GENERATE_SUCCESSFUL](state: engineeringProjectDocumentsState): engineeringProjectDocumentsState {
      return { ...state, layout: true };
    },
    [EngineeringProjectDocumentsActionsConstants.BOM_GENERATE_SUCCESSFUL](state: engineeringProjectDocumentsState): engineeringProjectDocumentsState {
      return { ...state, bom: true };
    },
    [EngineeringProjectDocumentsActionsConstants.ENGINEERING_GENERATE_SUCCESSFUL](state: engineeringProjectDocumentsState): engineeringProjectDocumentsState {
      return { ...state, engineering: true };
    },
    [EngineeringProjectDocumentsActionsConstants.ENGINEERING_SUMMARY_GENERATE_SUCCESSFUL](state: engineeringProjectDocumentsState): engineeringProjectDocumentsState {
      return { ...state, engineeringSummary: true };
    },
    [EngineeringProjectDocumentsActionsConstants.INTERNAL_ENGINEERING_GENERATE_SUCCESSFUL](state: engineeringProjectDocumentsState): engineeringProjectDocumentsState {
      return { ...state, internalEngineering: true };
    },
    [EngineeringProjectDocumentsActionsConstants.DETAILED_ENGINEERING_GENERATE_SUCCESSFUL](state: engineeringProjectDocumentsState): engineeringProjectDocumentsState {
      return { ...state, detailedEngineering: true };
    },

    [EngineeringProjectDocumentsActionsConstants.PE_STAMP_GENERATE_SUCCESSFUL](state: engineeringProjectDocumentsState): engineeringProjectDocumentsState {
      return { ...state, peStamp: true };
    },
    [EngineeringProjectDocumentsActionsConstants.PROJECT_DOCUMENTS_RESET](state: engineeringProjectDocumentsState): engineeringProjectDocumentsState {
      return { ...state, bom: false, layout: false, engineering: false };
    },
    [EngineeringProjectDocumentsActionsConstants.TOGGLE_UNITS](state: engineeringProjectDocumentsState): engineeringProjectDocumentsState {
      return { 
        ...state, 
        units: toggleUnits(state.units),
      };
    },
    [EngineeringProjectDocumentsActionsConstants.SET_UNITS](state: engineeringProjectDocumentsState, action): engineeringProjectDocumentsState {
      return { 
        ...state, 
        units: action.payload.units,
      };
    },
    [EngineeringProjectDocumentsActionsConstants.DXF_GENERATE_START](state: engineeringProjectDocumentsState): engineeringProjectDocumentsState {
      return { ...state, dxf: false, is_dxf_generating: true };
    },
    [EngineeringProjectDocumentsActionsConstants.DXF_GENERATE_SUCCESSFUL](state: engineeringProjectDocumentsState): engineeringProjectDocumentsState {
      return { ...state, dxf: true, is_dxf_generating: false };
    },
    [EngineeringProjectDocumentsActionsConstants.CONSOLIDATED_DXF_GENERATE_START](state: engineeringProjectDocumentsState): engineeringProjectDocumentsState {
      return { ...state, consolidatedDXF: false, is_consolidated_dxf_generating: true };
    },
    [EngineeringProjectDocumentsActionsConstants.CONSOLIDATED_DXF_GENERATE_SUCCESSFUL](state: engineeringProjectDocumentsState): engineeringProjectDocumentsState {
      return { ...state, consolidatedDXF: true, is_consolidated_dxf_generating: false };
    },
    [EngineeringProjectDocumentsActionsConstants.CONSOLIDATED_DXF_GENERATE_START](state: engineeringProjectDocumentsState): engineeringProjectDocumentsState {
      return { ...state, constructionDXF: false, is_construction_dxf_generating: true };
    },
    [EngineeringProjectDocumentsActionsConstants.CONSOLIDATED_DXF_GENERATE_SUCCESSFUL](state: engineeringProjectDocumentsState): engineeringProjectDocumentsState {
      return { ...state, constructionDXF: true, is_construction_dxf_generating: false };
    },
    [EngineeringProjectDocumentsActionsConstants.CONSOLIDATED_DXF_GENERATE_START](state: engineeringProjectDocumentsState): engineeringProjectDocumentsState {
      return { ...state, constructionDXF: false, is_planset_dxf_generating: true };
    },
    [EngineeringProjectDocumentsActionsConstants.CONSOLIDATED_DXF_GENERATE_SUCCESSFUL](state: engineeringProjectDocumentsState): engineeringProjectDocumentsState {
      return { ...state, constructionDXF: true, is_planset_dxf_generating: false };
    },
    [EngineeringProjectDocumentsActionsConstants.DXF_ZIP_ALL_GENERATE_START](state: engineeringProjectDocumentsState): engineeringProjectDocumentsState {
      return { ...state, dxf_zip: false, is_dxf_zip_generating: true };
    },
    [EngineeringProjectDocumentsActionsConstants.DXF_ZIP_ALL_GENERATE_SUCCESSFUL](state: engineeringProjectDocumentsState): engineeringProjectDocumentsState {
      return { ...state, dxf_zip: true, is_dxf_zip_generating:false };
    },
    [EngineeringProjectDocumentsActionsConstants.LAYOUT_TYPE_DXF_GENERATE_START](state: engineeringProjectDocumentsState): engineeringProjectDocumentsState {
      return { ...state, layout_type_dxf: false, is_layout_type_dxf_generating: true };
    },
    [EngineeringProjectDocumentsActionsConstants.LAYOUT_TYPE_DXF_GENERATE_SUCCESSFUL](state: engineeringProjectDocumentsState): engineeringProjectDocumentsState {
      return { ...state, layout_type_dxf: true, is_layout_type_dxf_generating: false };
    },
  }),
};

export const toggleUnits = (units: unitsType): unitsType => units === CUSTOMARY_UNIT ? METRIC_UNIT : CUSTOMARY_UNIT;

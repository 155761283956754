import { dispatch, state } from '../../store';
import { FETCH_MFGS_AND_MODELS_REQUEST } from 'actions';
import { getPanelsModel } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/loadProject';
import { isAscender, isCanadianChangesProduct } from '__common/constants/products';
import { cmsToInches, inchesToCms, kgsToLbs, lbsToKgs } from '__common/calculations/unitConversions';
import { isCustomaryUnit, isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import { isCanadianZipcode } from 'projectDesign/components/projectConfiguration/utils/validation';

export function loadProject(productId: string, module: number, model: number, version: string) {
  dispatch(FETCH_MFGS_AND_MODELS_REQUEST(productId, module, model, undefined, version));
}

export function loadProjectModule(productId: string, projectData?:any ) {
  const { saveLoadProject: { dataToLoad }, projectConfiguration: {projectVersion, productId: productNumber,  inputUnit , projectEnvConfig : { zipcode }}, app : { isProjectError } } = state();
  if (!isProjectError) {
    const panelsModel = getSavedPanelsData(dataToLoad);
    if(isAscender(productNumber)){
      panelsModel.height = cmsToInches(panelsModel.height);
      panelsModel.width = cmsToInches(panelsModel.width);
      panelsModel.thickness = cmsToInches(panelsModel.thickness);
      panelsModel.weight = kgsToLbs(panelsModel.weight);
    }
    else if (isCanadianChangesProduct(productNumber) && isCanadianZipcode(zipcode) && !isMetricUnit(inputUnit)){
      panelsModel.height = inchesToCms(panelsModel.height);
      panelsModel.width = inchesToCms(panelsModel.width);
      panelsModel.thickness = inchesToCms(panelsModel.thickness);
      panelsModel.weight = inchesToCms(panelsModel.weight);
    }
    dispatch(FETCH_MFGS_AND_MODELS_REQUEST(productId, panelsModel.module, panelsModel.id, panelsModel,projectVersion, inputUnit));
  } else {
    const { model, module, module_length, module_thickness, module_watts, module_weight, module_width, input_unit } = projectData.project_configuration;
    const { version } = projectData.version
    const customModel = {
      id : model,
      module,
      watts: module_watts,
      width: module_width,
      height: module_length,
      thickness: module_thickness,
      weight: module_weight,
    };
    dispatch(FETCH_MFGS_AND_MODELS_REQUEST(productId, customModel.module, customModel.id, customModel, version, input_unit));
  }
}

export function loadDefaultModule(productId: string) {

  const { userPreferences: { module, model }, projectConfiguration: {projectVersion} } = state();
  dispatch(FETCH_MFGS_AND_MODELS_REQUEST(productId, module, model, undefined, projectVersion));
}

function getSavedPanelsData(dataToLoad): panelsModel | any {
  if (dataToLoad && Object.keys(dataToLoad).length) {
    return getPanelsModel(dataToLoad.project_configuration);
  }
}


export const convertModelData  = (modelData, to_unit: number) => {
  if(isCustomaryUnit(to_unit)) {
    modelData.thickness = Number(cmsToInches(modelData.thickness).toFixed(1));
    modelData.width = Number(cmsToInches(modelData.width).toFixed(1));
    modelData.height = Number(cmsToInches(modelData.height).toFixed(1));
    modelData.weight = Number(kgsToLbs(modelData.weight).toFixed(1));
  }
  else if(isMetricUnit(to_unit)) {
    modelData.thickness = Number(inchesToCms(modelData.thickness).toFixed(1));
    modelData.width = Number(inchesToCms(modelData.width).toFixed(1));
    modelData.height = Number(inchesToCms(modelData.height).toFixed(1));
    modelData.weight = Number(lbsToKgs(modelData.weight).toFixed(1));
  }
  return modelData;
}
import en from '_translations/en-us.json';
import { ActionTypes } from '../actionsConstants';
import { createReducer } from '__common/utils/helpers';
import { translations } from '_translations/translations';
import { Action } from '__common/store/action';
import { APP_REVISION_HISTORY_PAYLOAD } from './appActions';

// const savedLang = localStorage.getItem('lang');
export const appState: appConfigState = {
  locales: en, // savedLang ? translations[savedLang] : translations.en, // TODO
  rehydrated: false,
  searchProject: false,
  copyProject: false,
  loaderMsg: '',
  message: '',
  revision_history: [],
  isProjectError: false,
};

export default {
  app: createReducer(appState, {
    [ActionTypes.CHANGE_LANGUAGE](state: appConfigState, action): appConfigState {
      localStorage.setItem('lang', action.payload.lang);
      const locales = translations[action.payload.lang];

      return { ...state, locales };
    },
    [ActionTypes.SEARCH_PROJECT](state: appConfigState): appConfigState {
      return { ...state, searchProject: true };
    },
    [ActionTypes.COPY_PROJECT](state: appConfigState): appConfigState {
      return { ...state, copyProject: true };
    },
    [ActionTypes.REDIRECT_TO_PROJECT](state: appConfigState): appConfigState {
      return { ...state, searchProject: false, copyProject: false };
    },
    [ActionTypes.PROJECT_NOT_FOUND](state: appConfigState): appConfigState {
      return { ...state, searchProject: false, copyProject: false };
    },
    [ActionTypes.EMPTY_APP_ACTION](state: appConfigState): appConfigState {
      return { ...state };
    },
    [ActionTypes.SET_LOADER_MSG](state: appConfigState, action): appConfigState {
      const { loaderMsg } = action.payload;
      return { ...state, loaderMsg };
    },
    [ActionTypes.CLEAR_LOADER_MSG](state: appConfigState): appConfigState {
      return { ...state, loaderMsg: '' };
    },
    [ActionTypes.GET_APP_STATUS](state: appConfigState): appConfigState {
      return { ...state };
    },
    [ActionTypes.GET_APP_STATUS_SUCCESS](state: appConfigState, action): appConfigState {
      const { message } = action.payload;
      return { ...state, message };
    },
    [ActionTypes.GET_APP_STATUS_FAILURE](state: appConfigState): appConfigState {
      const message = 'Application seems to be offline';
      return { ...state, message };
    },
    [ActionTypes.GET_APP_STATUS_CLEAR](state: appConfigState): appConfigState {
      return { ...state, message: '' };
    },
    [ActionTypes.SET_APP_REVISION_HISTORY](state: appConfigState, action: Action<APP_REVISION_HISTORY_PAYLOAD>): appConfigState {
      return {
        ...state,
        revision_history: action.payload.revision_history,
      };
    },
    [ActionTypes.SET_INVALID_PROJECT](state: appConfigState,action): appConfigState {
      const {isProjectError} = action.payload
      return { ...state, isProjectError: isProjectError };
    },
  }),
};

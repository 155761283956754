import { state } from '__common/store';
import findKey from 'lodash/findKey';
import { getAllProductsInfo, getProductsInfoForUser, getUnaccessedProductsInfoForUser } from 'app/components/HeaderComponent/utils/getProducts';
import { getFormattedWord, getFormattedWordForProductLabel } from 'app/components/ReportProblem/ReportProblemForm';
import { TypeOfSupport } from 'app/components/ReportProblem/constants';

// Warning. The Ids are also duplicated in workers' code.
// It's because we can't easily share the code between the app and workers.

export const products = {
  solarmount_hd: 2,
  rm: 8,
  solarmount_2: 9,
  // adjustable_tilt_system: 99,
  solarmount_2_tilt: 99,
  sunframe: 5,
  solarmount_lt: 11,
  sfmEnhanced: 17, // sunframe microrail
  sfm_enhanced: 17,
  sfmInfinity: 18,
  sfm_infinity: 18,
  rm_5: 14,
  rm_dt: 15,
  gft: 19,
  ula: 20,
  rm_10_ultra: 21,
  helioscope: 98,
  rm_gridflex: 22,
  metal_x: 23,
  ecofoot2_plus: 24,
  nxt_umount : 25,
  nxt_horizon_lt : 26,
  nxt_horizon_hd : 27,
  rm_10_evolution: 28,
  ascender: 29,
  sm_tilt_pr : 30,
  sm_tilt_pr_lt : 31,
  solarmount_ascender_flush: 32,
  rm_gridflex_10: 33,
  nxt_tilt: 34,
};



export function isAscender(productId: number) {
  return [29].includes(productId);
}

export function isSMTiltPR(productId: number) {
  return [30, 31].includes(productId)
}
export function isSMTiltPRRegular(productId: number) {
  return [30].includes(productId)
}
export function isNxtHorizon(productId: number) {
  return [25,26,27].includes(productId)
}

export function isULA(productId: number) {
  return productId === products.ula;
}

export function isRM10Evolution(productId: number) {
  return productId === products.rm_10_evolution;
}

export function isGFT(productId: number) {
  return productId === products.gft;
}

export function isSM2Standard(productId: number) {
  return productId === 9;
}

export function isSolarMount(productId: number) {
  return [2, 9, 11, 32].includes(productId);
}

export function isSMTiltVariations(productId: number) {
  return [2, 9, 99].includes(productId);
}

export function isSMTiltOrFlush(productId: number) {
  return [2, 9, 11, 99, 32].includes(productId);
}

export function isSMFamily(productId: number) {
  return [2, 9, 11, 99, 32].includes(productId);
}

export function isSFMSandSMF(productId: number): boolean {
  return [2, 9, 11, 17, 18, 99, 32].includes(productId);
}

export function isMetalX(productId: number) {
  return productId === products.metal_x;
}

export function isNxtTilt(productId: number) {
  return productId === products.nxt_tilt;
}

export function isEcoFoot2Plus(productId: number) {
  return productId === products.ecofoot2_plus;
}

export function isSFMFamily(productId: number) {
  return productId === products.sfmEnhanced || productId === products.sfmInfinity;
}

export function isSunframeMicroRail(productId: number) {
  return productId === products.sfmEnhanced
}

export function isSFMInfinityOrSMFlush(productId: number) {
  return [9, 18].includes(productId);
}

export function isSFMInfinity(productId: number) {
  return productId === 18;
}

export function isRMFamily(productId: number) {
  switch (productId) {
    case products.rm:
    case products.rm_5:
    case products.rm_dt:
    case products.rm_10_ultra:
    case products.rm_gridflex:
    case products.rm_10_evolution:
    case products.rm_gridflex_10:
      return true;
    default:
      return false;
  }
}

export function isOldRMFamily(productId: number) {
  switch (productId) {
    case products.rm:
    case products.rm_5:
    case products.rm_dt:
    case products.rm_10_evolution:
      return true;
    default:
      return false;
  }
}

export function isRMAndEcofootFamily(productId: number) {
  switch (productId) {
    case products.rm:
    case products.rm_5:
    case products.rm_dt:
    case products.rm_10_evolution:
    case products.ecofoot2_plus:
    case products.rm_gridflex_10:
      return true;
    default:
      return false;
  }
}

export function isRm10AndRm10evoAndRm5(productId: number) {
  switch (productId) {
    case products.rm:
    case products.rm_5:
    case products.rm_10_evolution:
      return true;
    default:
      return false;
  }
}

export function isRmGridflex10(productId: number) {
  return productId === products.rm_gridflex_10;
}

export function isRMGridFlexAndEcofoot(productId: number) {
  switch (productId) {
    case products.rm_gridflex:
    case products.ecofoot2_plus:
      return true;
    default:
      return false;
  }
}


export function isSMLT(productId: number) {
  return productId === products.solarmount_lt;
}

export function isSMRegular(productId: number) {
  return productId === 9;
}

export function isSMHD(productId: number) {
  return productId === products.solarmount_hd;
}

export const isSMAscenderFlush = (productId: number) : boolean => productId === products.solarmount_ascender_flush;

export function isSMTilt(productId: number) {
  return productId === 99;
}

export function isSF(productId: number) {
  return productId === 5;
}

export function isRM5(productId: number) {
  return productId === products.rm_5;
}

export function isRM10(productId: number) {
  return productId === products.rm;
}
export function isRMDT(productId: number) {
  return productId === products.rm_dt;
}

export function isRM10Ultra(productId: number) {
  return productId === products.rm_10_ultra;
}

export function isRMGridFlex(productId: number) {
  return productId === products.rm_gridflex;
}

export function isRMIFIProduct(productId: number) {
  return isRM10Ultra(productId) || isRMGridFlex(productId);
}

export function isRM10orRM10Evo(productId: number) {
  return isRM10(productId) || isRM10Evolution(productId);
}

export function getProductName(productId: number) {
  return findKey(products, val => val === productId);
}


export function isSfmFamilyName(productName: string) {
  return productName === 'sfmEnhanced' || productName === 'sfmInfinity';
}

export function isSfmInfinity(productId: number) {
  return productId === products.sfmInfinity;
}

export function isResidentialProduct(productId: number) {
  return isSMFamily(productId) || isSFMFamily(productId) || isSF(productId) || isMetalX(productId) || isNxtHorizon(productId) || isNxtTilt(productId) || isSMTiltPR(productId) || isAscender(productId);
}

export function isCommercialProduct(productId: number) {
  return isRM5(productId) || isRM10(productId) || isRMDT(productId) || isRMIFIProduct(productId) || isEcoFoot2Plus(productId) || isRM10Evolution(productId) || isRmGridflex10(productId);
}

export function isGroundProduct(productId: number) {
  return isGFT(productId) || isULA(productId);
}

export function isSFMAndMetalXAndNXT(productId: number) {
  return [26, 27, 28, 23, 18].includes(productId);
}

export function isAdditionalUserInputEnabledProduct(productId: number){
  return isRM10(productId) || isRM10Evolution(productId) || isEcoFoot2Plus(productId) || isRmGridflex10(productId)
}


export function isOnlyAsce716ProductFamily(productId: number) {
  const {projectConfiguration: {railsProductId}} = state();
  return isSMTiltPR(productId) || isAscender(productId) || isSMAscenderFlush(railsProductId);
}

export function productHasAsce722BuildingCode(productId: number) {
  return ![99, 29, 23].includes(productId)
}

export function productHasAsce716BuildingCode(productId: number) {
  return ![99].includes(productId)
}

export const productsInfo = [
  {
    productType: 'FLAT ROOF',
    productItems: [ products.rm_5, products.rm_gridflex, products.rm, products.rm_10_evolution, products.ecofoot2_plus, products.rm_dt, products.ascender, products.sm_tilt_pr, products.rm_gridflex_10]
  },
  {
    productType: 'PITCHED ROOF',
    productItems: [ products.solarmount_2, products.nxt_umount, products.solarmount_2_tilt, products.sfmInfinity, products.metal_x, products.ascender, products.sm_tilt_pr ]
  },
  {
    productType: 'GROUND MOUNT',
    productItems: [ products.gft, products.ula ]
  },
  {
    productType: 'IMPORTS',
    productItems: [ products.helioscope ]
  },
]

export const nonPrivilegedProducts = [ products.solarmount_2, products.sfmInfinity, products.rm_5, products.rm_dt, products.ecofoot2_plus, products.rm_10_evolution, products.gft, products.ula, products.solarmount_lt, products.helioscope, products.nxt_umount ];

export const isNonPrivilegedProduct = productId => nonPrivilegedProducts.includes(productId);

export const discountinuedProducts = [products.rm];

export function isSMFlushOrTiltOrTiltPR(productId: number) {
  switch (productId) {
    case products.solarmount_2:
    case products.solarmount_hd:
    case products.solarmount_lt:
    case products.solarmount_ascender_flush:
    case products.solarmount_2_tilt:
    case products.sm_tilt_pr:
    case products.sm_tilt_pr_lt:
    case products.nxt_tilt:
      return true;
    default:
      return false;
  }
}

export function isSMTiltPRLT(productId: number) {
  return productId === products.sm_tilt_pr_lt;
}

export function isCanadianChangesProduct(productId: number) {
  return isRM10orRM10Evo(productId) || isRM5(productId) || isEcoFoot2Plus(productId);
}


export function isOneClickCDProduct(productId: number) {
  return (isRM10Evolution(productId) || isSolarMount(productId)) && !(isSMAscenderFlush(productId))
}
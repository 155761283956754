import * as React from 'react';
import autobind from 'autobind-decorator';
import history from '__common/modules/history';
import classNames from 'classnames';
import { anyPanelsDrawn } from '__editor/googleMapsRoofsSelector/components/drawingManager/drawingManagerHelper';
import { connect } from 'react-redux';
import { GO_TO_ENGINEERING_FROM_BOM } from 'actions';
import { panelsEditorEnabled, deactivePanelsEditor } from '__editor/panelsEditor/panelsEditorHelper';
import { saveBom } from 'bom/utils/saveBom';
import { saveProject, savePanelEditorAndSaveProject, validatePanelQtyFromPermitForm } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/saveProject';
import { state } from '__common/store';


type Props = {
  dispatch: Function,
  projectId?: string,
  productId?: string,
  bom: bomState,
  drawingManager: drawingManagerState,
  railsSelector: railsSelectorState,
  moduleSelector: moduleSelectorState,
  panels: panelsState,
  projectConfiguration: projectConfigurationState,
};

type State = {
  isOnBom: boolean,
  isOnEngineering: boolean,
};

class NavigationBar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { isOnBom: false, isOnEngineering: false };
  }

  _enableProjectInfoTab() {
    return window.location.href.includes('project/design') || this._isHelioscope();
  }

  _enableBomTab() {
    const { bom: { goToEngineeringFromBom } } = this.props;
    return window.location.href.includes('project/bom') && !goToEngineeringFromBom;
  }

  _enableEngineeringTab() {
    return window.location.href.includes('project/engineering');
  }

  _isHelioscope() {
    return window.location.href.includes('/helioscope/');
  }

  _isProjectInfoLast() {
    return this._enableProjectInfoTab() && !this._enableBomTab();
  }

  _isBomLast() {
    return this._enableProjectInfoTab() && this._enableBomTab() && !this._enableEngineeringTab();
  }

  _hasProjectIdAndProductId() {
    const { productId, projectId } = this.props;
    return productId && projectId;
  }

  _inactiveTab() {
    const {projectConfiguration:{gone_through_panel_editor, projectEnvConfig: {helioscope_id}}} = state();
    return (this._enableProjectInfoTab() && !anyPanelsDrawn()) || (helioscope_id && !gone_through_panel_editor);
  }

  isOnHelioscopeFlyout(){
    const {projectConfiguration:{openedDrawerPage}} = state();
    return openedDrawerPage === 'helioscope-selection';
  }

  projectInfoClasses() {
    return classNames(
      {
        inactive: this.isOnHelioscopeFlyout(),
        active: this._enableProjectInfoTab() && !this._enableBomTab() && !this._enableEngineeringTab(),
        'right-border': this._enableEngineeringTab(),
      },
      'steps project-info-step',
    );
  }

  bomClasses() {
    return classNames(
      {
        'right-border': this._enableProjectInfoTab(),
        inactive: this._inactiveTab() || this.props.moduleSelector.modelDataError || this.isOnHelioscopeFlyout(),
        active: !this._enableProjectInfoTab() && this._enableBomTab() && !this._enableEngineeringTab(),
      },
      'bom-step steps',
    );
  }

  engineeringClasses() {
    return classNames(
      'engineering-step steps',
      {
        active: this._enableEngineeringTab(),
        inactive: this._inactiveTab() || this.props.moduleSelector.modelDataError || this.isOnHelioscopeFlyout(),
      },
    );
  }

  @autobind
  onDisignClick() {
    const { productId, projectId, projectConfiguration : { projectEnvConfig : { adjustable_tilt_system}} } = this.props;

    if (this._isHelioscope()) {
      return;
    }


    if (projectId && !this._enableProjectInfoTab()) {
      history.push(`/project/design/${productId}/${projectId}`);
    }
  }

  @autobind
  onBomClick() {
    const { productId, projectId, drawingManager : { roofs }, projectConfiguration : { projectEnvConfig } } = this.props;

    if (this._inactiveTab() || this.props.moduleSelector.modelDataError) {
      return;
    }

    if (!validatePanelQtyFromPermitForm(projectEnvConfig, roofs)) {
      return
    }

    if (this._enableProjectInfoTab()) {
      savePanelEditorAndSaveProject();
    }

    if (this._enableEngineeringTab()) {
      history.push(`/project/bom/${productId}/${projectId}`);
    }
  }

  @autobind
  onEngineeringClick() {
    const { drawingManager : { roofs }, projectConfiguration : { projectEnvConfig } } = this.props;


    if (this._inactiveTab() || this.props.moduleSelector.modelDataError) {
      return;
    }

    if (!validatePanelQtyFromPermitForm(projectEnvConfig, roofs)) {
      return
    }
    
    if (this._enableBomTab()) {
      saveBom(false);
    }

    if (this._enableProjectInfoTab()) {
      const { dispatch, productId, projectId, drawingManager: { roofs }, moduleSelector: { modelData } } = this.props;
      
      if (Object.keys(modelData).length === 0) {
        return;
      }

      if (panelsEditorEnabled()) {
        return deactivePanelsEditor();
      }

      if (roofs && Object.keys(roofs).length) {
        const withBom = false;
        dispatch(GO_TO_ENGINEERING_FROM_BOM());
        saveProject(productId, projectId, withBom);
      }
    }
  }

  render() {
    return (
      <div className="header-navigation-bar">
        <div className={this.projectInfoClasses()} onClick={this.onDisignClick}>
          design
        </div>
        <div 
          className={this.bomClasses()}
          onMouseEnter={() => this.setState(prevState => ({ isOnBom: true }))}
          onMouseLeave={() => this.setState(prevState => ({ isOnBom: false }))}
          onClick={this.onBomClick}
        >
          part list
        </div>
        <div 
          className={this.engineeringClasses()} 
          onMouseEnter={() => this.setState(prevState => ({ isOnEngineering: true }))} 
          onMouseLeave={() => this.setState(prevState => ({ isOnEngineering: false }))}
          onClick={this.onEngineeringClick}
        >
          engineering
        </div>
      </div>
    );
  }
}

function mapPropsToState(state: appState) {
  return {
    bom: state.bom,
    drawingManager: state.drawingManager,
    railsSelector: state.railsSelector,
    moduleSelector: state.moduleSelector,
    panels: state.panels,
    projectConfiguration: state.projectConfiguration,
  };
}

export default connect(mapPropsToState)(NavigationBar);

import {
  smBuildingHeight,
} from "projectDesign/components/projectConfiguration/fields/buildingHeight";
import clientName from 'projectDesign/components/projectConfiguration/fields/clientName';
import clientAddress from 'projectDesign/components/projectConfiguration/fields/clientAddress';
import elevation from "projectDesign/components/projectConfiguration/fields/elevation";
import seamSpacing from "projectDesign/components/projectConfiguration/fields/seamSpacing.json";
import seismicSs from "projectDesign/components/projectConfiguration/fields/seismicSs";
import metalXRoofType,{ options as roofTypeOptions } from "projectDesign/components/projectConfiguration/fields/metalXRoofType";
import snowLoad from "projectDesign/components/projectConfiguration/fields/snowLoad";
import span from "projectDesign/components/projectConfiguration/fields/metalXSpans";
import metalXStaggerAttachments from "projectDesign/components/projectConfiguration/fields/metalXStaggerAttachments";
import WindExposure from "projectDesign/components/projectConfiguration/fields/windExposure";
import {
  asce705WindSpeedForSfmAndSm,
  asce716WindSpeedForSfmAndSm,
  asce710WindSpeedForSfmAndSm,
} from "projectDesign/components/projectConfiguration/fields/windSpeed";
import { checkEnvironmentalFactors } from "../utils/checkEnvironmentalFactors";
import {
  checkBuildingHeight,
  checkFieldNotEmpty,
  checkRafterSpacing,
  checkSeismic,
  checkShortestBuildingLength,
} from "__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/validateProject";
import { state } from "__common/store";
import { getASCE716722LocationsResidentalAdditionalInputs } from "../fields/getASCE716LocationsResidentalAdditionalInputs";
import {
  isASCE705,
  isASCE710,
} from "__common/constants/buildingCodes";
import {
  configStandingSeam,
  configRPanel,
  configCorrugated,
} from 'projectDesign/components/projectConfiguration/fields/metalXMaterialThickness';
import topographicalFactorKzt from '../fields/topographicalFactorKzt';
import getAttachmentType from '../fields/metalXAttachmentType';
import shortestBuildingLength from "../fields/shortestBuildingLength";
import { asce716BuildingCodeNXTMetalx } from '../fields/buildingCode';
function getWindSpeedField(buildingCodeValue: number) {
  if (isASCE705(buildingCodeValue)) {
    return asce705WindSpeedForSfmAndSm;
  }

  if (isASCE710(buildingCodeValue)) {
    return asce710WindSpeedForSfmAndSm;
  }

  return asce716WindSpeedForSfmAndSm;
}

export default (buildingCodeValue: number) => {
  const {
    projectConfiguration: {
      projectEnvConfig: { seismic_ss,roof_type, topographical_factor_kzt, material_thickness , rafter_spacing_inches, shortest_building_length, building_height},
    },
  } = state();

  const buildingCode = asce716BuildingCodeNXTMetalx;
  const windSpeed = getWindSpeedField(buildingCodeValue);
  const buildingHeight = smBuildingHeight;

  checkEnvironmentalFactors(
    buildingCode,
    windSpeed,
    snowLoad,
    seismicSs,
    elevation,
  );

  buildingHeight.missing = !checkBuildingHeight(building_height * 1);
  shortestBuildingLength.missing = !checkShortestBuildingLength(shortest_building_length * 1);
  seamSpacing.missing = !checkRafterSpacing(Number(rafter_spacing_inches));
  seismicSs.missing = !checkSeismic(seismic_ss);
  topographicalFactorKzt.missing = !checkFieldNotEmpty(topographical_factor_kzt);
  const standingSeamSelected = roof_type === roofTypeOptions.metalSeam.value;
  const rPanelSelected = roof_type === roofTypeOptions.metalRPanel.value;
  const corrugatedSelected = roof_type === roofTypeOptions.metalCorrugated.value;
  const roofSelectorBooleans = [standingSeamSelected, rPanelSelected, corrugatedSelected];
  let attachmentType = null;

  attachmentType = getAttachmentType(roofSelectorBooleans, material_thickness);


  const cfg = {
    zipCode: "zipcode",
    Area: "Peoria, AZ",
    WindSpeed: "wind_speed",
    SnowFall: "snow_load",
    Client: {
      label: "",
      fields: [clientName, clientAddress],
    },
    LocationAndLoads: {
      fields: [
        buildingCode,
        WindExposure,
        windSpeed,
        snowLoad,
        seismicSs,
        elevation,
        ...getASCE716722LocationsResidentalAdditionalInputs(buildingCodeValue),
        topographicalFactorKzt,
      ],
    },
    BuildingDescription: {
      label: "Building description",
      fields: [
        buildingHeight,
        metalXRoofType,
        ...(standingSeamSelected ? [configStandingSeam] : rPanelSelected ? [configRPanel] : corrugatedSelected ? [configCorrugated] : []),
        attachmentType,
        metalXStaggerAttachments,
        span,
        seamSpacing,
        shortestBuildingLength,
      ],
    },
    spans: true,
  };

  return cfg;
};

import Leaflet, { LatLng } from 'leaflet';
import {
  arrayDetails,
  popupEvents,
  shouldShowBannersInMiniMap,
} from '__editor/components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorPopup';

export function createBingMapPopup(
  roofId: number,
  name: string,
  nrOfModules: number,
  position: LatLng,
  hasPanels: boolean,
  map: Leaflet.Map,
  previewMode: boolean,
) {
  const popup = Leaflet.popup({ autoClose: false, closeOnClick: false })
    .setLatLng(position)
    .setContent(
      `
    <div class="edit-and-delete-array bing-maps-popup">
    <div class="array-details">
    ${arrayDetails(name, nrOfModules, hasPanels)}
  </div>
    <div class="array-buttons">
    ${shouldShowBannersInMiniMap(roofId, previewMode)}
    </div>
  </div>
    `,
    );

  popup.on('add', () => {
    popupEvents();
  });

  return popup;
}


export function createBingMapAzimuth(
  azimuth: number,
  position: LatLng,
) {
  const popup = Leaflet.popup({ autoClose: false, closeOnClick: false, className: 'bing-maps-azimuth-popup' })
    .setLatLng(position)
    .setContent(
    `
      <div class="bing-maps-popup">
        <div class="array-details">
          Azimuth: ${azimuth}\u00B0
        </div>
      </div>
    `,
    );

  popup.on('add', () => {
    popupEvents();
  });

  return popup;
}

import { asce716and722BuildingCode, asce722BuildingCodeSM } from 'projectDesign/components/projectConfiguration/fields/buildingCode';
import {smBuildingHeight, buildingHeightMetric, buildingHeightSMFamilyNonAscenderFlushMetric} from 'projectDesign/components/projectConfiguration/fields/buildingHeight';
import clientName from 'projectDesign/components/projectConfiguration/fields/clientName';
import clientAddress from 'projectDesign/components/projectConfiguration/fields/clientAddress';
import smElevation, { elevationMetric } from 'projectDesign/components/projectConfiguration/fields/elevation';
import smRafterSpacing, {rafterSpacingMetric} from 'projectDesign/components/projectConfiguration/fields/rafterSpacing';
import railDirection from 'projectDesign/components/projectConfiguration/fields/railDirection';
import {railFinish  as smRailFinish, ascenderRailFinish} from 'projectDesign/components/projectConfiguration/fields/railFinish';
import {smAscenderFlushRoofType, smFlushRoofType } from 'projectDesign/components/projectConfiguration/fields/roofType';
import seismicSs from 'projectDesign/components/projectConfiguration/fields/seismicSs';
import smSnowLoad, {snowLoadMetric} from 'projectDesign/components/projectConfiguration/fields/snowLoad';
import smPreferredSpan, { preferredSpanMetric } from 'projectDesign/components/projectConfiguration/fields/span';
import WindExposure from 'projectDesign/components/projectConfiguration/fields/windExposure';
import { asce716WindSpeedForSfmAndSm, asce705WindSpeedForSfmAndSm, asce710WindSpeedForSfmAndSm, asce716WindSpeedMetric, } from 'projectDesign/components/projectConfiguration/fields/windSpeed';
import { checkEnvironmentalFactors } from 'projectDesign/components/projectConfiguration/utils/checkEnvironmentalFactors';
import { checkRafterSpacing, checkSeismic, checkShortestBuildingLength, checkBuildingHeight, checkSnowLoadLimit, checkTornadoSpeed, checkSeismicSds, checkFieldNotEmpty, checkRoofTypeMissing } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/validateProject';
import { state } from '__common/store';
import { isASCE705, isASCE710, isASCE716or722, isASCE722 } from '__common/constants/buildingCodes';
import { ascenderMidAndEndClamps, smMidAndEndClamps } from 'projectDesign/components/projectConfiguration/fields/smClamps';
import smTileReplacementsOrSolarhooks from '../fields/smTileReplacementsOrSolarhooks';
import smStandardSeamMetalAttachment from '../fields/smStandardSeamMetalAttachment';
import roofMaterialThicknessSolarMountFlush, { rpanelRoofMaterialThicknessForPM9000s, rpanelRoofMaterialThicknessForPMAdj } from '../fields/roofMaterialThicknessSolarMountFlush';
import smSolarhooksAttachments from '../fields/smSolarhooksAttachments';
import { RoofType,TileReplacementOrSolarhooks, removeUpslopeRail } from '../utils/constants';
import topographicalFactorKzt from '../fields/topographicalFactorKzt';
import { AttachmentType, smAndNxtAttachmentTypes,  } from '../fields/attachmentType';
import { roofSubstrate } from '../fields/roofSubstrate';
import { isOsbOrPlywood } from '../utils/validation';
import { isSMAscenderFlush } from '__common/constants/products';
import smShortestBuildingLength, {shortestBuildingLengthMetric, shortestBuildingLengthSMFamilyNonAscenderFlushMetric} from 'projectDesign/components/projectConfiguration/fields/shortestBuildingLength';
import residentalBuildingTypes from 'projectDesign/components/projectConfiguration/fields/residentalBuildingTypes';
import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import { getASCE716722LocationsResidentalAdditionalInputs } from '../fields/getASCE716LocationsResidentalAdditionalInputs';
import { isSMAscenderFlushMetric } from '__common/utils/products';
import azimuth from 'projectDesign/components/projectConfiguration/fields/helioscope_azimuth.json';
import frameSizeUp from 'projectDesign/components/projectConfiguration/fields/helioscope_frame_size_up.json';
import frameSizeWide from 'projectDesign/components/projectConfiguration/fields/helioscope_frame_size_wide.json';
import orientation from 'projectDesign/components/projectConfiguration/fields/helioscope_orientation.json';
import racking from 'projectDesign/components/projectConfiguration/fields/helioscope_racking.json';
import setSpacing from 'projectDesign/components/projectConfiguration/fields/helioscope_setback.json';
import seismicSds from 'projectDesign/components/projectConfiguration/fields/seismicSds';
import tornadoSpeed, { metricTornadoSpeedField } from 'projectDesign/components/projectConfiguration/fields/tornadoSpeed';
import { riskCategoryCheckForTornadoSpeed } from '../constraints/constraints';
import { longestBuildingLengthField as longestBuildingLength } from '../fields/longestBuildingLength';
import riskCategory from '../fields/riskCategory';
import StaggerAttachments from 'projectDesign/components/projectConfiguration/fields/staggerAttachments';
import { PSEUDO_STAFF_USER_PERMISSIONS } from 'user/userGroups';

const getWindSpeedField = (buildingCodeValue: number, inputUnit?: number) => {
  if(isMetricUnit(inputUnit)) {
    return asce716WindSpeedMetric;
  }
  else if (isASCE705(buildingCodeValue)) {
    return asce705WindSpeedForSfmAndSm;
  }
  else if (isASCE710(buildingCodeValue)) {
    return asce710WindSpeedForSfmAndSm;
  }
  else return asce716WindSpeedForSfmAndSm;
}

export default (buildingCodeValue: number) => {

  const {
    projectConfiguration: {
      railsProductId,
      projectEnvConfig: {
        seismic_ss, seismic_sds, tornado_speed, roof_type,
        tile_replacement_or_solarhooks,
        attachment_type,
        roof_substrate,
        building_height,
        shortest_building_length,
        snow_load,
        rafter_spacing_inches,
        risk_category,
        building_length,
        elevation: current_elevation,
        wind_speed
      },
      inputUnit,
      productId, projectVersion
    },
    user : { isPrivilegedUser, isStaff, permissions },
  } = state();

  const buildingCodeField = isSMAscenderFlush(railsProductId) ? asce716and722BuildingCode : asce722BuildingCodeSM;
  const windSpeed = getWindSpeedField(buildingCodeValue, inputUnit);
  const railFinish = isSMAscenderFlush(railsProductId) ? ascenderRailFinish: smRailFinish; 
  const snowLoad = isMetricUnit(inputUnit) ? snowLoadMetric : smSnowLoad;
  const elevation = isMetricUnit(inputUnit) ? elevationMetric : smElevation;
  const preferredSpan = isMetricUnit(inputUnit) ? preferredSpanMetric : smPreferredSpan;
  const shortestBuildingLength = isMetricUnit(inputUnit) ? isSMAscenderFlushMetric() ? shortestBuildingLengthMetric: shortestBuildingLengthSMFamilyNonAscenderFlushMetric: smShortestBuildingLength;
  const buildingHeight = isMetricUnit(inputUnit) ? isSMAscenderFlushMetric() ? buildingHeightMetric: buildingHeightSMFamilyNonAscenderFlushMetric : smBuildingHeight;
  const rafterSpacing = isMetricUnit(inputUnit) ?  rafterSpacingMetric: smRafterSpacing;
  const roofType = isSMAscenderFlush(railsProductId) ? smAscenderFlushRoofType : smFlushRoofType;
  let tornadoSpeedField: any = []
  let longestBuildingLengthField: any = []
  if (isASCE722(buildingCodeValue) && riskCategoryCheckForTornadoSpeed(risk_category)) {
    longestBuildingLengthField = longestBuildingLength(inputUnit, productId)
    longestBuildingLengthField.missing = !Boolean(building_length)
    tornadoSpeedField = isMetricUnit(inputUnit) ? metricTornadoSpeedField : tornadoSpeed;

  }
  const seismicSdsField = isASCE722(buildingCodeValue) ? seismicSds : [];

  checkEnvironmentalFactors(buildingCodeField, windSpeed, snowLoad, seismicSs, elevation);
  
  rafterSpacing.missing = isSMAscenderFlush(railsProductId) || isOsbOrPlywood(roof_type,attachment_type,roof_substrate) ? null: !checkRafterSpacing(Number(rafter_spacing_inches));
  seismicSs.missing = !checkSeismic(seismic_ss);
  buildingHeight.missing = !checkBuildingHeight(building_height * 1);
  shortestBuildingLength.missing = !checkShortestBuildingLength(shortest_building_length * 1);
  snowLoad.missing = !checkSnowLoadLimit(snow_load*1);
  roofType.missing = !checkRoofTypeMissing(roof_type);
  seismicSds.missing = !checkSeismicSds(seismic_sds);
  tornadoSpeed.missing = !checkTornadoSpeed(tornado_speed);
  elevation.missing = !checkFieldNotEmpty(current_elevation);
  riskCategory.missing = !checkFieldNotEmpty(risk_category);
  snowLoad.missing = !checkFieldNotEmpty(snow_load);
  windSpeed.missing = !checkFieldNotEmpty(wind_speed);

  const Helioscope = {
    fields: [
      azimuth,
      frameSizeUp,
      frameSizeWide,
      orientation,
      racking,
      smMidAndEndClamps('mid_clamps_choices','mid_clamps', true),
      smMidAndEndClamps('end_clamps_choices','end_clamps', true),
    ],
  };
  const cfg = {
    zipCode: 'zipcode',
    Area: 'Peoria, AZ',
    Helioscope,
    Client: {
      label: '',
      fields: [
        clientName,
        clientAddress,
      ],
    },
    LocationAndLoads: {
      label: 'loads',
      fields: [
        buildingCodeField,
        WindExposure,
        windSpeed,
        snowLoad,
        elevation,
        seismicSs,
        seismicSdsField,
        ...getASCE716722LocationsResidentalAdditionalInputs(buildingCodeValue),
        topographicalFactorKzt,
        tornadoSpeedField,
      ],
    },
    BuildingDescription: {
      label: 'Building description',
      fields: [
        buildingHeight,
        ...(isSMAscenderFlush(railsProductId) ? [
          ascenderMidAndEndClamps('mid_clamps_choices'),
          ascenderMidAndEndClamps('end_clamps_choices'),
        ] : [
          smMidAndEndClamps('mid_clamps_choices','mid_clamps'),
          smMidAndEndClamps('end_clamps_choices','end_clamps'),
        ]),
        ...(isSMAscenderFlush(railsProductId) ? [smAscenderFlushRoofType] : [smFlushRoofType]),
        ...([RoofType.SHINGLE, RoofType.OTHERS].includes(roof_type) ? [smAndNxtAttachmentTypes(roof_type, productId)] : []) ,
        ...(roof_type === RoofType.SHINGLE  && [AttachmentType.FLASHLOC_DUO, AttachmentType.SM_BUTYL_DTD].includes(attachment_type)? [roofSubstrate(productId,attachment_type,roof_substrate,roof_type)] : []) ,
        isStaff || permissions.includes(PSEUDO_STAFF_USER_PERMISSIONS.staggering_access)?StaggerAttachments: [],
        ...(roof_type === RoofType.STANDING_SEAM ? [roofMaterialThicknessSolarMountFlush,smStandardSeamMetalAttachment] : []) ,
        ...(roof_type === RoofType.R_PANEL ? [smAndNxtAttachmentTypes(roof_type, productId)] : []) ,
        ...(roof_type === RoofType.R_PANEL && attachment_type === AttachmentType.PM_9000S ? [rpanelRoofMaterialThicknessForPM9000s] : []) ,
        ...(roof_type === RoofType.R_PANEL && attachment_type === AttachmentType.PM_ADJUST ? [rpanelRoofMaterialThicknessForPMAdj] : []) ,
        ...(roof_type === RoofType.TILE  ? [smTileReplacementsOrSolarhooks] : []) ,
        ...(roof_type === RoofType.TILE && tile_replacement_or_solarhooks === TileReplacementOrSolarhooks.SOLARHOOKS ? [smSolarhooksAttachments] : []) ,
        ...(isASCE716or722(buildingCodeValue) && !isSMAscenderFlush(railsProductId) ? [residentalBuildingTypes, ...(isASCE722(buildingCodeValue) ? [longestBuildingLengthField] : []), shortestBuildingLength] : [shortestBuildingLength]),
      ],
    },
    Spans: {
      label: '',
      fields: [
        preferredSpan,
        ...(isOsbOrPlywood(roof_type,attachment_type,roof_substrate) ? [] : [rafterSpacing]),
      ],
    },
    railDirection: railDirection(removeUpslopeRail(attachment_type, projectVersion)),
    railFinish,
    railTypes: true,
  };

  return cfg;
};

import * as React from 'react';
import NavigationBar from 'app/components/HeaderComponent/NavigationBar';
import UserHeaderArea from 'app/components/HeaderComponent/userHeaderArea';
import { Link } from 'react-router-dom';
import ProjectInfoHeaderMiddleBar from 'projectDesign/components/ProjectInfoHeaderMiddleBar';

const getHeaderHeight = (navigation: boolean, infobar: JSX.Element) => {
  const topHeight = 48;
  const infobarHeight = 47;
  const navigationHeight = 0;
  if (!navigation && !infobar) {
    return topHeight;
  }

  if (infobar && !navigation) {
    return topHeight + infobarHeight;
  }

  if (!infobar && navigation) {
    return topHeight + navigationHeight;
  }

  if (infobar && navigation) {
    return topHeight + infobarHeight + navigationHeight;
  }
};

// headerComponentState
const Header: React.StatelessComponent<headerComponentState> = ({ navigation, Infobar, productId, projectId }: {navigation :boolean, Infobar?: JSX.Element, projectId?: string, productId?: string }) => {
  return (
    <div className="header" style={{ height: getHeaderHeight(navigation, Infobar) }}>
      <div className="header-row top-row">
        <div className="ubuilder-header-logo">
          <Link to="/">
            <img src={require('assets/media/logos/ubuilder.png')} />
          </Link>
        </div>
        <UserHeaderArea />
      </div>
      <div className="second-row">
      { Infobar && <div className="header-row middle-row">
        <ProjectInfoHeaderMiddleBar projectId={projectId} />
      </div>  }
      {navigation ? <div className="header-row bottom-row" >
        {< NavigationBar productId={productId} projectId={projectId} />}
  </div> : ''}
  </div>
    </div>
  );
};

export default Header;

import Icon from '__common/components/Icon';
import React from 'react';
import { connect } from 'react-redux';
import { products, isULA, isNxtHorizon, isAscender, isSMAscenderFlush, isSolarMount, isNxtTilt } from '__common/constants/products';
import SelectionControl from 'react-md/lib/SelectionControls/SelectionControl';

import {
  FETCH_RAILS,
  SELECT_RAIL,
  UNSELECT_RAIL,
  SELECT_ALL_RAILS,
  SELECT_DEAFULT_RAILS,
  SELECT_ONLY_SMHD_RAILS,
  UNSELECT_ONLY_SMHD_RAILS_OPTION,
  SELECT_LOWEST_COST_RAILS_FOR_185,
} from 'actions';
import { isStandardClampsAccessorySelected } from 'bom/utils/clamps';
import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import { round } from 'lodash';
import { showErrorAlert } from '__common/modules/alerts';
import { state } from '__common/store';
import { applyAdd185InchRail } from '__common/utils/versionCompare/versionCompare';

type railsSelectorProps = {
  dispatch: Function,
  projectId: string,
  productId: string,
  railsList: railsList,
  railFinish: string,
  clampsChoice: number|boolean,
  selectOnlySMHDRails: boolean,
  updateBom: Function,
  railsProductId?: number,
  inputUnit?: number,
};

class RailsSelector extends React.Component<railsSelectorProps> {
  onRailSelected = (rail: rail, isChecked: boolean) => {
    const { dispatch, railsList, projectId } = this.props;
    const selectedRails = railsList.filter(rail => rail.selected);
    
    if (isChecked) {
      dispatch(SELECT_RAIL(rail.length));
    } else {
      if (selectedRails.length > 1) {
        dispatch(UNSELECT_RAIL(rail.length));
      }
    }
    const {railsSelector: {railsList: railsListNew}} = state();
    const finalisedSelectedRailList = railsListNew.filter(rail => rail.selected);
    if((selectedRails.length > 1 || !selectedRails.includes(rail)) && (finalisedSelectedRailList.length<5)){

      dispatch(FETCH_RAILS(railsList, projectId, this.props.clampsChoice));
    }
    else if(finalisedSelectedRailList.length>=5){
      dispatch(UNSELECT_RAIL(rail.length));
      showErrorAlert(`Please select up to 4 rails`);
    }
    //(selectedRails.length<5 && (selectedRails.length > 1 || !selectedRails.includes(rail))) && dispatch(FETCH_RAILS(railsList, projectId, this.props.clampsChoice));
  }

  onLowestConstsSelected = (isSelected: boolean) => {
    const { dispatch, railsList, projectId, productId, railFinish } = this.props;
    const {projectConfiguration:{projectVersion, railsProductId}} = state();
    if (isSelected) {
      if(applyAdd185InchRail(projectVersion) && ([9,30].includes(railsProductId) && (products[productId]=="9" || products[productId]=="30"))){
        dispatch(SELECT_LOWEST_COST_RAILS_FOR_185());
      }
      else{
        dispatch(SELECT_ALL_RAILS());
      }
    } else {
      dispatch(SELECT_DEAFULT_RAILS(products[productId], railFinish));
    }

    dispatch(FETCH_RAILS(railsList, projectId, this.props.clampsChoice));
  }

  onSelectOnlySMHDRails = (isSelected: boolean) => {
    const { dispatch, railsList, projectId } = this.props;
    if (isSelected) {
      dispatch(SELECT_ONLY_SMHD_RAILS());
      dispatch(FETCH_RAILS(railsList, projectId, false, false, true));
    } else {
      dispatch(UNSELECT_ONLY_SMHD_RAILS_OPTION());
      dispatch(FETCH_RAILS(railsList, projectId, false, false, false));
    }
  }

  render() {
    const { railsList, productId, railsProductId, inputUnit} = this.props;
  
    const selectedRails = railsList.filter(rail => rail.selected);
        
    return (
      <div>
        { !isULA(products[productId]) && <> <div className="control-panel-label bom-sidebar-main-header">
          Select rail lengths
        </div>
        <div className="rail-lists">
          {
            railsList.map((rail, index) =>
              <SelectionControl
                key={index}
                className={rail.selected ? `rail-selected ${rail.length}` : `${rail.length}`}
                onChange={this.onRailSelected.bind(null, rail)}
                id={`${rail.length}`}
                name={`rail-${rail.length}`}
                label={`${isAscender(products[productId]) || (isSolarMount(products[productId]) && isMetricUnit(inputUnit) ) ? round(rail.length * 0.0254, 1) + ' m' : rail.length}`}
                type="checkbox"
                value={rail.length}
                inline={true}
                checked={rail.selected}
                checkedCheckboxIcon={(<Icon>check</Icon>)}
                uncheckedCheckboxIcon={(<Icon>check</Icon>)}
                disabled={railsList.length === 1}
              />)
          }
        {!isNxtHorizon(products[productId])&&!isNxtTilt(products[productId])&&!isAscender(products[productId])&& !((isSMAscenderFlush(railsProductId) && isMetricUnit(inputUnit) ))  && <SelectionControl
            onChange={this.onLowestConstsSelected}
            id="lowest_costs"
            name="lowest_costs"
            label="Lowest costs"
            type="checkbox"
            value="lowest_costs"
            inline={true}
            checked={selectedRails.length === railsList.length}
            checkedCheckboxIcon={(<Icon>check</Icon>)}
            uncheckedCheckboxIcon={(<Icon>check</Icon>)}
          />}
        </div> </> }
      </div>
    );
  }
}

function mapStateToProps(appState: appState) {
  return {
    railsList: appState.railsSelector.railsList,
    railFinish: appState.bom.extraParameters.project_rail_finish,
    clampsChoice: isStandardClampsAccessorySelected(appState),
    selectOnlySMHDRails: appState.railsSelector.selectOnlySMHDRails,
    inputUnit: appState.projectConfiguration.inputUnit,
    railsProductId: appState.projectConfiguration.railsProductId,
  };
}

export default connect(mapStateToProps)(RailsSelector);

import BingTileLayer from 'leaflet-bing-layer';
import config from 'local_config';
import Leaflet from 'leaflet';
import _ from 'lodash';
import { checkOnRoofSizeOnEditor } from '../../../googleMapsRoofsSelector/components/drawingManager/utils/roofSizeAlert';
import { clearRoofWithWarning, newRoofOverlaping } from '../../../components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManager';
import { deactivePanelsEditor } from '../../../panelsEditor/panelsEditorHelper';
import { dispatch, state } from '__common/store';
import { getProductSettings } from '../../../panelsEditor/components/productsSettings/productsSettings';
import { hidePlaceBingMarker, setPlaceBingMarker, showPlaceBingMarker } from '../bingMapsRoofsSelectorLocationSearch/bingMapsRoofsSelectorLocationSearch';
import { selectBingRoofLeadEdge } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorLeadEdge/utils/selectBingLeadEdge';
import { setMapCenterFromBingMaps, shouldSkipFetchZipcode, setShouldSkipFetZipCode, getZipCode } from '__editor/components/roofsSelector/components/roofsSelectorMap/roofsSelectorMap';
import '__common/vendor/L.Editable';
import {
  FETCH_ZIP_CODE_AND_ADDRESS_BY_LAT_LNG,
  SET_ROOF_ZONES_AUTOFILL,
  UPDATE_ROOF_COORDS,
  SET_LOCATION,
  GET_ENVIRONMENTAL_FACTORS,
  SET_PROJECT_OPTION,
  SET_CITY_NAME,
} from 'actions';
import { getRoofsSelectorPlaceMarker } from '__editor/components/roofsSelector/components/roofsSelectorLocationSearch/roofsSelectorLocationSearch';
import debounce from 'lodash/debounce';
import { toogleBingRoofSelection, redrawBingRoof, updateCenterMarkerPosition, updateInfoWindowPosition } from '../bingMapsRoofsSelectorDrawingManager/bingMapsRoofsSelectorDrawingManager';
import { markBingArrayEditrButtonSelected } from '__editor/components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorPopup';
import { findSelfIntersects } from '__editor/googleMapsRoofsSelector/components/drawingManager/utils/findGoogleMapsSelfIntersection';
import { SET_MAP_ZOOM } from '__editor/components/roofsSelector/components/roofsSelectorMap/roofsSelectorMapActions';
import { changeCityAndZipCode } from '__editor/googleMapsRoofsSelector/components/locationSearch/locationSearch';
import { isOnlyAsce716ProductFamily } from '__common/constants/products';
import { asce_710, asce_716 } from '__common/constants/buildingCodes';

let map: Leaflet.Map;
let autocomplete: google.maps.places.Autocomplete;
let pointBeforeEdit;

export function initializeMap(canvas: HTMLDivElement, width: number, height: number, preview: boolean) {
  const { roofsSelector: { mapType } } = state();

  if (mapType === 'bing') {
    return renderBing(canvas, width, height, preview);
  }

  if (mapType === 'white') {
    return renderWhite(canvas, width, height);
  }

  if (mapType === 'image') {
    return renderImage(canvas, width, height);
  }

  return null;
}

function renderBing(canvas: HTMLDivElement, width: number, height: number, preview: boolean) {
  const { roofsSelectorMap: { mapCenterCoords, zoom } } = state();
  map = Leaflet
    .map(canvas, { editable: true, editOptions: { skipMiddleMarkers: false }, zoomControl: false })
    .setView([mapCenterCoords.lat, mapCenterCoords.lng], zoom);
  map.name = 'bing map';

  setBingLayer();
  setBingMapsEvent();
  if (!shouldSkipFetchZipcode()) {
    getZipCode(preview);
  } else {
    setShouldSkipFetZipCode(false);
  }

  setBingMapsSettings();
  loadPlaceMarker();
  return map;
}

function renderWhite(canvas: HTMLDivElement, width: number, height: number) {
  const { roofsSelectorMap: { mapCenterCoords } } = state();
  const zoom = 20;
  map = Leaflet
    .map(canvas, { editable: true, zoomControl: false, layers: [Leaflet.tileLayer('', { maxNativeZoom: 19, maxZoom: 23 })] })
    .setView([mapCenterCoords.lat, mapCenterCoords.lng], zoom);

  map.name = 'white map';

  setBingMapsEvent();
  setWhiteMapSettings();

  return map;
}

function renderImage(canvas: HTMLDivElement, width: number, height: number) {
  const { roofsSelector: { image }, roofsSelectorMap: { mapCenterCoords } } = state();
  const zoom = 23;
  map = Leaflet
    .map(canvas, { editable: true, zoomControl: false, minZoom: 15, crs: Leaflet.CRS.Simple, layers: [Leaflet.tileLayer('')] })
    .setView([mapCenterCoords.lat, mapCenterCoords.lng], zoom);

  const w = 2600 * 2;
  const h = 2000 * 2;
  const url = `${image}`;
  // calculate the edges of the image, in coordinate space
  const southWest = map.unproject([0, h], map.getMaxZoom() - 1);
  const northEast = map.unproject([w, 0], map.getMaxZoom() - 1);
  const bounds = new Leaflet.LatLngBounds(southWest, northEast);
  const overlay = new Leaflet.ImageOverlay(url, bounds, {
    opacity: 0.5,
    attribution: '&copy; A.B.B Corp.',
  });
  map.addLayer(overlay);
  map.setMaxBounds(bounds);
  map.fitBounds(bounds);
  map.name = 'image map';

  setBingMapsEvent();

  return map;
}

export function initializePreviewMap(canvas: HTMLDivElement, mapType: string) {
  const { roofsSelectorMap: { mapCenterCoords } } = state();
  map = Leaflet
    .map(canvas, { attributionControl: false, zoomControl: false })
    .setView([mapCenterCoords.lat, mapCenterCoords.lng], 19);

  setBingMapsEvent(true);

  if (mapType === 'bing') {
    map.name = 'bing map';
    setBingLayer();
  }

  if (mapType === 'white') {
    map.name = 'white map';
  }
}

export function getBingMap(): Leaflet.Map {
  return map;
}

export function turnBingMapsOnMapMoveControls() {
  if (map) {
    map.dragging.enable();
    map.doubleClickZoom.enable();
    map.scrollWheelZoom.enable();
    map.boxZoom.enable();
    map.keyboard.enable();
  }
}

export function turnBingMapsOffMapMoveControls() {
  if (map) {
    map.dragging.disable();
    map.doubleClickZoom.disable();
    map.scrollWheelZoom.disable();
    map.boxZoom.disable();
    map.keyboard.disable();
  }
}

export function zoomInBingMap() {
  const map = getBingMap();
  map.zoomIn();
}

export function zoomOutBingMap() {
  const map = getBingMap();
  map.zoomOut();
}

export const getBingZipCode = debounce(() => {
  const { projectConfiguration: { projectId } } = state();
  if (map && projectId.length === 0) {
    const { projectConfiguration: { projectEnvConfig: { building_code, risk_category }, productId } } = state();
    const { lat, lng } = map.getCenter();
    dispatch(FETCH_ZIP_CODE_AND_ADDRESS_BY_LAT_LNG(lat, lng, building_code, productId, risk_category));
  }
}, 1000);

export function setBingLocationSearch(ref: HTMLInputElement) {
  const input: any = document.getElementById(ref.id);

  autocomplete = new google.maps.places.Autocomplete(input);
  autocomplete.addListener('place_changed', () => {
    clearRoofWithWarning(placeChanged);
  });
}

export function resetZipCode() {
  const { projectConfiguration: { productId, projectEnvConfig: { risk_category } } } = state();
  const coords = { lat: 34.05237915277395, lng: -118.24425353984009 };
  dispatch(
    GET_ENVIRONMENTAL_FACTORS(coords.lat, coords.lng, '00000', isOnlyAsce716ProductFamily(productId) ? asce_716.value : asce_710.value, productId, risk_category),
  );

  dispatch(SET_PROJECT_OPTION('zipcode', ''));
  dispatch(SET_CITY_NAME(''));
}

function setBingMapsEvent(preview: boolean = false) {
  map.whenReady(() => {
    map.on('moveend', () => {
      const { roofsSelector: { mapType } } = state();
      setMapCenterFromBingMaps(map.getCenter());

      if (!preview && mapType !== 'white' && !shouldSkipFetchZipcode()) {
        getBingZipCode();
      } else {
        setShouldSkipFetZipCode(false);
      }
    });

    map.on('editable:vertex:dragstart', (e) => {
      const { drawingManager: { roofs } } = state();
      const roofId = e.layer.id;
      pointBeforeEdit = _.cloneDeep(roofs[roofId].coords);
    });

    map.on('editable:vertex:drag', (e: any) => {
      const { leadEdgeRoofSelector: { leadEdges } } = state();
      const roofId = e.layer.id;
      const centerL = e.layer.getCenter();
      const edgeNumber = leadEdges[roofId];
      updateCenterMarkerPosition(roofId, centerL);
      updateInfoWindowPosition(roofId, centerL);
      selectBingRoofLeadEdge({ edgeNumber, roofId });
    });

    map.on('editable:vertex:new', (e: any) => {
      const roofId = e.layer.id;
      const coords = e.vertex.latlngs;

      dispatch(UPDATE_ROOF_COORDS(coords, roofId));
    });

    map.on('editable:vertex:dragend', (e: any) => {
      const roofId = e.layer.id;
      if (findSelfIntersects(new google.maps.Polygon({ paths: e.vertex.latlngs })) || newRoofOverlaping(e.vertex.latlngs, roofId)) {
        restoreRoofShape(roofId, pointBeforeEdit);
      } else {
        const { leadEdgeRoofSelector: { leadEdges } } = state();
        dispatch(UPDATE_ROOF_COORDS(e.vertex.latlngs, roofId));
        const edgeNumber = leadEdges[roofId];
        checkOnRoofSizeOnEditor(roofId);
        selectBingRoofLeadEdge({ edgeNumber, roofId });
      }

      pointBeforeEdit = undefined;
    });

    map.on('zoomend', () => {
      const zoom = map.getZoom();
      if (zoom > 19) {
        hidePlaceBingMarker();
      } else {
        showPlaceBingMarker();
      }

      dispatch(SET_MAP_ZOOM(zoom));
    });

    if (preview) {
      map.on('click', () => {
        deactivePanelsEditor();
      });
    }
  });
}

function setBingLayer() {
  const options = {
    bingMapsKey: config.bingMapsKey,
    maxNativeZoom: 19,
    maxZoom: 23,
    type: 'satellite',
  };
  new BingTileLayer(options, {
    forceCanvas: true,
  }).addTo(map);
}

function placeChanged() {
  const place = autocomplete.getPlace();
  if (place && place.geometry && place.geometry.location && map) {
    const { lat, lng } = place.geometry.location;
    map.panTo([lat(), lng()]);
    map.setZoom(18);

    if (place && place.formatted_address) {
      dispatch(SET_LOCATION(place.formatted_address));
    }

    setPlaceBingMarker([lat(), lng()]);
    changeCityAndZipCode(new google.maps.LatLng(lat(), lng()));
  }
}

export function bingMapRelocation() {
  const { drawingManager: { roofs } } = state();
  let roofIdArray = (Object.keys(roofs));
  if (roofs && roofIdArray.length > 0) {
    const { lat, lng } = roofs[roofIdArray[0]].marker;
    map.panTo(new Leaflet.LatLng(lat, lng));
  }
}

function setBingMapsSettings() {
  const { projectConfiguration: { productId } } = state();
  
  const settings = getProductSettings(productId);
  
  dispatch(SET_ROOF_ZONES_AUTOFILL(settings.autofillRoofZones));
}

function setWhiteMapSettings() {
  dispatch(SET_ROOF_ZONES_AUTOFILL(false));
}

function loadPlaceMarker() {
  const placeMarker = getRoofsSelectorPlaceMarker();

  if (placeMarker) {
    const { lat, lng } = new google.maps.LatLng(placeMarker.lat, placeMarker.lng);
    setPlaceBingMarker([lat(), lng()]);
  }
}

function restoreRoofShape(roofId: number, coords: { lat: number, lng: number }[]) {
  const { leadEdgeRoofSelector: { leadEdges } } = state();
  const edgeNumber = leadEdges[roofId];
  toogleBingRoofSelection(roofId);
  redrawBingRoof(roofId, coords);
  selectBingRoofLeadEdge({ edgeNumber, roofId });
  markBingArrayEditrButtonSelected(roofId);

  toogleBingRoofSelection(roofId);
}

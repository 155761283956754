import { dispatch, state } from '__common/store';
import Cookies, { get } from 'js-cookie';
import { USER_ALREADY_LOGED_IN, USER_LOGIN_REQUEST } from 'actions';
import config from 'config';

export const tryToLogIn = () => {
  const { user: { token, username, isAuthenticated, isRunning } } = state();

  if (config.domainName !== 'localhost' && !get('_globalToken')) {
    Cookies.remove('token');
  }

  const tokenFromCookies = get('token') ? get('token') : get('_globalToken');

  if (tokenFromCookies && token.length === 0 && token !== tokenFromCookies && username.length === 0) {
    return dispatch(USER_LOGIN_REQUEST(null, null, tokenFromCookies));
  }

  if ((isRunning && username.length > 0 && isAuthenticated) || !tokenFromCookies) {
    return dispatch(USER_ALREADY_LOGED_IN());
  }
};

import { createReducer } from '__common/utils/helpers';
import { RailsSelectorActionTypes } from 'actionsConstants';
import { getDefaultSelectedRails } from './railsSelector';

export const railsSelectorState: railsSelectorState = {
  railsList: [],
  railsLoaded: false,
  railsFetching: false,
  selectOnlySMHDRails: false,
};

export default {
  railsSelector: createReducer(railsSelectorState, {
    [RailsSelectorActionTypes.FETCH_RAILS](state: railsTypeChangerState) {
      return { ...state, railsLoaded: false};
    },
    [RailsSelectorActionTypes.FETCHING_RAILS](state: railsSelectorState){
      return { ...state, railsFetching: true};
    },
    [RailsSelectorActionTypes.SELECT_ALL_RAILS](state: railsSelectorState) {
      const allSelectedRails = state.railsList.map((rail: rail) => { 
        if(rail.length != 185){
          rail.selected = true;
        }
        else{
          rail.selected = false;
        }
        return rail;
      });
      return { ...state, railsList: allSelectedRails };
    },

    [RailsSelectorActionTypes.SELECT_LOWEST_COST_RAILS_FOR_185](state: railsSelectorState) {
      const allSelectedRails = state.railsList.map((rail: rail) => { 
        if([132, 185, 246].includes(rail.length)){
          rail.selected = true;
        }
        else{
          rail.selected = false;
        }
        return rail;
      });
      return { ...state, railsList: allSelectedRails };
    },

    [RailsSelectorActionTypes.SELECT_DEAFULT_RAILS](state: railsSelectorState, action) {
      const { productId, railFinish } = action.payload;
      const defaultRails = getDefaultSelectedRails(productId, railFinish);
      const defaultSelectedRails = state.railsList.map((rail) => {
        if (defaultRails.includes(rail.length)) {
          rail.selected = true;
        } else {
          rail.selected = false;
        }
        return rail;
      });

      return { ...state, railsList: defaultSelectedRails };
    },
    [RailsSelectorActionTypes.SELECT_RAIL](state: railsSelectorState, action) {
      const { payload: { length } } = action;
      const railsList = state.railsList.map((rail) => {
        if (rail.length === length) {
          rail.selected = true;
        }
        return rail;
      });
      return { ...state, railsList };
    },
    [RailsSelectorActionTypes.UNSELECT_RAIL](state, action) {
      const { payload: { length } } = action;
      const railsList = state.railsList.map((rail) => {
        if (rail.length === length) {
          rail.selected = false;
        }
        return rail;
      });
      return { ...state, railsList };
    },
    [RailsSelectorActionTypes.LOAD_RAILS_LIST](state: railsSelectorState, action) {
      const { railsList } = action.payload;
      return { ...state, railsList };
    },
    [RailsSelectorActionTypes.CLEAR_RAILS_LIST](state: railsSelectorState) {
      return { ...state, railsList: [], railsLoaded: false, railsFetching: false, railTypeLoaded: false };
    },
    [RailsSelectorActionTypes.SELECT_RAILS_FROM_API](state: railsSelectorState, action) {
      const { railsList } = state;
      const { selectedRails } = action.payload;      

      const newRailsList = railsList.map((rail) => {
        if (selectedRails.includes(rail.length)) {
          rail.selected = true;
        } else {
          rail.selected = false;
        }

        return rail;
      });
      return { ...state, railsList: newRailsList };
    },
    [RailsSelectorActionTypes.RAILS_LOADED](state) {
      return { ...state, railsLoaded: true, railsFetching: false };
    },
    [RailsSelectorActionTypes.SELECT_ONLY_SMHD_RAILS](state) {
      return { ...state, selectOnlySMHDRails: true };
    },
    [RailsSelectorActionTypes.UNSELECT_ONLY_SMHD_RAILS_OPTION](state) {
      return { ...state, selectOnlySMHDRails: false };
    },
  }),
};


import Cookies from 'js-cookie';
import history from '__common/modules/history';
import { ActionTypes } from 'actionsConstants';
import { dispatch } from '__common/store';
import { getToken, hitApi, ObservableAjax } from '__common/utils/api';
import { Observable } from 'rxjs/Observable';
import pickBy from 'lodash/pickBy';
import {
  EMPTY_APP_ACTION,
  USER_LOGIN_SUCCESS,
  loginError,
  UPDATE_USER_PREFERENCES,
} from 'actions';

interface AuthResponse {
  email: string;
  is_staff: boolean;
  token: string;
  username: string;
  preferences: userPreferences;
  products: number[];
  permissions: string[];
}

export function userLogin(action$) {
  return action$.ofType(ActionTypes.USER_LOGIN_REQUEST)
    .switchMap(({ payload: { username, password, token } }) => {
      const requestBody = {
        withCredentials: true,
        username,
        password,
        token,
      };
      return Observable.ajax(getToken('api/auth/', pickBy(requestBody)))
        .map(({ response }: { response: AuthResponse }) => {
          if (!token) {
            history.push('/');
          }
          Cookies.set('token', response.token, { expires: 365 });
          const notAfterSave = true;

          dispatch(UPDATE_USER_PREFERENCES(response.preferences, notAfterSave));
          return USER_LOGIN_SUCCESS(response.token, response.username, response.email, response.is_staff || false, response.is_privileged_user || false, response.products || [], response.permissions || []);
        })
        .catch(error =>
          Observable.of(loginError(error)),
        );
    });
}

export function userLogout(action$) {
  return action$.ofType(ActionTypes.USER_LOGOUT_REQUEST)
    .map(() => {
      Cookies.remove('token');
      Cookies.remove('_globalToken');
      return { type: ActionTypes.USER_LOGOUT_SUCCESS };
    });
}

export function registrationRequest(action$) {
  return action$.ofType(ActionTypes.REGISTRATION_REQUEST)
    .switchMap((action) => {
      const {
        email,
        password,
        repeatedPassword,
        postRegistrationAction,
        onErrorAction,
      } = action.payload;

      const userDetails = {
        email,
        username: email,
        password,
        password_repeat: repeatedPassword,
      };
      return ObservableAjax({
        takeUntil: action$.ofType(ActionTypes.REGISTRATION_REQUEST),
        nonReduxExtraAction: postRegistrationAction,
        onSuccess: EMPTY_APP_ACTION,
        onErrorAction,
        link: hitApi('post', `api/auth/register/`, userDetails),
      });
    });
}

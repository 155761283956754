import React from 'react';
import ReactTooltip from 'react-tooltip';
import SearchProjectInput from 'app/components/HeaderComponent/SearchProjectInput';
import { CLEAR_USER_PREFERENCES, logOut } from 'actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { startNewProject } from 'projectDesign/projectDesign';
import { state } from '__common/store';
import AppHistory from '__common/modules/history';
import { getProductsInfoForUser } from 'app/components/HeaderComponent/utils/getProducts';
import { getProductLink } from 'app/components/HeaderComponent/utils/getProductLink';
import config from 'config';
import { isRM10, isGroundProduct } from '__common/constants/products';

type UserHeaderAreaProps = {
  user: userState,
  dispatch: Function,
  productDisabledInfo: productDisabledInfoState,
};

class UserHeaderArea extends React.Component<UserHeaderAreaProps, {}> {
  shouldComponentUpdate(prevProps: UserHeaderAreaProps) {
    return prevProps.user !== this.props.user ||
      prevProps.productDisabledInfo !== this.props.productDisabledInfo;
  }

  searchProjectOnClick() {
    alert('TODO');
  }

  GoToPage(page: string) {
    setTimeout(() => document.body.click());
    switch (page) {
      case 'Settings':
        return '/userpreferences';
      case 'Change password':
        return '/changepassword';
      case 'Disable your account':
        return '/disableaccount';
      case 'Terms of Service':
        return '/termsofservice';
      case 'Design Assumptions':
        return '/designassumptions';
      case 'Application Revision':
        return '/applicationrevision';
      case 'Log out':
        return '/';
    }
  }

  MenuItem(page: string, className: string) {
    const goToPage = this.GoToPage.bind(this, page);
    return (
      <li key={page}>
        {
          goToPage() === '/' ?
            <a role="presentation" onClick={this.action.bind(this, page)} className={`product-list ${className}`} href={config.ssoLogoutUrl}>
              <span>{page}</span>
            </a> :
            <Link role="presentation" className={`product-list ${className}`} to={goToPage()} onClick={this.action.bind(this, page)}>
              <span>{page}</span>
            </Link>
        }
      </li>
    );
  }

  userMenu() {
    return [
      this.MenuItem('Settings', 'settings'),
      this.MenuItem('Change password', 'change-password'),
      this.MenuItem('Disable your account', 'disable-your-account'),
      this.MenuItem('Log out', 'log-out'),
    ];
  }

  aboutMenu() {
    return [
      this.MenuItem('Application Revision', 'application-revision'),
      this.MenuItem('Terms of Service', 'terms-of-service'),
      this.MenuItem('Design Assumptions', 'design-assumptions'),
    ];
  }

  action(page: string) {
    const { dispatch } = this.props;
    if (page === 'Log out') {
      dispatch(CLEAR_USER_PREFERENCES());
      dispatch(logOut());
    }
  }

  goToProductEditor = (productId: number) => {
    return getProductLink(productId);
  }

  menuItem = (productTitle: { type: string, name: string }, productId: number, userIsStaff: boolean) => {
    const goToProduct = this.goToProductEditor.bind(null, productId);
    const rm10DepricatingMessage = <ul id="rm10InfoBox">
    <li >
        <b>Unirac is discontinuing RM10 from distribution channel. Use RM10 EVO for all RM10 projects.</b>
        <b>RM10 EVO offers:</b>
        <div className='point'>-15% greater module density (13.5" row spacing)</div>
        <div className='point'>-Reliable 25-year clamping method</div>
        <b> If you have any questions, please contact your account manager.</b>
    </li>
  </ul>
    return (
      <li key={productId} className={isRM10(productId) ? `rm10-link`: null}>
        <Link role="presentation" className="product-list" to={goToProduct()} onClick={() => startNewProject(productId)}>
        <span className="menu-type">{isGroundProduct(productId) ? '' : productTitle.type}</span>
        <span className="menu-name">{isGroundProduct(productId) || productId === 98 ? productTitle.name.slice(2).toUpperCase() : productTitle.name.toUpperCase()}</span>
        </Link>
        {isRM10(productId) ?
           rm10DepricatingMessage
        : null}
      </li>
    );
  }

  _isAuthenticated = () => {
    const { user: { isAuthenticated } } = state();

    return isAuthenticated;
  }

  redirectToLoginIfNotLoggedIn = () => {
    if (!this._isAuthenticated()) {
      AppHistory.push('/login');
    }
  }

  products = () => {
    const { user } = this.props;

    return getProductsInfoForUser(user).map(({ productType, nestedItems }, index) => 
      <li key={index} className="sub-list-1-li">
        <span>{productType}</span>
        <span><i className="material-icons">arrow_right</i></span>
        <ul>{nestedItems.map(({title, productId}) => this.menuItem(title, productId, user.isStaff))}</ul>
      </li>
    )
  }

  render() {
    const { user: { username, isAuthenticated } } = this.props;
    return (
      <div className="user-header-area">
        <ReactTooltip className="drawer-and-header-tooltip" effect="solid" id="headerTooltip" />
        <ul className="dropdown userHeaderAreaDropdown" onClick={this.redirectToLoginIfNotLoggedIn}>
          <div>
            <div className="user-header-menu-item new-projects">
              <div className="menuItemTitle">
                <span className="">NEW PROJECT</span>
                <span><i className="material-icons">arrow_drop_down</i></span>
              </div>
              <ul className={this._isAuthenticated() ? 'sub-list-1' : 'hidden-content'}>{this.products()}</ul>
            </div>
          </div>
        </ul>
        <div className="your-projects header-menu-item">
          {isAuthenticated && <Link to="/yourprojects" className="header-menu-item">
            YOUR PROJECTS
          </Link>}
          {!isAuthenticated && <a data-for="headerTooltip" data-tip="Register to Save Projects" className="disabled header-menu-item">YOUR PROJECTS</a>}
        </div>
        {isAuthenticated && <div className="search-project header-menu-item">
          <i className="large material-icons searchMaterialIcon">search</i>
          <SearchProjectInput />
        </div>}
        {!isAuthenticated && <>
          <div className="your-projects login-button header-menu-item">
            <a className="header-menu-item" href={config.ssoLoginUrl} data-for="headerTooltip" data-tip="Our login has changed to a single sign-on for all Unirac services. You will be re-directed. User name and passwords have not changed." onClick={this.action.bind(this, 'log out')} >LOGIN</a>
          </div>
          <div className="your-projects register-button header-menu-item">
            <a className="header-menu-item" href={config.ssoRegistrationUrl}>REGISTER</a>
          </div>
        </>
        }
        <ul className={`dropdown userHeaderAreaDropdownRight user-name-label-list ${this._isAuthenticated() ? '' : 'hidden-content'}`}>
          <div>
            <div className="user-header-menu-item">
              <span className="header-menu-item">
                <i className="material-icons">person</i>
                {username}
                <i className="material-icons">arrow_drop_down</i>
              </span>
              <ul className="sub-list-1">{this.userMenu()}</ul>
            </div>
          </div>
        </ul>
        <ul className="dropdown userHeaderAreaDropdownRight">
          <div>
            <div className="user-header-menu-item">
              <span className="header-menu-item">
                ABOUT
                <i className="material-icons">arrow_drop_down</i>
              </span>
              <ul className="sub-list-1">{this.aboutMenu()}</ul>
            </div>
          </div>
        </ul>
      </div>
    );
  }
}

function mapPropsToState(state: appState) {
  return {
    user: state.user,
    productDisabledInfo: state.productDisabledInfo,
  };
}

export default connect(mapPropsToState)(UserHeaderArea);

import { dispatch, state } from '__common/store';
import { setPanelsDimensions } from '__editor/panelsEditor/components/panels/panels';
import {
  HIDE_CURSOR,
  SHOW_CURSOR,
  START_SCALE,
  TURN_OFF_SCALE,
  SET_METERS_PER_PIXEL,
} from 'actions';

export function scaleToolInitialized() {
  const { scale: { startPos } } = state();
  return startPos.x > 0 && startPos.y > 0;
}

export function turnOnScaleTool() {
  dispatch(HIDE_CURSOR());
  dispatch(START_SCALE());
}

export function trunOffScaleTool() {
  const { scale: { endPos } } = state();

  if (endPos.x > 0 || endPos.y > 0) {
    const scale = prompt('Set distances in meters');
    const lineWidth = getScaleLineWidth();

    if (scale && !isNaN(Number(scale))) {
      dispatch(SET_METERS_PER_PIXEL(Number(scale) / lineWidth));
      setPanelsDimensions();
    }
  }

  dispatch(SHOW_CURSOR());
  dispatch(TURN_OFF_SCALE());
}

function getScaleLineWidth() {
  const { scale: { startPos, endPos } } = state();
  return Math.hypot(endPos.x - startPos.x, endPos.y - startPos.y);
}
